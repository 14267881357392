import {Main} from "../components/Main";
import {Button, ButtonGroup, Col, Container, Row, Table} from "react-bootstrap";
import {useCallback, useState} from "react";
import {getUserData} from "../apiCalls/userApi";
import {Trakcja} from "../components/Trakcja";
import {dataToLocalString, optionalValue, parseBool} from "../helpers/dataHelper";

/**
 *
 * @param {string} errorText
 * @param {function(string)} setErrorText
 * @returns {JSX.Element}
 * @constructor
 */
export function CurrentUser({errorText, setErrorText}) {
    /** @type GetUserData|null */
    let userData ;
    let setUserData;
    [userData, setUserData] = useState(null);
    const getData = useCallback(() => {
        return getUserData(setErrorText)
            .then(data => setUserData(data))
    }, [userData]);

    return (
        <Main
            pageTitle={"Szczegóły konta"}
            errorText={errorText}
            loadDataFunction={getData}
            waitForData={true}
        >
            {userData &&
                <Container>
                    <Row>
                        <Col>
                            <div align={"center"} className={"mb-3"}>
                                <ButtonGroup>
                                    <Button variant={"outline-warning"} disabled={true}>Edytuj konto</Button>
                                    <Button variant={"outline-secondary"} disabled={true}>Zmień hasło</Button>
                                </ButtonGroup>
                            </div>
                            <Table className={"table_centered"} bordered={true} striped={true}>
                                <tbody>
                                <tr>
                                    <th>Imię</th>
                                    <td>{userData.imie}</td>
                                </tr>
                                <tr>
                                    <th>Nazwisko</th>
                                    <td>{userData.nazwisko}</td>
                                </tr>
                                <tr>
                                    <th>Numer telefonu</th>
                                    <td>{optionalValue(userData.telefon)}</td>
                                </tr>
                                {userData.nrKlubowy &&
                                    <tr>
                                        <th>Numer klubowy</th>
                                        <td>{userData.nrKlubowy}</td>
                                    </tr>
                                }
                                {userData.wymiana.organizacja &&
                                    <tr>
                                        <th>Organizacja macierzysta</th>
                                        <td>{userData.wymiana.organizacja}&nbsp;{userData.wymiana.miasto}</td>
                                    </tr>
                                }
                                {userData.login.email &&
                                    <tr>
                                        <th>Adres e-mail</th>
                                        <td>{userData.login.email}</td>
                                    </tr>
                                }
                                <tr>
                                    <th>Status konta</th>
                                    <td>
                                        {userData.login.kontoZablokowane === null ? "BEZ LOGOWANIA" : (userData.login.kontoZablokowane ? "ZABLOKOWANE" : "AKTYWNE")}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col>
                            <h2 className={"mt-3"}>Uprawnienia konduktorskie</h2>
                            <Table className={"table_centered"} striped={true} bordered={true}>
                                <thead>
                                <tr>
                                    <th>Nazwa</th>
                                    <th>Trakcja</th>
                                    <th>Czy przeszkolony?</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userData.uprawnienia.map(upr =>
                                    <tr key={upr.kategoria.KategoriaID}>
                                        <td>{upr.kategoria.nazwa}</td>
                                        <td>
                                            <Trakcja
                                                imgPath={upr.kategoria.trakcja.imgPath}
                                                nazwa={upr.kategoria.trakcja.nazwa}
                                                showNazwa={true}
                                            />
                                        </td>
                                        <td>{parseBool(upr.czyPrzeszkolony)}</td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2 className={"mt-3"}>Umowy wolontariackie</h2>
                            <Table className={"table_centered"} striped={true} bordered={true}>
                                <thead>
                                <tr>
                                    <th>Numer umowy</th>
                                    <th>Data zawarcia</th>
                                    <th>Początek obowiązywania</th>
                                    <th>Koniec obowiązywania</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userData.umowy.map(umowa =>
                                    <tr key={umowa.nrUmowy}>
                                        <td>{umowa.nrUmowy}</td>
                                        <td>{dataToLocalString(umowa.dataZawarcia)}</td>
                                        <td>{dataToLocalString(umowa.dataPoczatek)}</td>
                                        <td>{dataToLocalString(umowa.dataKoniec)}</td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={{order: "first"}}>
                            <h2 className={"mt-3"}>Stanowiska</h2>
                            <Table className={"table_centered"} striped={true} bordered={true}>
                                <thead>
                                <tr>
                                    <th>Nazwa stanowiska</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userData.stanowiska.map(stan =>
                                    <tr key={stan.StanowiskoID}>
                                        <td>{stan.nazwa}</td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            }
        </Main>
    )
}
