import {czasTrwania} from "../../../helpers/dataHelper";
import {ButtonGroup} from "react-bootstrap";
import {DodajSzkolenie} from "./DodajSzkolenie";
import {SzkolenieMember} from "./SzkolenieMember";
import {NadajUprawnienia} from "./NadajUprawnienia";
import {DodajUczestnika} from "./DodajUczestnika";
import {OdwolajSzkolenie} from "./OdwolajSzkolenie";

/**
 *
 * @param {SzkolStac} szkolenieData
 * @param {function()} reloadDataFunction
 * @param {boolean} czyEdycja
 * @param {function(string)} setErrorText
 * @returns {JSX.Element}
 * @constructor
 */
export function Szkolenie({szkolenieData, reloadDataFunction, czyEdycja, setErrorText}) {
    return (
        <tr key={szkolenieData.SzkolenieID}>
            <td>{szkolenieData.nazwa}</td>
            <td>{czasTrwania(szkolenieData.godzRozp, szkolenieData.godzZak)}</td>
            <td>{szkolenieData.miejsce}</td>
            <td>
                {szkolenieData.users.map(user =>
                    <SzkolenieMember
                        key={user.UserID}
                        user={user}
                        szkolenieID={szkolenieData.SzkolenieID}
                        setErrorText={setErrorText}
                        reloadDataFunction={reloadDataFunction}
                        czyEdycja={czyEdycja}
                    />
                )}
            </td>
            {czyEdycja &&
                <td align={"center"}>
                    <ButtonGroup vertical={true}>
                        <DodajUczestnika idSzkolenia={szkolenieData.SzkolenieID}
                                         reloadDataFunction={reloadDataFunction}/>
                        {!!szkolenieData.czyZakonczone &&
                            <NadajUprawnienia idSzkolenia={szkolenieData.SzkolenieID}
                                              reloadDataFunction={reloadDataFunction}/>
                        }
                        <DodajSzkolenie szkolenieData={szkolenieData} data={szkolenieData.data}
                                        reloadDataFunction={reloadDataFunction}/>
                        <OdwolajSzkolenie idSzkolenia={szkolenieData.SzkolenieID}
                                          reloadDataFunction={reloadDataFunction}/>
                    </ButtonGroup>
                </td>
            }
        </tr>
    )
}
