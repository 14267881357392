import {Alert} from "react-bootstrap";

function ErrorBanner({errorText}) {
    return !errorText ? (<></>) :
        (
            <Alert variant={"danger"}>
                {errorText}
            </Alert>
        )
}

export default ErrorBanner;
