import {handleDataReceive, ModalForm} from "../../../components/ModalForm";
import {useCallback, useState} from "react";
import ErrorBanner from "../../../components/ErrorBanner";
import {Form} from "react-bootstrap";
import {getUsersGdp} from "../../../apiCalls/userApi";
import {zapisGDP} from "../../../apiCalls/gdpApi";

/**
 * Zapisuje przez koordynatora użytkownika na GDP
 * @param {string} data
 * @param {function()} reloadDataFunction
 * @return {JSX.Element}
 * @constructor
 */
export function ZapiszKonduktoraGDP({data, reloadDataFunction}) {
    const [errorText, setErrorText] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    /** @type FilteredUsers|[]*/
    let users;
    /** @type function(FilteredUsers|[]) */
    let setUsers;
    [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [uwagi, setUwagi] = useState("");

    const onOpen = useCallback(() => {
        setIsLoaded(false);
        getUsersGdp(setErrorText)
            .then(resp => {
                setUsers(resp);
                setIsLoaded(true);
            })
    }, [])

    const onSubmit = useCallback(() => {
        if (selectedUser === "") {
            setErrorText("Wybierz użytkownika do GDP")
            return Promise.reject(null);
        }
        setErrorText(null);
        return zapisGDP(setErrorText, data, uwagi, selectedUser)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [selectedUser, uwagi])

    const onReset = useCallback(() => {
        setErrorText("");
        setSelectedUser("");
        setUwagi("");
    }, [selectedUser, uwagi])

    return (
        <ModalForm
            title={"Zgłoszenie konduktora na GDP"}
            childrenOnOpen={onOpen}
            childrenOnSubmit={onSubmit}
            childrenOnReset={onReset}
            isInButtonGroup={true}
            openButtonText={"Zapisz konduktora na GDP"}
        >
            <ErrorBanner errorText={errorText}/>

            <Form.Select name={"userID"} value={selectedUser}
                         onChange={e => setSelectedUser(e.target.value)}>
                <option
                    disabled={selectedUser !== ""}>
                    {isLoaded ? "Wybierz uczestnika" : "Ładowanie listy użytkowników"}
                </option>
                {users.map(u =>
                    <option key={u.UserID} value={u.UserID}>
                        {`${u.imie} ${u.nazwisko}`}
                    </option>
                )}
            </Form.Select>
            {uwagi}
            <Form.Group>
                <Form.Label>Uwagi dla koordynatora</Form.Label>
                <Form.Control name={"uwagi"} value={uwagi} onChange={e => setUwagi(e.target.value)}/>
            </Form.Group>
        </ModalForm>
    )
}
