/**
 * Plik agregujący wszystkie funkcje odpowiedzialne za weryfikację autoryzacji użytkownika
 */

import {getUserData} from "../apiCalls/userApi";

/**
 * Zwraca token odpowiedzialny za autoryzację użytkownika lub null, jeżeli niezdefiniowany
 * @return {string|null}
 */
export function getToken() {
    return localStorage.getItem('token');
}

/**
 * Zapisuje do pamięci podręcznej wartość tokena odpowiedzialnego za autoryzację użytkownika
 * @param {({"token": string, "userData": UserData, "cooldown": Cooldown})|null} loginData
 * @param {function(string|null)} setToken
 */
export function saveToken(loginData, setToken = undefined) {
    if (loginData) {
        localStorage.setItem('token', loginData.token);
        localStorage.setItem('userData', JSON.stringify(loginData.userData));
        localStorage.setItem('cooldown', JSON.stringify(loginData.cooldown));
        if (setToken)
            setToken(loginData.token);
    } else {
        localStorage.clear();
        if (setToken)
            setToken(null);
    }
}

/**
 * Pobiera szczegóły aktualnie zalogowanego użytkownika
 * @return {Promise<GetUserData|null>}
 */
export async function getCurrentUser(setError) {
    return getUserData(setError);
}

/**
 * Pobiera dane użytkownika z pamięci lokalnej
 * @returns {UserData|null}
 */
export function getCurrentUserLocal() {
    try {
        return JSON.parse(localStorage.getItem('userData'));
    } catch (e) {
        localStorage.clear();
        return null;
    }
}

/**
 * Pobiera parametry cooldown z pamięci lokalnej
 * @returns {Cooldown}
 */
export function getCooldown() {
    try {
        return JSON.parse(localStorage.getItem('cooldown'));
    } catch (e) {
        localStorage.clear();
        return {};
    }
}

