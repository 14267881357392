import {stringifyParamValue} from "../helpers/searchParamsHelper";
import {_apiCall, MethodType} from "./_apiCall";

const addrBazaZestawy = "pojazdy/zestawy"

/**
 * @typedef ZestawPojazdowData
 *
 * @property {int} ZestawPojazdowID
 * @property {string} opis
 * @property {int} TrakcjaID
 */

/**
 * Pobiera listę zestawów pojazdów (opcjonalnie pofiltrowaną wg trakcji)
 * @param {function(string)} setErrorText
 * @param {string|int|undefined} trakcja
 * @returns {Promise<ZestawPojazdowData[]>}
 */
export function getZastawyPojazdow(setErrorText, trakcja = undefined) {
    let addr = trakcja === undefined ? addrBazaZestawy : addrBazaZestawy + "?trakcja=" + stringifyParamValue(trakcja);
    return _apiCall(addr, MethodType.GET, setErrorText)
        .then(resp => resp.data)
        .catch(() => []);
}
