import {handleDataReceive, ModalForm} from "../../../components/ModalForm";
import ErrorBanner from "../../../components/ErrorBanner";
import {useCallback, useEffect, useState} from "react";
import {WyborTrakcji} from "./WyborTrakcji";
import {Form, InputGroup} from "react-bootstrap";
import {getKoordynatorzy} from "../../../apiCalls/userApi";
import {addKoordynator} from "../../../apiCalls/kalendarzApi";

export function DodajKoordynatora({data, reloadDataFunction, currentUserID}) {
    const [errorText, setErrorText] = useState(null);
    const [trakcjaID, setTrakcjaID] = useState(null);
    const [koordynatorzy, setKoordynatorzy] = useState([]);
    const [userID, setUserID] = useState(currentUserID);

    const onOpen = useCallback(() => {
        getKoordynatorzy(setErrorText)
            .then(resp => {
                setKoordynatorzy(resp);
            });
    }, []);

    useEffect(() => setUserID(currentUserID), [currentUserID]);

    const onSubmit = useCallback(() => {
        setErrorText("");

        return addKoordynator(setErrorText, data, trakcjaID, userID)
            .then((resp) => handleDataReceive(resp, reloadDataFunction));
    }, [data, trakcjaID, userID]);

    const onReset = useCallback(() => {
        setErrorText(null);
        setTrakcjaID(null);
        setUserID(currentUserID);
    }, [currentUserID]);

    return (
        <ModalForm
            title={"Dodawanie koordynatora"}
            openButtonText={"Dodaj koordynatora"}
            childrenOnOpen={onOpen}
            childrenOnSubmit={onSubmit}
            childrenOnReset={onReset}
        >
            <ErrorBanner errorText={errorText}/>

            <WyborTrakcji setErrorText={setErrorText} setTrakcja={setTrakcjaID}/>

            <InputGroup>
                <InputGroup.Text>Koordynator</InputGroup.Text>
                <Form.Select onChange={(e) => setUserID(e.target.value)} required={true}>
                    {koordynatorzy.map(k =>
                        <option key={k.UserID} value={k.UserID} selected={k.UserID === currentUserID}>
                            {`${k.imie} ${k.nazwisko}`}
                        </option>)}
                </Form.Select>
                <Form.Text>Do każdej trakcji może być przypisany maksymalnie jeden koordynator.</Form.Text>
            </InputGroup>

        </ModalForm>
    )
}
