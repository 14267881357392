import {_apiCall, apiErrorHandler, MethodType} from "./_apiCall";

const usersAddr = "users";
const userAddr = "user";

/**
 * Lista użytkowników
 * @typedef GetUsers
 *
 * @property {int} UserID
 * @property {string} imie
 * @property {string} nazwisko
 * @property {int} nrKlubowy
 * @property {boolean} kontoZablokowane
 */

/**
 * Lista użytkowników z numerem telefonu (używana w kalendarzu)
 * @typedef UserTelefon
 *
 * @property {int} UserID
 * @property {string} imie
 * @property {string} nazwisko
 * @property {string|null} telefon
 */

/**
 * Dane użytkownika
 * @typedef {Object} UserData
 *
 * @property {int} UserID
 * @property {string} imie
 * @property {string} nazwisko
 * @property {string|null} telefon
 * @property {int|null} nrKlubowy
 * @property {{organizacja: string|null, miasto: string|null}} wymiana
 * @property {{email: string|null, kontoZablokowane: boolean|null}} login
 * @property {[UprawnieniaKonduktorskie]} uprawnienia
 * @property {UserUprawnienia} userUprawnienia
 */

/**
 * Uprawnienia konduktorskie
 * @typedef UprawnieniaKonduktorskie
 *
 * @property {KategorieSluzbData} kategoria
 * @property {boolean} czyPrzeszkolony
 */


/**
 * Uprawnienia użytkownika w dostępie do systemu
 * @typedef {Object} UserUprawnienia
 *
 * @property {boolean} config_modify
 * @property {boolean} kalendarz_koordynator
 * @property {boolean} kalendarz_modify
 * @property {boolean} kalendarz_read
 * @property {boolean} kalendarz_read_hidden
 * @property {boolean} kategorie_modify
 * @property {boolean} kategorie_read
 * @property {boolean} login_modify
 * @property {boolean} pojazdy_modify
 * @property {boolean} pojazdy_read
 * @property {boolean} raporty_read
 * @property {boolean} sluzby_gdp
 * @property {boolean} sluzby_modify
 * @property {boolean} sluzby_zapis
 * @property {boolean} szkolenia_instruktor
 * @property {boolean} szkolenia_read
 * @property {boolean} trakcje_modify
 * @property {boolean} trakcje_read
 * @property {boolean} umowy_modify
 * @property {boolean} umowy_read
 * @property {boolean} uprawnienia_modify
 * @property {boolean} uprawnienia_read
 * @property {boolean} user_delete
 * @property {boolean} user_modify
 * @property {boolean} user_modify_spec
 * @property {boolean} user_read
 * @property {boolean} zajezdnie_modify
 * @property {boolean} zajezdnie_read
 */

/**
 * Pobiera listę użytkowników
 * @param {function(string)} setErrorText
 * @returns {Promise<GetUsers[]>}
 */
export function getUsers(setErrorText) {
    return _apiCall("users", MethodType.GET, setErrorText)
        .then(resp => resp.data)
        .catch(() => []);
}

/**
 * @typedef FilteredUsers
 *
 * @property {int} UserID
 * @property {string} imie
 * @property {string} nazwisko
 */

/**
 * Pobiera koordynatorów
 * @param {function(string)} setErrorText
 * @returns {Promise<FilteredUsers[]>}
 */
export function getKoordynatorzy(setErrorText) {
    return _apiCall(`${usersAddr}/koordynatorzy`, MethodType.GET, setErrorText)
        .then(resp => resp.data)
        .catch(() => [])
}

/**
 * Pobiera instruktorów
 * @param {function(string)} setErrorText
 * @returns {Promise<FilteredUsers[]>}
 */
export function getInstruktorzy(setErrorText) {
    return _apiCall(`${usersAddr}/instruktorzy`, MethodType.GET, setErrorText)
        .then(resp => resp.data)
        .catch(() => []);
}

/**
 * Pobiera użytkowników z uprawnieniami do GDP
 * @param {function(string)} setErrorText
 * @return {Promise<FilteredUsers[]>}
 */
export function getUsersGdp(setErrorText) {
    return _apiCall(`${usersAddr}/gdp`, MethodType.GET, setErrorText)
        .then(resp => resp.data)
        .catch(() => [])
}

/**
 * @typedef {UserData & {"cooldown": Cooldown} } GetUserData
 */

/**
 * Pobierz dane bieżącego użytkownika
 * @param setError
 * @returns {Promise<GetUserData|null>}
 */
export function getUserData(setError) {
    return _apiCall(userAddr, MethodType.GET, setError)
        .then(resp => resp.data)
        .catch(() => null)
}

/**
 * @typedef Grafik
 *
 * @property {int} UserID ID użytkownika
 * @property {string} rok Rok kalendarzowy grafiku
 * @property {string} data Data (w formacie string)
 * @property {string} data_raw Data (w formacie Date)
 * @property {"KOORDYNATOR"|"KONDUKTOR"|"INSTRUKTOR"|"GDP"|"SZKOLENIE"} typ Typ służby
 * @property {string} typ_nazwa Nazwa służby (widoczna w grafiku)
 * @property {string|null} zadanie Nazwa zadania (konduktor, instruktor, szkolenie - nazwa szkolenia)
 * @property {string|null} godzRozp Godzina rozpoczęcia (konduktor, instruktor, szkolenie)
 * @property {string|null} godzZak Godzina zakończenia (konduktor, instruktor, szkolenie)
 * @property {string|null} czasTrwania Czas trwania służby (konduktor, instruktor, szkolenie)
 * @property {string|null} miejsceRozp Miejsce rozpoczęcia służby (konduktor, instruktor)
 * @property {string|null} miejsceZak Miejsce zakończenia służby (konduktor, instruktor)
 * @property {string|null} zajezdnia Zajezdnia przypisana do zadania (konduktor, instruktor)
 * @property {string|null} koordynator_trakcja Trakcja przypisana do koordynatora (koordynator)
 * @property {boolean|null} konduktor_czySzkolenie Definiuje czy konduktor jest w trakcje szkolenia (konduktor)
 * @property {string|null} konduktor_instruktor Instruktor szkolący konduktora (konduktor)
 * @property {string|null} instruktor_kursant Kursant instruktora (instruktor)
 * @property {string|null} instruktor_kategoria Kategoria uprawnień, do której instruktor szkoli (instruktor)
 * @property {string|null} gdp_uwagi Uwagi konduktora dotyczące GDP (GDP)
 * @property {string|null} szkolenie_miejsce Miejsce organizacji szkolenia stacjonarnego (szkolenie)
 */

/**
 * @typedef UserGrafik
 *
 * @property {int} UserID
 * @property {string} imie
 * @property {string} nazwisko
 * @property {int|null} nrKlubowy
 * @property {{organizacja: string|null, miasto: string|null}} wymiana
 * @property {string} sumaCzasTrwania
 * @property {Grafik[]} grafik
 */

/**
 * Pobiera grafik użytkownika
 * @param {function(string)} setError
 * @param {string|int} rok
 * @param {string|int|undefined} userID ID użytkownika, którego grafik chcemy pobrać (jeżeli puste — obecny użytkownik)
 * @returns {Promise<UserGrafik>}
 */
export function getGrafik(setError, rok = 2023, userID) {
    const addr = (userID ? `${usersAddr}/${userID}/grafik` : `${userAddr}/grafik`) + `?rok=${rok}`;
    return _apiCall(addr, MethodType.GET, setError)
        .then(resp => resp.data)
        .catch(err => apiErrorHandler(err));
}
