import {_apiCall, apiErrorHandler, MethodType} from "./_apiCall";
import {dataToString} from "../helpers/dataHelper";

const addrBaza = "kalendarz";

/**
 * Data w kalendarzu
 * @typedef {Object.<string, {archieved: boolean, published: boolean}>} KalendarzData
 */

/**
 * Pobiera daty z kalendarza
 * @param {function(string)} setErrorText
 * @param {boolean} czyPelny
 * @return {Promise<KalendarzData|null>}
 */
export function getKalendarz(setErrorText, czyPelny = false) {
    const addr = addrBaza + "?czyPelny=" + (czyPelny ? "true" : "false");
    return _apiCall(addr, MethodType.GET, setErrorText)
        .then(resp => resp.data)
        .catch(err => apiErrorHandler(err))
}

/**
 * Dodaje dzień do kalendarza
 * @param {function(string)} setErrorText
 * @param {Date} data
 * @param {Date|null} dataPublikacji
 * @param {boolean} czyDopuszczoneGDP
 * @returns {Promise<string|null>}
 */
export function addKalendarzData(setErrorText, data, dataPublikacji, czyDopuszczoneGDP) {
    const content = {
        data: data,
        dataPublikacji: dataPublikacji,
        czyDopuszczoneGDP: czyDopuszczoneGDP
    }
    return _apiCall(addrBaza, MethodType.POST, setErrorText, content)
        .then((resp) => dataToString(resp.data))
        .catch(() => null);
}

/**
 * Aktualizacja daty w kalendarzu
 * @param {function(string)} setErrorText
 * @param {string} data
 * @param {string} dataPublikacji
 * @param {string} koordynatorNotes
 * @param {boolean} czyDopuszczoneGDP
 * @return {Promise<string|null>}
 */
export function updateKalendarzData(setErrorText, data, dataPublikacji, koordynatorNotes, czyDopuszczoneGDP) {
    const content = {dataPublikacji, czyDopuszczoneGDP, koordynatorNotes};
    return _apiCall(`${addrBaza}/${data}`, MethodType.PUT, setErrorText, content)
        .then(resp => resp.data)
        .catch(() => null);
}

export function deleteKalendarzData(setErrorText, data) {
    return _apiCall(`${addrBaza}/${data}`, MethodType.DELETE, setErrorText)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Dzień w kalendarzu
 * @typedef KalendarzDay
 *
 * @property {string} data
 * @property {string|null} koordynatorNotes Uwagi od koordynatora
 * @property {string|Date|null} dataPublikacji Data publikacji dnia
 * @property {boolean} czyDopuszczoneGDP Definiuje czy GDP w tym dniu jest dopuszczone
 * @property {boolean} czySamodzielnyZapisGDP Definiuje czy konduktor może się samodzielnie zapisać na GDP
 * @property {boolean} dopuszczalnaRezygnacja Definiuje czy konduktor może zrezygnować ze służby w tym dniu
 * @property {boolean} archieved Definiuje czy dzień jest zarchiwizowany
 * @property {boolean} published Definiuje czy dzień jest opublikowany
 * @property {[KoordynatorData]} koordynatorzy Lista koordynatorów dnia
 * @property {[GDPdata]} GDP Lista konduktorów zgłaszających GDP
 * @property {[SzkolStac]} szkolenia Lista szkoleń stacjonarnych
 * @property {[BrygadaData]} brygady Lista brygad
 * @property {boolean} czyEdycja Definiuje czy użytkownik może edytować dzień (jest koordynatorem głównym lub koordynatorem dnia)
 */

/**
 * Pobiera szczegóły dnia
 * @param {string} data
 * @returns {Promise<KalendarzDay|null>}
 */
export function getKalendarzData(data) {
    return _apiCall(addrBaza + "/" + data, MethodType.GET)
        .then((resp) => resp.data)
        .catch(() => null)
}

/**
 * @typedef DataBrygadyList
 *
 * @property {int} BrygadaID
 * @property {string} nazwa
 * @property {TrakcjaData} trakcja
 */

/**
 * Pobiera listę brygad z danego dnia
 * @param {function(string)} setErrorText
 * @param {string} data
 * @returns {Promise<DataBrygadyList[]|null>}
 */
export function getKalendarzDataBrygadyList(setErrorText, data) {
    return _apiCall(`${addrBaza}/${data}/brygady`, MethodType.GET, setErrorText)
        .then(resp => resp.data)
        .catch(() => null)
}

// KOORDYNATORZY

/**
 * Koordynatorzy dnia
 * @typedef KoordynatorData
 *
 * @property {TrakcjaData} Trakcja
 * @property {UserTelefon} User
 */

/**
 * Dodaje koordynatora
 * @param {function(string)} setErrorText
 * @param {Date|string} data
 * @param {int} TrakcjaID
 * @param {int} UserID
 * @return {Promise<AxiosResponse|null>}
 */
export function addKoordynator(setErrorText, data, TrakcjaID, UserID) {
    const content = {
        data: data,
        TrakcjaID: TrakcjaID,
        UserID: UserID
    };
    const addr = addrBaza + "/" + data + "/koordynatorzy";
    return _apiCall(addr, MethodType.POST, setErrorText, content)
        .then((resp) => resp)
        .catch(() => null)
}

/**
 * Usuwa koordynatora
 * @param {function(string)} setErrorText
 * @param {Date|string} data
 * @param {int} TrakcjaID
 * @return {Promise<AxiosResponse|null>}
 */
export function delKoordynator(setErrorText, data, TrakcjaID) {
    const addr = addrBaza + "/" + data + "/koordynatorzy/" + TrakcjaID;
    return _apiCall(addr, MethodType.DELETE, setErrorText)
        .then((resp) => resp)
        .catch((err) => {
            if (err.code === 404) {
                setErrorText("Nie znaleziono koordynatora do usunięcia")
            }
            return null;
        })
}
