import {Stack} from "react-bootstrap";
import {PhoneButton} from "../../../components/buttons/PhoneButton";
import {DeleteButton} from "../../../components/buttons/DeleteButton";
import {useCallback} from "react";
import {wypiszSieZmiana} from "../../../apiCalls/zmianyApi";
import {handleDataReceive} from "../../../components/ModalForm";
import {WyznaczInstruktora} from "../szkolTabElements/WyznaczInstruktora";
import {UsunInstruktora} from "../szkolTabElements/UsunInstruktora";
import {UsunKonduktora} from "../szkolTabElements/UsunKonduktora";

/**
 *
 * @param {int} zmianaID
 * @param {string} zmianaName
 * @param {ZmianaKonduktorData} konduktorData
 * @param {boolean} czyEdycja
 * @param {boolean} dopuszczalnaRezygnacja
 * @param {int} currentUserID
 * @param {function()} reloadDataFunction
 * @param {function(string)} setErrorText
 * @return {JSX.Element}
 * @constructor
 */
export function KonduktorZmiana({
                                    zmianaID,
                                    zmianaName,
                                    konduktorData,
                                    czyEdycja,
                                    dopuszczalnaRezygnacja,
                                    currentUserID,
                                    reloadDataFunction,
                                    setErrorText
                                }) {

    const onResign = useCallback(() => {
        wypiszSieZmiana(setErrorText, zmianaID)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [zmianaID]);

    return (
        <>
            {konduktorData.szkolenie &&
                <>
                    <hr className={"mb-1"}/>
                    <div className={"center"} style={{fontWeight: "bold"}}>SZKOLENIE</div>
                </>}
            <Stack direction={"horizontal"} gap={2}>
                <div className={currentUserID === konduktorData.UserID ? "ms-auto" : "mx-auto"}>
                    {`${konduktorData.imie} ${konduktorData.nazwisko}`}
                    {konduktorData.szkolenie && <> – <i>Kursant</i></>}
                </div>
                {!!dopuszczalnaRezygnacja && currentUserID === konduktorData.UserID &&
                    <div className={"me-auto"}>
                        <DeleteButton
                            onClick={onResign}
                            buttonWithIcon={false}
                            buttonText={"Wypisz się"}
                            confirmTitle={"Rezygnacja ze służby"}
                            confirmText={`Czy na pewno chcesz zrezygnować ze służby ${zmianaName}?`}
                        />
                    </div>
                }
                {czyEdycja &&
                    <div>
                        <PhoneButton phoneNumber={konduktorData.telefon}/>
                        <UsunKonduktora
                            zmianaID={zmianaID}
                            zmianaName={zmianaName}
                            userID={konduktorData.UserID}
                            userImie={konduktorData.imie}
                            userNazwisko={konduktorData.nazwisko}
                            czySzkolenie={!!konduktorData.szkolenie}
                            setErrorText={setErrorText}
                            reloadDataFunction={reloadDataFunction}
                        />
                    </div>
                }
            </Stack>
            {konduktorData.szkolenie &&
                <>
                    <Stack direction={"horizontal"} gap={2}>
                        {konduktorData.szkolenie.instruktor ?
                            <>
                                <div className={"mx-auto"}>
                                    {`${konduktorData.szkolenie.instruktor.imie} ${konduktorData.szkolenie.instruktor.nazwisko}`} – <i>Instruktor</i>
                                </div>
                                <div>
                                    <PhoneButton phoneNumber={konduktorData.szkolenie.instruktor.telefon}/>
                                    <UsunInstruktora
                                        zmianaID={zmianaID}
                                        konduktorID={konduktorData.UserID}
                                        konduktorImie={konduktorData.imie}
                                        konduktorNazwisko={konduktorData.nazwisko}
                                        instruktorImie={konduktorData.szkolenie.instruktor.imie}
                                        instruktorNazwisko={konduktorData.szkolenie.instruktor.nazwisko}
                                        setErrorText={setErrorText}
                                        reloadDataFunction={reloadDataFunction}
                                    />
                                </div>
                            </> : (czyEdycja &&
                                <div className={"mx-auto"}>
                                    <WyznaczInstruktora
                                        zmianaID={zmianaID}
                                        kursantID={konduktorData.UserID}
                                        kursantImie={konduktorData.imie}
                                        kursantNazwisko={konduktorData.nazwisko}
                                        reloadDataFunction={reloadDataFunction}
                                    />
                                </div>
                            )
                        }
                    </Stack>
                    <hr className={"mt-1"}/>
                </>
            }

        </>
    )
}
