import {DeleteButton} from "../../../components/buttons/DeleteButton";
import {useCallback} from "react";
import {deleteInstruktor} from "../../../apiCalls/szkolTabApi";
import {handleDataReceive} from "../../../components/ModalForm";

/**
 * Usuwa instruktora ze szkolenia
 * @param {int} zmianaID
 * @param {int} konduktorID
 * @param {string} konduktorImie
 * @param {string} konduktorNazwisko
 * @param {string} instruktorImie
 * @param {string} instruktorNazwisko
 * @param {function(string)} setErrorText
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function UsunInstruktora({
                                    zmianaID,
                                    konduktorID,
                                    konduktorImie,
                                    konduktorNazwisko,
                                    instruktorImie,
                                    instruktorNazwisko,
                                    setErrorText,
                                    reloadDataFunction
                                }) {
    const onDeleteInstruktor = useCallback(() => {
        deleteInstruktor(setErrorText, zmianaID, konduktorID)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [zmianaID, konduktorID]);

    return (
        <DeleteButton
            onClick={onDeleteInstruktor}
            confirmTitle={"Usuwanie instruktora"}
            confirmText={`Czy na pewno chcesz usunąć instruktora ${instruktorImie} ${instruktorNazwisko} ze szkolenia konduktora ${konduktorImie} ${konduktorNazwisko}?`}
        />
    )
}
