import {DeleteButton} from "../../../components/buttons/DeleteButton";
import {useCallback} from "react";
import {deleteKalendarzData} from "../../../apiCalls/kalendarzApi";
import {handleDataReceive} from "../../../components/ModalForm";

/**
 *
 * @param {string} data
 * @param {function(string)} setErrorText
 * @param {function()} reloadDataFunction
 * @return {JSX.Element}
 * @constructor
 */
export function UsunDzien({data, setErrorText, reloadDataFunction}) {
    const onDelete = useCallback(() => {
        deleteKalendarzData(setErrorText, data)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [data])

    return (
        <DeleteButton buttonWithIcon={true} buttonText={"Usuń dzień"} confirmTitle={"Usuwanie dnia"}
                      confirmText={"Czy na pewno chcesz usunąć z kalendarza tę datę?"} onClick={onDelete}/>
    )
}
