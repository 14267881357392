import {Button, Form, FormGroup} from "react-bootstrap";
import {useCallback, useState} from "react";
import {Navigate, useSearchParams} from "react-router-dom";
import {stringifyConfirmTextParam} from "../helpers/searchParamsHelper";
import {resetPassword} from "../apiCalls/authApi";
import {handleDataReceive} from "../components/ModalForm";
import ErrorBanner from "../components/ErrorBanner";

/**
 * Formularz resetowania hasła
 * @param {function(string)} navigate
 * @return {JSX.Element}
 * @constructor
 */
function ResetPassword({navigate}) {
    const [params] = useSearchParams();
    const [errorText, setErrorText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [userID, setUserID] = useState(params.get("user"));
    const [key, setKey] = useState(params.get("key"));
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const onSuccess = useCallback(() => {
        navigate(`/login?${stringifyConfirmTextParam("Hasło zostało zmienione")}`);
    }, []);

    const onSubmit = useCallback((event) => {
        event.preventDefault();
        setErrorText(null);
        if (password !== repeatPassword)
            return setErrorText("Podane hasła różnią się");
        setIsLoading(true);
        resetPassword(setErrorText, userID, key, password, repeatPassword)
            .then(resp => handleDataReceive(resp, onSuccess))
            .finally(() => setIsLoading(false));
    }, [userID, key, password, repeatPassword]);

    // Brak wymaganych parametrów
    if (!userID || !key)
        return (<Navigate to={"/login"}/>);

    return (
        <>
            <ErrorBanner errorText={errorText}/>
            <Form onSubmit={e => onSubmit(e)}>
                <input type="hidden" name="UserID" value={userID} onChange={e => setUserID(e.target.value)}/>
                <input type="hidden" name="key" value={key} onChange={e => setKey(e.target.value)}/>
                <FormGroup className="mb-2">
                    <Form.Label>Nowe hasło</Form.Label>
                    <Form.Control type="password" name="newPassword" required value={password}
                                  onChange={e => setPassword(e.target.value)}/>
                    <Form.Text>
                        Hasło musi zawierać co&nbsp;najmniej 10&nbsp;znaków.<br/>
                        Dodatkowo musi zawierać wielkie i&nbsp;małe&nbsp;litery, cyfry, i&nbsp;znaki specjalne.
                    </Form.Text>
                </FormGroup>
                <FormGroup className="mb-2">
                    <Form.Label>Powtórz hasło</Form.Label>
                    <Form.Control type="password" name="repeatPassword" required value={repeatPassword}
                                  onChange={e => setRepeatPassword(e.target.value)}/>
                </FormGroup>
                <div className="d-grid">
                    <Button variant="outline-primary" type="submit" disabled={isLoading}>
                        {isLoading ? "Resetowanie hasła..." : "Resetuj hasło"}
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default ResetPassword;
