import {Route, Routes, Navigate, useNavigate} from "react-router-dom";
import Login from "../strony/Login";
import ForgotPassword from "../strony/ForgotPassword";
import logo_kmkm from "../media/kmkm_logo.png";
import ResetPassword from "../strony/ResetPassword";

/**
 *
 * @param {function(string|null)} setToken
 * @returns {JSX.Element}
 * @constructor
 */
function UnloggedRouter({setToken}) {
    const navigate = useNavigate();
    return (
        <div className="public_main">
            <img onClick={() => navigate("/")} className="logo mb-4 pointer" src={logo_kmkm}
                 alt="Klub Miłośników Komunikacji Miejskiej"/>
            <h1 className="mb-4">Internetowy System Służb Konduktorskich</h1>
            <div>
                <Routes>
                    <Route path="/login" element={<Login setToken={setToken}/>}/>
                    <Route path="/forgot" element={<ForgotPassword navigate={navigate}/>}/>
                    <Route path="/reset" element={<ResetPassword navigate={navigate}/>}/>
                    <Route path='*' element={<Navigate to="/login"/>}/>
                </Routes>
            </div>
        </div>)
}

export default UnloggedRouter;
