import {Card, Col, Container, Row} from "react-bootstrap";
import Koordynator from "./koordynatorzyElements/Koordynator";
import {DodajKoordynatora} from "./koordynatorzyElements/DodajKoordynatora";

export default function Koordynatorzy({
                                          koordynatorzyData,
                                          koordynatorNotes,
                                          data,
                                          setErrorText,
                                          reloadDataFunction,
                                          userID,
                                          czyEdycja
                                      }) {

    return (
        <Container className={"center"}>
            <Row>
                <Col md={4} className={"border-10"}>
                    <Card>
                        <Card.Header>Koordynatorzy</Card.Header>
                        <Card.Body>
                            {koordynatorzyData === undefined || koordynatorzyData.length === 0 ?
                                <div><i>Nie wyznaczono jeszcze koordynatorów</i></div> :
                                koordynatorzyData.map(k =>
                                    <Koordynator
                                        key={"k"+k.User.UserID+"t"+k.Trakcja.TrakcjaID}
                                        data={data}
                                        koordynatorData={k}
                                        setErrorText={setErrorText}
                                        reloadDataFunction={reloadDataFunction}
                                        czyEdycja={czyEdycja}
                                    />)
                            }
                        </Card.Body>
                        {czyEdycja &&
                            <Card.Footer>
                                <DodajKoordynatora data={data} reloadDataFunction={reloadDataFunction}
                                                   currentUserID={userID}/>
                            </Card.Footer>
                        }
                    </Card>
                </Col>
                <Col md>
                    <Card>
                        <Card.Header>Uwagi od koordynatorów</Card.Header>
                        <Card.Body>
                            <div>
                                {koordynatorNotes !== null ? koordynatorNotes :
                                    <i>Koordynatorzy nie dodali żadnych uwag</i>}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
