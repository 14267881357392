import {InputGroup, Form} from "react-bootstrap";
import {useCallback, useState} from "react";
import {addSzkolStac, editSzkolStac} from "../../../apiCalls/szkolStacApi";
import {handleDataReceive, ModalForm} from "../../../components/ModalForm";
import {isEndAfterBegin} from "../../../helpers/dataHelper";
import ErrorBanner from "../../../components/ErrorBanner";
import {RequireField, RequireFieldLegenda} from "../../../components/inputs/RequireField";
import {GodzinaInput} from "../../../components/inputs/GodzinaInput";

/**
 * Dodaje lub edytuje szkolenie
 * @param {string} data
 * @param {SzkolStac|null} szkolenieData
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function DodajSzkolenie({data, szkolenieData = null, reloadDataFunction}) {
    const [errorText, setErrorText] = useState("");
    const [nazwa, setNazwa] = useState(szkolenieData ? szkolenieData.nazwa : "");
    const [godzRozp, setGodzRozp] = useState(szkolenieData ? szkolenieData.godzRozp : "");
    const [godzZak, setGodzZak] = useState(szkolenieData ? szkolenieData.godzZak : "");
    const [miejsce, setMiejsce] = useState(szkolenieData ? szkolenieData.miejsce : "");

    const onSubmit = useCallback(() => {
        setErrorText("");
        if (!isEndAfterBegin(godzRozp, godzZak)) {
            setErrorText("Godzina rozpoczęcia szkolenia jest późniejsza od godziny jego zakończenia");
            return Promise.resolve(false);
        }

        if (!szkolenieData)
            //Dodawanie szkolenia
            return addSzkolStac(setErrorText, data, nazwa, godzRozp, godzZak, miejsce)
                .then(resp => handleDataReceive(resp, reloadDataFunction));
        else
            //Edytowanie szkolenia
            return editSzkolStac(setErrorText, szkolenieData.SzkolenieID, data, nazwa, godzRozp, godzZak, miejsce)
                .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [nazwa, godzRozp, godzZak, miejsce, szkolenieData])

    const onReset = useCallback(() => {
        setErrorText(null);
        setNazwa(szkolenieData ? szkolenieData.nazwa : "");
        setGodzRozp(szkolenieData ? szkolenieData.godzRozp : "");
        setGodzZak(szkolenieData ? szkolenieData.godzZak : "");
        setMiejsce(szkolenieData ? szkolenieData.miejsce : "");
    }, [szkolenieData, nazwa, godzRozp, godzZak, miejsce])

    return (
        <ModalForm
            title={szkolenieData ? "Edycja szkolenia stacjonarnego" : "Dodawanie szkolenia stacjonarnego"}
            openButtonText={szkolenieData ? "Edytuj szkolenie" : "Dodaj szkolenie stacjonarne"}
            openButtonVariant={szkolenieData ? "outline-warning" : "outline-primary"}
            isInButtonGroup={true}
            childrenOnSubmit={onSubmit}
            childrenOnReset={onReset}
        >
            <ErrorBanner errorText={errorText}/>

            <Form.Group className={"mb-2"}>
                <Form.Label>Nazwa szkolenia<RequireField/></Form.Label>
                <Form.Control onChange={e => setNazwa(e.target.value)} value={nazwa}
                              type={"text"} name={"nazwa"} required={true}/>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label>Czas trwania szkolenia<RequireField/></Form.Label>
                <InputGroup className={"mb-1"}>
                    <InputGroup.Text>Godzina rozpoczęcia</InputGroup.Text>
                    <GodzinaInput godzina={godzRozp} setGodzina={setGodzRozp} name={"godzRozp"}/>
                </InputGroup>
                <InputGroup>
                    <InputGroup.Text>Godzina zakończenia</InputGroup.Text>
                    <GodzinaInput godzina={godzZak} setGodzina={setGodzZak} name={godzZak}/>
                </InputGroup>
                <Form.Text>
                    Jeżeli szkolenie kończy się po północy, wprowadź godzinę jako wartość większą niż 24
                </Form.Text>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label className={"mt-2"}>Miejsce organizacji<RequireField/></Form.Label>
                <Form.Control onChange={e => setMiejsce(e.target.value)} value={miejsce}
                              type={"text"} name={"miejsce"} required={true}/>
            </Form.Group>

            <RequireFieldLegenda/>
        </ModalForm>
    )
}
