import {Button, Form, InputGroup} from "react-bootstrap";
import {useCallback, useMemo, useState} from "react";
import {aktualizujGDP, wypisGDP, zapisGDP} from "../../../apiCalls/gdpApi";
import {handleDataReceive} from "../../../components/ModalForm";
import {DeleteButton} from "../../../components/buttons/DeleteButton";

/**
 * Zapisuje bieżącego użytkownika na GDP
 * @param {string} data
 * @param {string} defaultUwagi
 * @param {boolean} zgloszoneGDP
 * @param {function(boolean)} setZgloszoneGDP
 * @param {boolean} czySamodzielnyZapis
 * @param {boolean} dopuszczalnaRezygnacja
 * @param {function()} reloadDataFunction
 * @param {function(string)} setErrorText
 * @return {JSX.Element}
 * @constructor
 */
export function ZapisGDP({
                             data,
                             defaultUwagi,
                             zgloszoneGDP,
                             setZgloszoneGDP,
                             czySamodzielnyZapis,
                             dopuszczalnaRezygnacja,
                             reloadDataFunction,
                             setErrorText
                         }) {
    const [isLoading, setIsLoading] = useState(false);
    const [uwagi, setUwagi] = useState("");

    const showInput =
        useMemo(() => zgloszoneGDP || (!zgloszoneGDP && czySamodzielnyZapis),
            [zgloszoneGDP, czySamodzielnyZapis]);
    /** @type {Boolean} */
    const disabledButton = useMemo(() => !czySamodzielnyZapis || isLoading, [czySamodzielnyZapis, isLoading]);

    const zmianyButtonText = useMemo(() => isLoading ? "Zapisywanie zmian..." : "Zapisz zmiany", [isLoading]);
    const zapisButtonText = useMemo(() => isLoading ? "Zgłaszanie GDP..." : "Zgłoś GDP", [isLoading]);
    const endZgloszeniaText = useMemo(() => "Upłynął okres zgłaszania GDP", []);

    const onZapis = useCallback(() => {
        setIsLoading(true);
        zapisGDP(setErrorText, data, uwagi)
            .then(resp => handleDataReceive(resp, reloadDataFunction))
            .finally(() => setIsLoading(false));
    }, [uwagi]);

    const onZmiany = useCallback(() => {
        setIsLoading(true);
        aktualizujGDP(setErrorText, data, uwagi)
            .then(resp => handleDataReceive(resp, reloadDataFunction))
            .finally(() => setIsLoading(false));
    }, [uwagi]);

    const onRezygnacja = useCallback(() => {
        wypisGDP(setErrorText, data, undefined)
            .then(resp => {
                if (resp)
                    setZgloszoneGDP(false);
                handleDataReceive(resp, reloadDataFunction)
            });
    }, [uwagi]);

    return (
        <InputGroup>
            {!!showInput &&
                <Form.Control type={"text"} placeholder={"Uwagi dla koordynatora (opcjonalnie)"}
                              onChange={e => setUwagi(e.target.value)}
                              disabled={disabledButton} defaultValue={defaultUwagi}
                />
            }
            {zgloszoneGDP ?
                <>
                    <Button onClick={onZmiany} variant={"outline-success"} disabled={disabledButton}>
                        {czySamodzielnyZapis ? zmianyButtonText : endZgloszeniaText}
                    </Button>
                    {!!dopuszczalnaRezygnacja &&
                        <DeleteButton onClick={onRezygnacja} confirmTitle={"Rezygnacja z GDP"} buttonText={"Zrezygnuj"}
                                      confirmText={"Czy na pewno chcesz zrezygnować z GDP?"} buttonWithIcon={false}/>
                    }
                </>
                :
                <Button onClick={onZapis} variant={"success"} disabled={disabledButton}>
                    {czySamodzielnyZapis ? zapisButtonText : endZgloszeniaText}
                </Button>
            }
        </InputGroup>
    )
}
