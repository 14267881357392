import {Button} from "react-bootstrap";
import {useCallback, useEffect, useMemo, useState} from "react";
import {zapiszSieZmiana} from "../../../apiCalls/zmianyApi";
import {handleDataReceive} from "../../../components/ModalForm";
import {getCooldown} from "../../../helpers/authHelper";
import {parseDate} from "../../../helpers/dataHelper";

/**
 * Zapisz się na służbę
 * @param {int} zmianaID
 * @param {int} trakcjaID
 * @param {string|null} dataPublikacji
 * @param {function(string)} setErrorText
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function ZapiszSie({zmianaID, trakcjaID, dataPublikacji, setErrorText, reloadDataFunction}) {
    const [isLoading, setIsLoading] = useState(false);

    const cooldownTimes = useMemo(() => getCooldown(), []);
    const publikacjaTime = useMemo(() => parseDate(dataPublikacji), []);

    /**
     * Wyliczanie godziny zakończenia cooldown
     * @type int
     */
    const calculatedCooldownTime = useMemo(() => {
        if (!publikacjaTime)
            return 0;
        const time = cooldownTimes[trakcjaID] ? cooldownTimes[trakcjaID].cooldown * 1000 : 0;
        return publikacjaTime.getTime() + time;
    }, [trakcjaID]);

    // Czas do zakończenia cooldown
    const [cooldown, setCooldown] = useState(calculatedCooldownTime > Date.now() ? calculatedCooldownTime - Date.now : 0);

    // Odliczanie cooldown
    useEffect(() => {
        const interval = setInterval(() => setCooldown(calculatedCooldownTime - Date.now()), 1000);
        return () => clearInterval(interval);
    }, []);

    const isCooldown = useMemo(() => cooldown > 0, [cooldown]);
    const [cooldownText, setCooldownText] = useState("")

    useEffect(() => {
        const t = new Date(cooldown);
        let godz = t.getHours() - 1;
        let min = t.getMinutes();
        let sek = t.getSeconds();
        godz = godz < 10 ? "0" + godz.toString() : godz.toString();
        min = min < 10 ? "0" + min.toString() : min.toString();
        sek = sek < 10 ? "0" + sek.toString() : sek.toString();
        setCooldownText(`${godz}:${min}:${sek}`);
    }, [cooldown]);

    const onClick = useCallback(() => {
        setIsLoading(true);
        zapiszSieZmiana(setErrorText, zmianaID)
            .then(resp => {
                handleDataReceive(resp, reloadDataFunction);
                setIsLoading(false);
            });
    }, [zmianaID]);

    const buttonText = useMemo(() => {
        if (!publikacjaTime)
            return "Zapis możliwy po publikacji";
        if (isCooldown)
            return `Zapisz się za ${cooldownText}`;
        return isLoading ? "Zapisuję..." : "Zapisz się";
    }, [isLoading, isCooldown, cooldownText, publikacjaTime]);

    /** @type boolean */
    const buttonDisabled = useMemo(() => {
        if (!publikacjaTime)
            return true;
        if (isCooldown)
            return true;
        return isLoading;
    }, [isLoading, isCooldown, publikacjaTime]);

    return (
        <Button variant={"success"} onClick={onClick} disabled={buttonDisabled}>
            {buttonText}
        </Button>
    )
}
