import {Button, Form} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import {loginApi} from "../apiCalls/authApi";
import ErrorBanner from "../components/ErrorBanner";
import {getConfirmTextParam, getErrorTextParam} from "../helpers/searchParamsHelper";
import {saveToken} from "../helpers/authHelper";
import {ConfirmBanner} from "../components/ConfirmBanner";

/**
 * Ekran logowania
 * @param {function(string|null)} setToken
 * @returns {JSX.Element}
 * @constructor
 */
function Login({setToken}) {
    const [errorText, setErrorText] = useState(getErrorTextParam());
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    async function onSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        setErrorText(null);
        loginApi({email, password}, setErrorText)
            .then(token => {
                if (token) {
                    saveToken(token, setToken);
                    navigate("/kalendarz");
                }
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <ErrorBanner errorText={errorText}/>
            <ConfirmBanner confirmText={getConfirmTextParam()}/>
            <Form onSubmit={e => onSubmit(e)}>
                <Form.Group className="mb-2">
                    <Form.Control type="email" placeholder="E-mail" onChange={e => setEmail(e.target.value)} required/>
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Control type="password" placeholder="Hasło" onChange={e => setPassword(e.target.value)}
                                  required/>
                </Form.Group>
                <div className="d-grid">
                    <Button variant="success" type="submit"
                            disabled={isLoading}>{isLoading ? "Logowanie..." : "Zaloguj się"}
                    </Button>
                </div>
            </Form>
            <hr/>
            <div className="py-2">
                <div className="mb-2">Nie pamiętasz hasła lub logujesz się pierwszy raz?</div>
                <div className="d-grid">
                    <Button variant="outline-secondary" href="/forgot">Zresetuj hasło</Button>
                </div>
            </div>
            <hr/>
            <div className="py-2">
                Po zalogowaniu przeglądarka zapisze na&nbsp;twoim komputerze dane dotyczące twojego konta. Będą one tam przechowywane aż do&nbsp;wylogowania.
            </div>
        </>
    )
}

export default Login;
