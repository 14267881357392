import {_apiCall, MethodType} from "./_apiCall";
import {stringifyParamValue} from "../helpers/searchParamsHelper";

const addrBaza = "zajezdnie";

/**
 * @typedef ZajezdniaData
 *
 * @property {int} ZajezdniaID
 * @property {TrakcjaData} trakcja
 * @property {string} oznaczenie
 * @property {string} nazwa
 */

/**
 * Pobiera listę zajezdni (opcjonalnie pofitrowaną wg trakcji)
 * @param {function(string)} setErrorText
 * @param {string|int|undefined} trakcja
 * @returns {Promise<ZajezdniaData[]>}
 */
export function getZajezdnie(setErrorText, trakcja = undefined) {
    let addr = trakcja === undefined ? addrBaza : addrBaza + "?trakcja=" + stringifyParamValue(trakcja);
    return _apiCall(addr, MethodType.GET, setErrorText)
        .then((resp) => resp.data)
        .catch(() => []);
}
