import {_apiCall, MethodType} from "./_apiCall";

/**
 * GDP
 * @typedef GDPdata
 *
 * @property {UserTelefon & {nrKlubowy: int}} User
 * @property {string|null} uwagi
 */

/**
 * Zwraca adres zapytania
 * @param {string} data
 * @return {string}
 */
function addrBaza(data) {
    return `kalendarz/${data}/gdp`;
}

/**
 * Zapisuje użytkownika na GDP
 * @param {function(string)} setErrorText
 * @param {string} data
 * @param {int|string|undefined} UserID Jeżeli brak – samodzielny zapis
 * @param {string} uwagi
 * @return {Promise<AxiosResponse|null>}
 */
export function zapisGDP(setErrorText, data, uwagi, UserID = undefined) {
    const content = UserID ? {uwagi} : {UserID, uwagi};
    return _apiCall(addrBaza(data), MethodType.POST, setErrorText, content)
        .then(resp => resp.data)
        .catch(() => null)
}

/**
 * Wypisuje użytkownika z GDP
 * @param {function(string)} setErrorText
 * @param {string} data
 * @param {int|string|undefined} UserID Jeżeli brak — samodzielna rezygnacja
 */
export function wypisGDP(setErrorText, data, UserID = undefined) {
    let addr = addrBaza(data);
    addr = UserID ? `${addr}/${UserID}` : addr;
    return _apiCall(addr, MethodType.DELETE, setErrorText)
        .then(resp => resp.data)
        .catch(err => {
            if (err.code === 404) {
                setErrorText("Nie znaleziono użytkownika do usunięcia z GDP");
            }
            return null;
        })
}

/**
 * Aktualizuje uwagi dla koordynatora dot. GDP bieżącego użytkownika
 * @param {function(string)} setErrorText
 * @param {string} data
 * @param {string} uwagi
 */
export function aktualizujGDP(setErrorText, data, uwagi) {
    return _apiCall(addrBaza(data), MethodType.PUT, setErrorText, {uwagi})
        .then(resp => resp.data)
        .catch(() => null);
}
