import {handleDataReceive, ModalForm} from "../../../components/ModalForm";
import {useCallback, useState} from "react";
import {getInstruktorzy} from "../../../apiCalls/userApi";
import ErrorBanner from "../../../components/ErrorBanner";
import {Form} from "react-bootstrap";
import {addInstruktor} from "../../../apiCalls/szkolTabApi";

/**
 * Wyznaczanie instruktora do szkolenia taborowego
 * @param {int} zmianaID
 * @param {int} kursantID
 * @param {string} kursantImie
 * @param {string} kursantNazwisko
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function WyznaczInstruktora({zmianaID, kursantID, kursantImie, kursantNazwisko, reloadDataFunction}) {
    const [errorText, setErrorText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    /** @type FilteredUsers[] */
    let instruktorzyList;
    let setInstruktorzyList;
    [instruktorzyList, setInstruktorzyList] = useState([]);

    const [instruktorID, setInstruktorID] = useState("");

    const onOpen = useCallback(() => {
        setIsLoading(true);
        getInstruktorzy(setErrorText)
            .then(resp => {
                setInstruktorzyList(resp);
                setIsLoading(false);
            });
    }, []);

    const onSubmit = useCallback(() => {
        setErrorText("");
        return addInstruktor(setErrorText, zmianaID, kursantID, instruktorID)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [zmianaID, kursantID, instruktorID]);

    const onReset = useCallback(() => {
        setErrorText("");
        setInstruktorID("");
    }, [instruktorID]);

    return (
        <ModalForm
            openButtonText={"Wyznacz instruktora"}
            openButtonVariant={"outline-primary"}
            title={"Wyznaczanie instruktora"}
            childrenOnReset={onReset}
            childrenOnSubmit={onSubmit}
            childrenOnOpen={onOpen}
        >
            <ErrorBanner errorText={errorText}/>

            <Form.Group className={"mb-2"}>
                <Form.Label>Kursant</Form.Label>
                <Form.Control disabled={true} value={`${kursantImie} ${kursantNazwisko}`}/>
            </Form.Group>

            <Form.Group>
                <Form.Label>Instruktor</Form.Label>
                <Form.Select disabled={isLoading} name={"instruktorID"}
                             value={instruktorID} onChange={e => setInstruktorID(e.target.value)}
                >
                    <option disabled={!!instruktorID}>
                        {isLoading ? "Ładowanie listy instruktorów..." : "Wybierz instruktora"}
                    </option>
                    {
                        instruktorzyList.map(i =>
                            <option key={i.UserID} value={i.UserID}>
                                {i.imie} {i.nazwisko}
                            </option>)
                    }
                </Form.Select>
            </Form.Group>
        </ModalForm>
    )
}
