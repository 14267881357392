import {useCallback, useState} from "react";
import {delSzkolStac} from "../../../apiCalls/szkolStacApi";
import {handleDataReceive, ModalForm} from "../../../components/ModalForm";
import ErrorBanner from "../../../components/ErrorBanner";

/**
 * Usuwa szkolenie
 * @param {int} idSzkolenia
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function OdwolajSzkolenie({idSzkolenia, reloadDataFunction}) {
    const [errorText, setErrorText] = useState(null);

    const onSubmit = useCallback(() => {
        setErrorText("");

        return delSzkolStac(setErrorText, idSzkolenia)
            .then(resp => handleDataReceive(resp, reloadDataFunction))
    }, [idSzkolenia]);

    const onReset = useCallback(() => {
        setErrorText("");
    }, [idSzkolenia])

    return (
        <ModalForm
            title={"Usuwanie szkolenia stacjonarnego"}
            openButtonText={"Odwołaj szkolenie"}
            openButtonVariant={"outline-danger"}
            isInButtonGroup={true}
            childrenOnSubmit={onSubmit}
            childrenOnReset={onReset}
        >
            <ErrorBanner errorText={errorText}/>
            Czy na pewno chcesz usunąć szkolenie stacjonarne?
        </ModalForm>
    )
}
