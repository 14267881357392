import {_apiCall, isSuccess, MethodType} from "./_apiCall";

const addrBaza = "szkolenia/stacjonarne"

/**
 * Szkolenie stacjonarne (z listą uczestników)
 * @typedef {SzkolStacBasic & {users: UserTelefon[]}} SzkolStac
 */

/**
 * Szkolenie stacjonarne (bez listy uczestników)
 * @typedef SzkolStacBasic
 *
 * @property {int} SzkolenieID
 * @property {string} data
 * @property {string} nazwa
 * @property {string} godzRozp
 * @property {string} godzZak
 * @property {string} czasTrwania
 * @property {string} miejsce
 * @property {boolean} czyZakonczone
 */

/**
 * Pobiera listę wszystkich szkoleń stacjonarnych
 * @param {function(string)} setErrorText
 * @returns Promise<SzkolStacBasic[]>
 */
export function getSzkoleniaStacjonarne(setErrorText) {
    return _apiCall(addrBaza, MethodType.GET, setErrorText)
        .then(resp => resp.data)
        .catch(() => []);
}

/**
 * Dodaje szkolenie stacjonarne
 * @param {function(string)} setErrorText
 * @param {string} data
 * @param {string} nazwa
 * @param {string} godzRozp
 * @param {string} godzZak
 * @param {string} miejsce
 */
export function addSzkolStac(setErrorText, data, nazwa, godzRozp, godzZak, miejsce) {
    const content = {data, nazwa, godzRozp, godzZak, miejsce};
    return _apiCall(addrBaza, MethodType.POST, setErrorText, content)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Edytuj szkolenie stacjonarne
 * @param {function(string)} setErrorText
 * @param {int} szkolenieID
 * @param {string} data
 * @param {string} nazwa
 * @param {string} godzRozp
 * @param {string} godzZak
 * @param {string} miejsce
 * @returns {Promise<AxiosResponse>}
 */
export function editSzkolStac(setErrorText, szkolenieID, data, nazwa, godzRozp, godzZak, miejsce) {
    const content = {data, nazwa, godzRozp, godzZak, miejsce};
    return _apiCall(`${addrBaza}/${szkolenieID}`, MethodType.PUT, setErrorText, content)
        .then(resp => resp.data)
        .catch(() => null)
}

/**
 * Usuwa szkolenie stacjonarne
 * @param {function(string)} setErrorText
 * @param {int} szkolenieID
 * @returns {Promise<AxiosResponse|null>}
 */
export function delSzkolStac(setErrorText, szkolenieID) {
    return _apiCall(`${addrBaza}/${szkolenieID}`, MethodType.DELETE, setErrorText)
        .then(resp => resp.data)
        .catch(() => null)
}

/**
 * Dodaje uczestnika szkolenia stacjonarnego
 * @param {function(string)} setErrorText
 * @param szkolenieID
 * @param userID
 * @returns {Promise<boolean>}
 */
export function addSzkolStacMember(setErrorText, szkolenieID, userID) {
    return _apiCall(`${addrBaza}/${szkolenieID}/users/${userID}`, MethodType.POST, setErrorText)
        .then(resp => isSuccess(resp))
        .catch(() => null)
}

/**
 * Usuwa uczestnika szkolenia
 * @param {function(string)} setErrorText
 * @param szkolenieID
 * @param userID
 * @returns {Promise<boolean>}
 */
export function delSzkolStacMember(setErrorText, szkolenieID, userID) {
    return _apiCall(`${addrBaza}/${szkolenieID}/users/${userID}`, MethodType.DELETE, setErrorText)
        .then(resp => isSuccess(resp))
        .catch(() => null)
}

/**
 * Nadaje uprawnienia uczestnikom szkolenia
 * @param {function(string)} setErrorText
 * @param {int} szkolenieID
 * @param {int|string} kategoriaID
 * @return {Promise<boolean>}
 */
export function nadajUprawnieniaMembers(setErrorText, szkolenieID, kategoriaID) {
    return _apiCall(`${addrBaza}/${szkolenieID}/users`, MethodType.PUT, setErrorText, {KategoriaID: kategoriaID})
        .then(resp => isSuccess(resp))
        .catch(() => null)
}
