import {_apiCall, MethodType} from "./_apiCall";

const addrBaza = "szkolenia/taborowe";

/**
 * @typedef SzkolTabData
 * @property {SzkolTabZmiana} zmiana
 * @property {{KategoriaID: int, nazwa: string}} kategoria
 * @property {UserTelefon} user
 * @property {UserTelefon|null} instruktor
 * @property {string|null} wynik
 * @property {string|null} uwagi
 * @property {boolean} czyZakonczone
 */

/**
 * @typedef SzkolTabZmiana
 * @property {string} data
 * @property {string} data_raw
 * @property {int} zmianaID
 * @property {string} linia
 * @property {string|null} brygada
 * @property {string|null} oznaczenie
 * @property {string} nazwa
 */

/**
 * Szkolenie Taborowe (informacje do publicznodostępnego grafiku)
 * @typedef SzkolTabGrafik
 * @property {UserTelefon} instruktor
 */

/**
 * Pobiera listę wszystkich szkoleń taborowych
 * @param {function(string)} setErrorText
 * @returns {Promise<SzkolTabData[]>}
 */
export function getSzkoleniaTaborowe(setErrorText) {
    return _apiCall(addrBaza, MethodType.GET, setErrorText)
        .then(resp => resp.data)
        .catch(() => []);
}

/**
 * Wyznaczanie instruktora do szkolenia
 * @param {function(string)} setErrorText
 * @param {int} zmianaID
 * @param {int} kursantID
 * @param {string|int|null} instruktorID
 */
export function addInstruktor(setErrorText, zmianaID, kursantID, instruktorID) {
    const addr = `${addrBaza}/${zmianaID}/${kursantID}/instruktor`;
    const content = {instruktorID};
    return _apiCall(addr, MethodType.PUT, setErrorText, content)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Usuwanie wyznaczonego instruktora
 * @param {function(string)} setErrorText
 * @param {int} zmianaID
 * @param {int} kursantID
 */
export function deleteInstruktor(setErrorText, zmianaID, kursantID) {
    return addInstruktor(setErrorText, zmianaID, kursantID, null);
}

/**
 * Wypełnienie raportu ze szkolenia
 * @param {function(string)} setErrorText
 * @param {int} zmianaID
 * @param {int} kursantID
 * @param {string|boolean} wynik
 * @param {string} uwagi
 */
export function szkolenieRaport(setErrorText, zmianaID, kursantID, wynik, uwagi) {
    const addr = `${addrBaza}/${zmianaID}/${kursantID}/raport`;
    const content = {wynik, uwagi};
    return _apiCall(addr, MethodType.PUT, setErrorText, content)
        .then(resp => resp.data)
        .catch(() => null);
}
