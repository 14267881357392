import {useEffect, useState} from "react";
import ErrorBanner from "./ErrorBanner";
import {LoadingSpinner} from "./LoadingSpinner";

/**
 * Ciało głównej strony
 * @param {string|null} errorText
 * @param {string} pageTitle
 * @param {()=>Promise<>|undefined} loadDataFunction
 * @param {[*]} loadDataDeps
 * @param {boolean} waitForData Stwierdza czy przed wyświetleniem strony dane muszą zostać w pełni załadowane
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function Main({
                         errorText = null,
                         pageTitle,
                         loadDataFunction,
                         loadDataDeps = [],
                         waitForData = false,
                         children
                     }) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (loadDataFunction)
            loadDataFunction()
                .then(() => setIsLoading(false))
        else setIsLoading(false);
    }, loadDataDeps);

    return (
        <>
            <h1>{pageTitle}</h1>
            <ErrorBanner errorText={errorText}/>
            <LoadingSpinner isLoading={isLoading}/>
            {waitForData ? (!isLoading && children) : children}
        </>
    )
}
