import {useCallback, useEffect, useState} from "react";
import {ButtonIcon, handleDataReceive, ModalForm} from "../../../components/ModalForm";
import {getKalendarz, getKalendarzDataBrygadyList} from "../../../apiCalls/kalendarzApi";
import {Form} from "react-bootstrap";
import ErrorBanner from "../../../components/ErrorBanner";
import {copyBrygada} from "../../../apiCalls/brygadyApi";

/**
 * Kopiuje dane brygady do bieżącego dnia
 * @param {string} data
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function KopiujBrygade({data, reloadDataFunction}) {
    const [errorText, setErrorText] = useState("");
    const [isDatyLoaded, setIsDatyLoaded] = useState(false);
    const [isBrygadyLoaded, setIsBrygadyLoaded] = useState(false);
    /** @type KalendarzData */
    let datyList;
    let setDatyList;
    [datyList, setDatyList] = useState({});
    /** @type DataBrygadyList[] */
    let brygadyList;
    let setBrygadyList;
    [brygadyList, setBrygadyList] = useState([]);

    const [copyData, setCopyData] = useState("");
    const [copyBrygadaID, setCopyBrygadaID] = useState("");
    const [copyZmiany, setCopyZmiany] = useState(false);

    // Ładowanie dat (wszystkich)
    const onOpen = useCallback(() => {
        setIsDatyLoaded(false);
        getKalendarz(setErrorText, true)
            .then(resp => {
                setDatyList(resp);
                setIsDatyLoaded(true);
            })
    }, []);

    //Ładowanie brygad z dnia
    useEffect(() => {
        if (copyData) {
            setIsBrygadyLoaded(false);
            getKalendarzDataBrygadyList(setErrorText, copyData)
                .then(resp => {
                    setBrygadyList(resp);
                    setIsBrygadyLoaded(true);
                });
        } else {
            setBrygadyList([]);
            setCopyBrygadaID("");
        }
    }, [copyData])

    const onSubmit = useCallback(() => {
        setErrorText(null);
        return copyBrygada(setErrorText, copyBrygadaID, data, copyZmiany)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [copyBrygadaID, data, copyZmiany]);

    const onReset = useCallback(() => {
        setErrorText(null);
        setCopyData("");
        setCopyBrygadaID("");
        setCopyZmiany(false);
    }, [copyData, copyBrygadaID, copyZmiany]);

    return (
        <ModalForm
            title={"Kopiowanie brygady"}
            openButtonText={"Kopiuj brygadę"}
            openButtonIcon={ButtonIcon.COPY}
            openButtonVariant={"outline-success"}
            isInButtonGroup={true}
            childrenOnOpen={onOpen}
            childrenOnSubmit={onSubmit}
            childrenOnReset={onReset}
        >
            <ErrorBanner errorText={errorText}/>

            <Form.Group className={"mb-2"}>
                <Form.Text>Wybierz datę</Form.Text>
                <Form.Select disabled={!isDatyLoaded} value={copyData}
                             onChange={e => setCopyData(e.target.value)}>
                    <option disabled={copyData !== ""}>
                        {isDatyLoaded ? "Wybierz datę" : "Ładowanie kalendarza..."}
                    </option>
                    {
                        Object.entries(datyList).map(([data]) =>
                            <option key={data} value={data}>
                                {data}
                            </option>)
                    }
                </Form.Select>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Text>Wybierz brygadę</Form.Text>
                <Form.Select disabled={!isBrygadyLoaded} value={copyBrygadaID}
                             onChange={e => setCopyBrygadaID(e.target.value)}>
                    <option disabled={copyBrygadaID !== ""}>
                        {copyData ? (isBrygadyLoaded ? "Wybierz brygadę" : "Ładowanie brygad") : "Najpierw wybierz datę"}
                    </option>
                    {
                        brygadyList.map(b =>
                            <option key={b.BrygadaID} value={b.BrygadaID}>
                                {b.nazwa} ({b.trakcja.nazwa})
                            </option>)
                    }
                </Form.Select>
            </Form.Group>

            <Form.Group>
                <Form.Check
                    type={"switch"}
                    id={"zmiany"}
                    label={"Kopiuj razem ze zmianami"}
                    checked={copyZmiany}
                    onChange={e => setCopyZmiany(e.target.checked)}
                />
            </Form.Group>
        </ModalForm>
    )
}
