import {getToken} from "./helpers/authHelper";
import LoggedRouter from "./routers/LoggedRouter";
import UnloggedRouter from "./routers/UnloggedRouter";
import {useState} from "react";

function App() {
    const [token, setToken] = useState(getToken());
    return (
        <>
            {token ? <LoggedRouter token={token} setToken={setToken}/> : <UnloggedRouter setToken={setToken}/>}
        </>
    );
}

export default App;
