import {_apiCall, MethodType} from "./_apiCall";

const addrBaza = "uprawnienia";

/**
 * @typedef UprawnieniaUser
 * @property {FilteredUsers} user
 * @property {boolean} czyPrzeszkolony
 */

/**
 * Pobiera listę użytkowników z uprawnieniami do służb w danej kategorii
 * @param {function(string)} setErrorText
 * @param {int} kategoriaID
 * @returns {Promise<UprawnieniaUser[]>}
 */
export function getUsersUprawnieniaList(setErrorText, kategoriaID) {
    return _apiCall(`${addrBaza}/users/${kategoriaID}`, MethodType.GET, setErrorText)
        .then(resp => resp.data)
        .catch(() => []);
}
