import {_apiCall, MethodType} from "./_apiCall";

/**
 * @typedef KategorieSluzbBasic
 * @property {int} KategoriaID
 * @property {string} nazwa
 * @property {string} nazwaGrafik
 */

/**
 * @typedef KategorieSluzbExtend
 * @property {TrakcjaData} trakcja
 * @property {int} liczbaSzkolen
 * @property {boolean} przyznawanaDomyslnie
 */

/**
 * @typedef {KategorieSluzbBasic|KategorieSluzbExtend} KategorieSluzbData
 */

const addrDef = "kategorie";

/**
 * Pobiera listę kategorii uprawnień konduktorskich
 * @param {function(string)} setErrorText
 * @param {int|null} trakcja
 * @return {Promise<[KategorieSluzbData]|[]>}
 */
export function getKategorieList(setErrorText, trakcja = null) {
    const addr = trakcja?`${addrDef}?trakcja=${trakcja}`:addrDef;
    return _apiCall(addr, MethodType.GET, setErrorText)
        .then(resp => resp.data)
        .catch(() => []);
}
