import {czasTrwania, dataToLocalString, parseTime, timeToString} from "../../helpers/dataHelper";
import {Button} from "react-bootstrap";
import {useCallback} from "react";

/**
 * Pozycja w grafiku konduktora
 * @param {Grafik} grafikData
 * @returns {JSX.Element}
 */
export function GrafikPosition({grafikData}) {
    const godzinySluzby = useCallback((godzRozp, godzZak, czas) => {
        return (
            <>
                {czasTrwania(godzRozp, godzZak)}
                <b>
                    {` (${timeToString(parseTime(czas), true)})`}
                </b>
            </>
        )
    }, []);

    const uwagi = useCallback((miejsceRozp, miejsceZak, zajezdnia) => {
        return (
            <>
                {zajezdnia && <p><b>Zajezdnia: </b>{zajezdnia}</p>}
                {miejsceRozp && <p><b>Rozpoczęcie służby: </b>{miejsceRozp}</p>}
                {miejsceZak && <p><b>Zakończenie służby: </b>{miejsceZak}</p>}
            </>
        )
    }, []);

    return (
        <tr>
            <td className={"center"}>{dataToLocalString(grafikData.data)}</td>
            <td className={"center"}>{grafikData.typ_nazwa}</td>
            {
                grafikData.typ === "KOORDYNATOR" &&
                <>
                    <td>{grafikData.koordynator_trakcja}</td>
                    <td colSpan={2}><i>Koordynacja służb konduktorskich</i></td>
                </>
            }
            {
                grafikData.typ === "KONDUKTOR" &&
                <>
                    <td>{grafikData.zadanie}</td>
                    <td>
                        {godzinySluzby(grafikData.godzRozp, grafikData.godzZak, grafikData.czasTrwania)}
                    </td>
                    <td>
                        {!!grafikData.konduktor_czySzkolenie &&
                            <p>
                                <b>BRYGADA SZKOLENIOWA<br/>Instruktor: </b>
                                {grafikData.konduktor_instruktor ? grafikData.konduktor_instruktor :
                                    <i>Jeszcze nie wybrano instruktora</i>}
                            </p>
                        }
                        {uwagi(grafikData.miejsceRozp, grafikData.miejsceZak, grafikData.zajezdnia)}
                    </td>
                </>
            }
            {
                grafikData.typ === "INSTRUKTOR" &&
                <>
                    <td>{grafikData.zadanie}</td>
                    <td>
                        {godzinySluzby(grafikData.godzRozp, grafikData.godzZak, grafikData.czasTrwania)}
                    </td>
                    <td>
                        <p><b>Rodzaj szkolenia: </b>{grafikData.instruktor_kategoria}</p>
                        <p><b>Kursant: </b>{grafikData.instruktor_kursant}</p>
                        {uwagi(grafikData.miejsceRozp, grafikData.miejsceZak, grafikData.zajezdnia)}
                    </td>
                </>
            }
            {
                grafikData.typ === "SZKOLENIE" &&
                <>
                    <td>{grafikData.zadanie}</td>
                    <td>
                        {godzinySluzby(grafikData.godzRozp, grafikData.godzZak, grafikData.czasTrwania)}
                    </td>
                    <td>
                        <p><b>Miejse szkolenia: </b>{grafikData.szkolenie_miejsce}</p>
                    </td>
                </>
            }
            {
                grafikData.typ === "GDP" &&
                <>
                    <td colSpan={3}>
                        <p>
                            <i>Oczekiwanie na zadania w miejscu wyznaczonym przez koordynatora</i>
                            {!!grafikData.gdp_uwagi &&
                                <>
                                    <br/><b>Uwagi dla koordynatora: </b>{grafikData.gdp_uwagi}
                                </>
                            }
                        </p>
                    </td>
                </>
            }
            <td>
                <Button href={`/kalendarz/${grafikData.data}`} variant={"outline-primary"}>Podgląd dnia</Button>
            </td>
        </tr>
    )
}
