import {ListGroup, Stack} from "react-bootstrap";
import {PhoneButton} from "../../../components/buttons/PhoneButton";
import {DeleteButton} from "../../../components/buttons/DeleteButton";
import {useCallback, useEffect} from "react";
import {wypisGDP} from "../../../apiCalls/gdpApi";
import {handleDataReceive} from "../../../components/ModalForm";

/**
 *
 * @param {string} data
 * @param {GDPdata} konduktor
 * @param {int} currentUser ID akutalnie zalogowanego użytkownika
 * @param {boolean} czyEdycja
 * @param {function(string)} setErrorText
 * @param {function()} reloadDataFunction
 * @param {function(boolean)} setZgloszoneGDP
 * @param {function(string)} setUwagi
 * @return {JSX.Element}
 * @constructor
 */
export function KonduktorGDP({
                                 data,
                                 konduktor,
                                 currentUser,
                                 czyEdycja,
                                 setErrorText,
                                 reloadDataFunction,
                                 setZgloszoneGDP,
                                 setUwagi
                             }) {
    const onDelete = useCallback(() => {
        wypisGDP(setErrorText, data, konduktor.User.UserID)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [konduktor])

    useEffect(() => {
        if (konduktor.User.UserID === currentUser) {
            setZgloszoneGDP(true);
            setUwagi(konduktor.uwagi);
        }
    }, [konduktor])

    return (
        <ListGroup.Item>
            <Stack gap={1} direction={"horizontal"}>
                <div className={czyEdycja ? "gdp" : ""}>{konduktor.User.imie}&nbsp;{konduktor.User.nazwisko}</div>
                <div className={"ms-auto"}>
                    <PhoneButton phoneNumber={konduktor.User.telefon}/>
                </div>
                {czyEdycja &&
                    <div>
                        <DeleteButton
                            confirmText={`Czy chcesz usunąć z GDP konduktora ${konduktor.User.imie} ${konduktor.User.nazwisko}?`}
                            confirmTitle={"Usuwanie konduktora z GDP"}
                            onClick={onDelete}
                        />
                    </div>
                }
            </Stack>
            {czyEdycja &&
                <p className={"gdp_uwagi"}>
                    {konduktor.uwagi ? konduktor.uwagi : "Brak uwag"}
                </p>
            }
        </ListGroup.Item>
    )
}
