import moment from "moment";

/**
 * Zamienia string na datę lub zwraca datę, jeżeli już nią jest
 * @param {string|Date} value
 * @returns {Date}
 */
export function parseDate(value) {
    return typeof value === "string" ? new Date(value) : value;
}

/**
 * Zamienia string na godzinę (UTC)
 * @param {string} value
 * @returns {Date}
 */
export function parseTime(value) {
    let time = moment.duration(value, "hours").asMilliseconds();
    return moment.utc(time).toDate();
}

/**
 * Zamienia wartość czasu na string
 * @param {Date} value
 * @param {boolean} isUTC Definiuje czy czas jest niezależny od strefy czasowej
 * @returns {string}
 */
export function timeToString(value, isUTC = false) {
    let godz = isUTC ? value.getUTCHours() : value.getHours();
    let min = isUTC ? value.getUTCMinutes() : value.getMinutes();
    if (godz < 10) godz = "0" + godz;
    if (min < 10) min = "0" + min;
    return `${godz}:${min}`;
}

/**
 * Przerabia ciąg znakowy zawierający czas trwania w formacie time na format HH:mm
 * @param {string} value
 * @returns string
 */
export function parseCzasTrwaniaToString(value) {
    const time = moment.duration(value, "hours");
    let m = time.minutes();
    let h = Math.floor(time.asHours());
    m = m < 10 ? `0${m}` : m.toString();
    h = h < 10 ? `0${h}` : h.toString();
    return `${h}:${m}`;
}

/**
 * Konwertuje datę na czytelny tekst
 * @param {Date|string} data
 * @return string
 */
export function dataToString(data) {
    data = parseDate(data);

    return data.getFullYear() + '-' +
        ('0' + (data.getMonth() + 1)).slice(-2) + '-' +
        ('0' + data.getDate()).slice(-2);
}

/**
 * Konwertuje datę na string w wersji lokalnej
 * @param {Date|string} data
 * @returns {string}
 */
export function dataToLocalString(data) {
    return parseDate(data).toLocaleDateString();
}

/**
 * Konwertuje wartość daty i czasu do formularza
 * @param {Date|string} data
 * @return string
 */
export function dateTimeToFormString(data) {
    if (!data)
        return "";
    data = parseDate(data);

    const time = ('0' + data.getHours()).slice(-2) + ':' + ('0' + data.getMinutes()).slice(-2);

    return `${dataToString(data)}T${time}`;
}

/**
 * Wytwarza widełki czasowe
 * @param {Date|string} czasPoczatek
 * @param {Date|string} czasKoniec
 */
export function czasTrwania(czasPoczatek, czasKoniec) {
    czasPoczatek = parseTime(czasPoczatek);
    czasKoniec = parseTime(czasKoniec);

    return `${timeToString(czasPoczatek, true)}-${timeToString(czasKoniec, true)}`;
}

/**
 * Definiuje czy czas końca jest później niż czas początku
 * @param {Date|string} czasPoczatek
 * @param {Date|string} czasKoniec
 * @returns {boolean}
 */
export function isEndAfterBegin(czasPoczatek, czasKoniec) {
    czasPoczatek = parseTime(czasPoczatek);
    czasKoniec = parseTime(czasKoniec);

    return czasKoniec.getTime() > czasPoczatek.getTime();
}

/**
 * Konwertuje wartość binarną na czytelny tekst
 * @param {boolean} value
 */
export function parseBool(value) {
    return value ? "TAK" : "NIE";
}

/**
 * Zwraca wartość parametru lub komunikat o braku danych, jeżeli wartość to null
 * @param {string|null} value
 * @param {string} noDataText
 * @returns {string|JSX.Element}
 */
export function optionalValue(value, noDataText = "Brak danych") {
    return !!value ? value : <i>{noDataText}</i>;
}
