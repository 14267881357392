import {Button, Form, InputGroup} from "react-bootstrap";
import {useEffect, useMemo, useState} from "react";
import {getUsers} from "../../apiCalls/userApi";

/**
 * Panel zarządzania grafikiem (i pobierania go na nowo)
 * @param {boolean} canReadOtherUsers
 * @param {string} defaultUserID
 * @param {string} defaultRok
 * @param {function(string)} setErrorText
 * @returns {JSX.Element}
 * @constructor
 */
export function GrafikSelect({canReadOtherUsers, defaultUserID, defaultRok, setErrorText}) {
    const [usersLoading, setUsersLoading] = useState(true);
    const [rok, setRok] = useState(defaultRok);
    const [userID, setUserID] = useState(defaultUserID);
    const lata = useMemo(() => {
        /** @type string[] */
        const toReturn = [];
        for (let i = 2020; i <= new Date().getFullYear(); i++) {
            toReturn.push(i.toString());
        }
        return toReturn;
    }, []);
    /** @type GetUsers[] */
    let usersList;
    let setUsersList;
    [usersList, setUsersList] = useState([]);

    useEffect(() => {
        if (!canReadOtherUsers)
            return;

        getUsers(setErrorText)
            .then(resp => {
                setUsersList(resp);
                setUsersLoading(false);
            });
    }, [canReadOtherUsers]);

    return (
        <div className={"ms-3 my-3"} style={{maxWidth: "50%"}}>
            <form method={"get"}>
                {
                    canReadOtherUsers &&
                    <Form.Group className={"mb-2"}>
                        <InputGroup>
                            <InputGroup.Text>Konduktor</InputGroup.Text>
                            <Form.Select name={"user"} disabled={usersLoading}
                                         value={userID} onChange={e => setUserID(e.target.value)}>
                                {
                                    usersLoading ? <option>Ładowanie listy użytkowników...</option> :
                                        usersList.map(u =>
                                            <option key={u.UserID} value={u.UserID}>
                                                {`${u.imie} ${u.nazwisko} ${u.nrKlubowy ? `(${u.nrKlubowy})` : ""}`}
                                            </option>)
                                }
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>
                }
                <Form.Group className={"mb-2"}>
                    <InputGroup>
                        <InputGroup.Text>Rok</InputGroup.Text>
                        <Form.Select name={"rok"} value={rok} onChange={e => setRok(e.target.value)}>
                            {
                                lata.map(r =>
                                    <option key={r} value={r}>{r}</option>
                                )
                            }
                        </Form.Select>
                    </InputGroup>
                </Form.Group>
                <Button type={"submit"}>Pobierz grafik</Button>
            </form>
        </div>
    )
}
