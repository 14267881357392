import {useCallback, useEffect, useState} from "react";
import {dataToString} from "../../helpers/dataHelper";
import Calendar from "react-calendar";

/**
 * Wyświetla kalendarz
 * @param {[KalendarzData]|{}} daty
 * @param {boolean} canReadHidden
 * @param {function(string)} navigate
 * @return {JSX.Element}
 * @constructor
 */
export function KalendarzElement({daty, canReadHidden, navigate}) {
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());

    // Ustalamy najmniejszą i największą datę
    useEffect(() => {
        // Brak dat w kalendarzu
        if (!daty)
            return;

        let min = minDate.getTime();
        let max = maxDate.getTime();
        for (let d in daty) {
            const timestamp = new Date(d).getTime();

            if (timestamp < min)
                min = timestamp;

            if (timestamp > max)
                max = timestamp;
        }
        setMinDate(new Date(min));
        setMaxDate(new Date(max));
    }, [daty])

    // Dezaktywacja niewymienionych w kalendarzu dni
    const dateDisabled = useCallback((x) => {
        // Brak dat w kalendarzu
        if (!daty)
            return true;

        if (x.view !== 'month')
            return;

        const d = daty[dataToString(x.date)];

        return d === undefined;
    }, [daty])

    // Ustalenie styli CSS dla poszczególnych dni
    const dateStyle = useCallback((x) => {
        //Brak dat w kalendarzu
        if (!daty)
            return;

        if (x.view !== 'month')
            return;

        const d = daty[dataToString(x.date)];

        // Data nie istnieje, czyli dalej nas nie interere
        if (d === undefined)
            return

        // Data archiwalna
        if (d.archieved)
            return "callendar_archieved"

        if (d.published)
            return "callendar_published"   // Dzień opublikowany
        else
            return "callendar_unpublished"   // Dzień nieopublikowany
    }, [daty])

    // Akcja do wykonania po wyborze daty
    const dateSelect = ((value) => navigate(`/kalendarz/${dataToString(value)}`));

    return (
        <Calendar
            tileDisabled={dateDisabled}
            tileClassName={dateStyle}
            minDetail={canReadHidden && daty ? "year" : "month"}
            minDate={minDate}
            maxDate={maxDate}
            onChange={dateSelect}
        />)
}
