import React, {useEffect, useState} from "react";
import {getTrakcje} from "../../../apiCalls/trakcjaApi";
import {Form} from "react-bootstrap";

export function WyborTrakcji({setErrorText, setTrakcja, groupName = "trakcja", optional = false}) {
    const [trakcje, setTrakcje] = useState([]);

    useEffect(() => {
        getTrakcje(setErrorText)
            .then(resp => setTrakcje(resp))
    }, [])
    const onChange = (event) => setTrakcja(event.target.value);

    return (
        <div className={"mb-2 center"}>
            {trakcje === undefined || trakcje.length === 0 ? " " :
                trakcje.map(t => {
                    const label = t.imgPath === null ? t.nazwa :
                        <img className={"trakcja_img"} src={t.imgPath} alt={t.nazwa}/>
                    return (
                        <Form.Check
                            key={t.TrakcjaID}
                            inline={true}
                            label={label}
                            name={groupName}
                            type={"radio"}
                            id={`${groupName}-${t.TrakcjaID}`}
                            value={t.TrakcjaID}
                            onChange={onChange}
                            required={!optional}
                        />
                    )
                })}
        </div>)
}
