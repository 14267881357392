import {Col, Container, ListGroup, Row, Stack} from "react-bootstrap";
import {KonduktorGDP} from "./GDPElements/KonduktorGDP";
import {useState} from "react";
import {ZapiszKonduktoraGDP} from "./GDPElements/ZapiszKonduktoraGDP";
import {ZapisGDP} from "./GDPElements/ZapisGDP";

/**
 *
 * @param {string} data
 * @param {[GDPdata]} gdpData
 * @param {boolean} czySamodzielnyZapisGDP Definiuje czy obecnie jest możliwy samodzielny zapis na GDP
 * @param {boolean} dopuszczalnaRezygnacja Definiuje czy obecnie jest możliwa rezygnacja z GDP
 * @param {int} currentUser
 * @param {function()} reloadDataFunction
 * @param {boolean} czyEdycja
 * @param {boolean} canGDP Definiuje czy użytkownik ma uprawnienia do zapisu na GDP
 * @param {function(string)} setErrorText
 * @return {JSX.Element}
 * @constructor
 */
export function GDP({
                        data,
                        gdpData,
                        czySamodzielnyZapisGDP,
                        dopuszczalnaRezygnacja,
                        currentUser,
                        reloadDataFunction,
                        czyEdycja,
                        canGDP,
                        setErrorText
                    }) {
    //todo
    const [zgloszoneGDP, setZgloszoneGDP] = useState(false);
    const [defaultUwagi, setDefaultUwagi] = useState("");

    return (
        <>
            <h2>Gotowość do Pracy</h2>
            <Container className={"mb-2"} fluid>
                <Row md={2} sm={1}>
                    {canGDP &&
                        <Col lg={4} className={"mx-auto"}>
                            <ZapisGDP
                                data={data}
                                defaultUwagi={defaultUwagi}
                                zgloszoneGDP={zgloszoneGDP}
                                setZgloszoneGDP={setZgloszoneGDP}
                                czySamodzielnyZapis={czySamodzielnyZapisGDP}
                                dopuszczalnaRezygnacja={dopuszczalnaRezygnacja}
                                reloadDataFunction={reloadDataFunction}
                                setErrorText={setErrorText}
                            />
                        </Col>
                    }
                    {czyEdycja &&
                        <Col lg={4}>
                            <ZapiszKonduktoraGDP data={data} reloadDataFunction={reloadDataFunction}/>
                        </Col>
                    }
                </Row>
            </Container>
            {gdpData && gdpData.length > 0 &&
                <Stack>
                    <ListGroup className={"mx-md-auto"} horizontal={"md"}>
                        {gdpData.map(g =>
                            <KonduktorGDP key={g.User.UserID}
                                          data={data}
                                          konduktor={g}
                                          currentUser={currentUser}
                                          czyEdycja={czyEdycja}
                                          setErrorText={setErrorText}
                                          reloadDataFunction={reloadDataFunction}
                                          setZgloszoneGDP={setZgloszoneGDP}
                                          setUwagi={setDefaultUwagi}
                            />
                        )}
                    </ListGroup>
                </Stack>
            }
        </>
    )
}
