import {Form, InputGroup} from "react-bootstrap";
import {useEffect, useState} from "react";

/**
 * Rozdziela string z czasem na godzinę i minutę
 * @param {string} value
 * @returns {string[]}
 */
function splitTime(value) {
    const splittedValue = value.split(":");
    if (splittedValue.length < 2)
        return ["", ""];
    else
        return [splittedValue[0], splittedValue[1]]
}

/**
 * Zwraca pole do wprowadzania godziny
 * @param {string} godzina
 * @param {function(string)} setGodzina
 * @param {string} name
 * @param {boolean} required
 * @returns {JSX.Element}
 * @constructor
 */
export function GodzinaInput({godzina, setGodzina, name, required = true}) {
    const [godz, setGodz] = useState("");
    const [min, setMin] = useState("");

    useEffect(() => {
        const [h, m] = splitTime(godzina);
        setGodz(h);
        setMin(m);
    }, [godzina]);

    useEffect(() => {
        const g = parseInt(godz);
        const m = parseInt(min);
        if (!isNaN(g) && !isNaN(m))
            setGodzina(godz + ":" + min)
    }, [godz, min])

    return (
        <>
            <Form.Control type={"number"} htmlSize={2} min={0} required={required} name={name + "_godz"}
                          value={godz} onChange={e => setGodz(e.target.value)}/>
            <InputGroup.Text>:</InputGroup.Text>
            <Form.Control type={"number"} htmlSize={2} min={0} max={59} required={required} name={name + "_min"}
                          value={min} onChange={e => setMin(e.target.value)}/>
        </>
    )
}
