import {Main} from "../components/Main";
import {useCallback, useMemo, useState} from "react";
import {getGrafik} from "../apiCalls/userApi";
import {Table} from "react-bootstrap";
import {GrafikPosition} from "./grafikElements/GrafikPosition";
import {GrafikSelect} from "./grafikElements/GrafikSelect";
import {useSearchParams} from "react-router-dom";
import {parseCzasTrwaniaToString} from "../helpers/dataHelper";

/**
 * Wyświetla grafik użytkownika
 * @param {string} errorText
 * @param {function(string)} setErrorText
 * @param {UserData} currentUserData
 * @returns {JSX.Element}
 * @constructor
 */
export function Grafik({errorText, setErrorText, currentUserData}) {
    const [params] = useSearchParams();
    const userID = useMemo(() => {
        let u = params.get("user");
        if (u && !currentUserData.userUprawnienia.raporty_read)
            // Nie będziemy straszyć użytkownika komunikatami o błędach
            // Jak nie ma uprawnień do przeglądania obcych grafików to po prostu ich nie zobaczy
            u = null;
        return u ? u : undefined;
    }, [params, currentUserData]);
    const rok = useMemo(() => {
        const r = params.get("rok");
        return r ? r : new Date().getFullYear();
    }, [params]);

    /** @type Grafik[] */
    let grafik;
    let setGrafik;
    [grafik, setGrafik] = useState([]);

    const userName = useCallback((imie, nazwisko, nrKlubowy, wymianaOrganizacja, wymianaMiasto) => {
        nrKlubowy = nrKlubowy ? nrKlubowy : `${wymianaOrganizacja} ${wymianaMiasto}`;
        return `${imie} ${nazwisko} (${nrKlubowy})`;
    }, []);

    const [konduktor, setKonduktor] = useState("");
    const [czasPracySuma, setCzasPracySuma] = useState("");

    const loadData = useCallback(() => {
        return getGrafik(setErrorText, rok, userID)
            .then(resp => {
                if (resp) {
                    setGrafik(resp.grafik);
                    setKonduktor(userName(resp.imie, resp.nazwisko, resp.nrKlubowy, resp.wymiana.organizacja, resp.wymiana.miasto));
                    setCzasPracySuma(parseCzasTrwaniaToString(resp.sumaCzasTrwania));
                }
            });
    }, [rok, userID]);

    return (
        <Main
            errorText={errorText}
            pageTitle={`Grafik konduktora ${konduktor}`}
            loadDataFunction={loadData}
            waitForData={true}
        >
            <GrafikSelect
                canReadOtherUsers={currentUserData.userUprawnienia.raporty_read}
                defaultRok={rok}
                defaultUserID={userID}
                setErrorText={setErrorText}
            />

            {
                grafik.length === 0 ?
                    <div className={"h2 center mt-5"} style={{fontStyle: "italic"}}>Grafik jest pusty</div>
                    :
                    <div className={"mx-lg-3"}>
                        <Table bordered={true} striped={true} responsive={true}>
                            <thead>
                            <tr className={"bg-secondary text-light center"}>
                                <th>Data</th>
                                <th>Rodzaj służby</th>
                                <th>Zadanie</th>
                                <th>Godziny służby</th>
                                <th>Uwagi</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {grafik.map((g, v) =>
                                <GrafikPosition grafikData={g} key={v}/>)
                            }
                            <tr>
                                <th colSpan={3} style={{textAlign: "right"}}>Łączny czas pracy:</th>
                                <td><b>{czasPracySuma}</b></td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
            }
        </Main>
    )
}
