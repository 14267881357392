import {czasTrwania} from "../../../helpers/dataHelper";
import {KonduktorZmiana} from "./KonduktorZmiana";
import {useCallback} from "react";
import {ButtonGroup, Stack} from "react-bootstrap";
import {DodajZmiane} from "./DodajZmiane";
import {DeleteButton} from "../../../components/buttons/DeleteButton";
import {delZmiana} from "../../../apiCalls/zmianyApi";
import {handleDataReceive} from "../../../components/ModalForm";
import {ZapiszSie} from "./ZapiszSie";
import {ZapiszKonduktora} from "./ZapiszKonduktora";

/**
 *
 * @param {int} brygadaID
 * @param {string} brygadaName
 * @param {int} trakcjaID
 * @param {ZmianaData} zmianaData
 * @param {string} dataPublikacji
 * @param {boolean} czyEdycja
 * @param {boolean} dopuszczalnaRezygnacja
 * @param {int} currentUserID
 * @param {boolean} sluzbyZapis
 * @param {function()} reloadDataFunction
 * @param {function(string)} setErrorText
 * @constructor
 */
export function Zmiana({
                           brygadaID,
                           brygadaName,
                           trakcjaID,
                           zmianaData,
                           dataPublikacji,
                           czyEdycja,
                           dopuszczalnaRezygnacja,
                           currentUserID,
                           sluzbyZapis,
                           reloadDataFunction,
                           setErrorText
                       }) {
    const liczbaMiejsc = zmianaData.liczbaKonduktorow - zmianaData.konduktorzy.length;

    const onDelete = useCallback(() => {
        delZmiana(setErrorText, zmianaData.zmianaID)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [zmianaData]);

    return (
        <tr>
            <td className={"center"}>{zmianaData.nazwa}</td>
            <td className={"center"}>{czasTrwania(zmianaData.godzRozp, zmianaData.godzZak)}</td>
            <td>
                <div>
                    <b>Typ służby: </b>{zmianaData.kategoria.nazwaGrafik}
                </div>
                {zmianaData.miejsceRozp &&
                    <div>
                        <b>Miejsce rozpoczęcia: </b>{zmianaData.miejsceRozp}
                    </div>
                }
                {zmianaData.miejsceZak &&
                    <div>
                        <b>Miejsce zakończenia: </b>{zmianaData.miejsceZak}
                    </div>
                }
            </td>
            <td>
                {zmianaData.konduktorzy.map(k =>
                    <KonduktorZmiana
                        key={k.UserID}
                        zmianaID={zmianaData.zmianaID}
                        zmianaName={zmianaData.nazwa}
                        konduktorData={k}
                        czyEdycja={czyEdycja}
                        dopuszczalnaRezygnacja={dopuszczalnaRezygnacja}
                        currentUserID={currentUserID}
                        reloadDataFunction={reloadDataFunction}
                        setErrorText={setErrorText}
                    />
                )}
                {liczbaMiejsc > 0 &&
                    <Stack direction={"vertical"}>
                        <div className={"mx-auto"} style={{color: "green"}}>
                            Wolnych miejsc: <b>{liczbaMiejsc}</b>
                        </div>
                        <div className={"mx-auto"}>
                            <ButtonGroup vertical={true}>
                                {sluzbyZapis && zmianaData.czyZapis &&
                                    <ZapiszSie
                                        zmianaID={zmianaData.zmianaID}
                                        trakcjaID={trakcjaID}
                                        dataPublikacji={dataPublikacji}
                                        setErrorText={setErrorText}
                                        reloadDataFunction={reloadDataFunction}
                                    />
                                }
                                {czyEdycja &&
                                    <ZapiszKonduktora
                                        zmianaID={zmianaData.zmianaID}
                                        kategoriaID={zmianaData.kategoria.KategoriaID}
                                        zmianaName={zmianaData.nazwa}
                                        reloadDataFunction={reloadDataFunction}
                                    />}
                            </ButtonGroup>
                        </div>
                    </Stack>
                }
            </td>
            {czyEdycja &&
                <td>
                    <Stack>
                        <ButtonGroup className={"mx-auto"} vertical>
                            <DodajZmiane brygadaID={brygadaID} brygadaName={brygadaName} zmianaData={zmianaData}
                                         trakcjaID={trakcjaID} reloadDataFunction={reloadDataFunction}/>
                            <DeleteButton
                                buttonText={"Usuń zmianę"}
                                buttonWithIcon={true}
                                confirmText={`Czy na pewno chcesz usunąć z systemu zmianę ${zmianaData.nazwa}?`}
                                onClick={onDelete}
                            />
                        </ButtonGroup>
                    </Stack>
                </td>
            }
        </tr>
    )
}
