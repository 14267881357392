/**
 * Nazwa trakcji wraz z (opcjonalną) ikoną trakcji
 * @param {string|null} imgPath Adres grafiki trakcji
 * @param {string} nazwa Nazwa trakcji
 * @param {boolean} showNazwa Pokaż nazwę trakcji
 * @constructor
 */
export function Trakcja({imgPath, nazwa, showNazwa = false}) {
    const logoAlt = showNazwa ? "" : nazwa + ":";

    return (
        <>
            {imgPath === null ? logoAlt :
                <img className={"trakcja_img"} src={imgPath} alt={logoAlt}/>
            }
            {
                (showNazwa && imgPath !== null) && <span className={"px-3"}>{nazwa}</span>
            }
        </>
    );
}
