import {useCallback, useState} from "react";
import {getKalendarz} from "../apiCalls/kalendarzApi";
import {KalendarzElement} from "./kalendarzElements/KalendarzElement";
import {DodajDzien} from "./kalendarzElements/DodajDzien";
import {Main} from "../components/Main";

//TODO Zaznaczenie wybranej daty

/**
 * Kalendarz służb
 * @param {string} errorText
 * @param {function(string)} setErrorText
 * @param userUprawnienia
 * @param {function(string)} navigate
 * @returns {JSX.Element}
 * @constructor
 */
function Kalendarz({errorText, setErrorText, userUprawnienia, navigate}) {
    const [daty, setDaty] = useState({});

    // Pobieramy daty z kalendarza
    const loadData = useCallback(() => {
        return getKalendarz(setErrorText, userUprawnienia.kalendarz_read_hidden)
            .then(k => setDaty(k));
    }, [userUprawnienia])

    return (
        <Main
            errorText={errorText}
            pageTitle={"Kalendarz służb"}
            loadDataFunction={loadData}
            loadDataDeps={[userUprawnienia]}
        >
            {userUprawnienia.kalendarz_modify &&
                <div className="center">
                    <DodajDzien daty={daty} navigate={navigate}/>
                </div>
            }
            <KalendarzElement daty={daty} canReadHidden={userUprawnienia.kalendarz_read_hidden} navigate={navigate}/>
        </Main>
    )
}

export default Kalendarz;
