import {Table} from "react-bootstrap";
import {Szkolenie} from "./szkolStacElements/Szkolenie";

/**
 *
 * @param {[SzkolStac]} szkolenia
 * @param {function()} reloadDataFunction
 * @param {boolean} czyEdycja
 * @param {function(string)} setErrorText
 * @returns {JSX.Element}
 * @constructor
 */
export function Szkolenia({szkolenia, reloadDataFunction, czyEdycja, setErrorText}) {
    if (!szkolenia || szkolenia.length === 0)
        return (<></>);
    else
        return (
            <>
                <h2>Szkolenia stacjonarne</h2>

                <Table striped={true} bordered={true} responsive={"lg"}>
                    <thead>
                    <tr>
                        <th>Nazwa szkolenia</th>
                        <th>Czas trwania</th>
                        <th>Miejsce</th>
                        <th>Uczestnicy</th>
                        {czyEdycja && <th></th>}
                    </tr>
                    </thead>
                    <tbody>
                    {szkolenia.map(sz =>
                        <Szkolenie
                            key={sz.SzkolenieID}
                            szkolenieData={sz}
                            reloadDataFunction={reloadDataFunction}
                            czyEdycja={czyEdycja}
                            setErrorText={setErrorText}
                        />
                    )}
                    </tbody>
                </Table>
            </>
        )
}

