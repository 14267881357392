import {Form, InputGroup} from "react-bootstrap";
import Calendar from "react-calendar";
import {useCallback, useState} from "react";
import {dataToString} from "../../helpers/dataHelper";
import {ResetIcon} from "../../media/icons/ResetIcon";
import ErrorBanner from "../../components/ErrorBanner";
import {addKalendarzData} from "../../apiCalls/kalendarzApi";
import {ModalForm} from "../../components/ModalForm";

/**
 * Dodawanie dnia do kalendarza
 * @param {[KalendarzData]|{}} daty
 * @param {function(string)} navigate
 * @return {JSX.Element}
 * @constructor
 */
export function DodajDzien({daty, navigate}) {
    const [errorText, setErrorText] = useState("");
    const [data, setData] = useState(null);
    const [dataPublikacji, setDataPublikacji] = useState("");
    const [czyDopuszczoneGDP, setCzyDopuszczoneGDP] = useState(true);

    function dataIstnieje(data) {
        return daty[dataToString(data)] !== undefined;
    }

    const datyNieaktywne = useCallback(x => {
        if (x.view !== 'month')
            return;

        return dataIstnieje(x.date);
    }, [daty]);

    const datyStyle = useCallback(x => {
        if (x.view !== 'month')
            return;

        if (dataIstnieje(x.date))
            return "callendar_archieved";
    }, [daty])

    function onReset() {
        setData(null);
        setDataPublikacji("");
        setCzyDopuszczoneGDP(true);
        setErrorText("");
    }

    const onSubmit = useCallback(() => {
        if (data === null)
            return setErrorText("Wybierz datę którą chcesz dodać do kalendarza");

        setErrorText("");
        const dataPub = dataPublikacji === null ? null : new Date(dataPublikacji);
        return addKalendarzData(setErrorText, data, dataPub, czyDopuszczoneGDP)
            .then(addedId => {
                if (!addedId) {
                    return false;
                } else {
                    navigate("/kalendarz/" + addedId);
                    return true;
                }
            });
    }, [data, dataPublikacji, czyDopuszczoneGDP])

    return (
        <ModalForm
            title={"Dodawanie dnia"}
            openButtonText={"Dodaj dzień"}
            openButtonSize={"lg"}
            childrenOnReset={onReset}
            childrenOnSubmit={onSubmit}
        >
            <ErrorBanner errorText={errorText}/>
            <Calendar
                minDetail={"month"}
                tileDisabled={datyNieaktywne}
                tileClassName={datyStyle}
                value={data}
                onChange={(val) => setData(val)}
                className={"mb-3"}
            />

            <Form.Group className={"mb-3"} controlId={"dataPublikacji"}>
                <Form.Label>Termin publikacji dnia</Form.Label>
                <InputGroup>
                    <Form.Control
                        type={"datetime-local"}
                        value={dataPublikacji}
                        onChange={(e) => setDataPublikacji(e.target.value)}
                    />
                    <InputGroup.Text onClick={() => setDataPublikacji("")} className={"pointer"}>
                        <ResetIcon/>
                    </InputGroup.Text>
                </InputGroup>
                <Form.Text>
                    Jeżeli nie chcesz na razie określać terminu publikacji, pozostaw to pole puste.
                </Form.Text>
            </Form.Group>

            <Form.Check
                type={"switch"}
                id={"czyDopuszczoneGDP"}
                label={"Czy dopuszczone GDP?"}
                checked={czyDopuszczoneGDP}
                onChange={(e) => setCzyDopuszczoneGDP(e.target.checked)}
            />
        </ModalForm>)
}
