import {useCallback, useEffect, useState} from "react";
import {getKategorieList} from "../../../apiCalls/kategorieApi";
import {nadajUprawnieniaMembers} from "../../../apiCalls/szkolStacApi";
import {handleDataReceive, ModalForm} from "../../../components/ModalForm";
import ErrorBanner from "../../../components/ErrorBanner";
import {Form} from "react-bootstrap";

export function NadajUprawnienia({idSzkolenia, reloadDataFunction}) {
    const [errorText, setErrorText] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [kategorie, setKategorie] = useState([]);
    const [idKategorii, setIdKategorii] = useState("");

    useEffect(() => {
        setIsLoaded(false);
        getKategorieList(setErrorText)
            .then(resp => {
                setKategorie(resp);
                setIsLoaded(true);
            });
    }, []);

    const onSubmit = useCallback(() => {
        if (idKategorii === "") {
            setErrorText("Wybierz kategorię uprawnień do nadania");
            return Promise.resolve(null);
        }
        setErrorText(null);
        return nadajUprawnieniaMembers(setErrorText, idSzkolenia, idKategorii)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [idSzkolenia, idKategorii]);

    const onReset = useCallback(() => {
        setErrorText(null);
        setIdKategorii("");
    }, [idKategorii]);

    return (
        <ModalForm
            openButtonText={"Nadaj uprawnienia uczestnikom"}
            title={"Nadawanie uprawnień uczestnikom"}
            isInButtonGroup={true}
            childrenOnSubmit={onSubmit}
            childrenOnReset={onReset}
        >
            <ErrorBanner errorText={errorText}/>
            <Form.Group>
                <Form.Select disabled={!isLoaded} required={true} value={idKategorii}
                             onChange={e => setIdKategorii(e.target.value)}>
                    <option disabled={idKategorii !== ""}>
                        {isLoaded ? "Wybierz uprawnienia" : "Ładowanie listy uprawnień"}
                    </option>
                    {
                        kategorie.map(k =>
                            <option key={k.KategoriaID} value={k.KategoriaID}>
                                {`${k.trakcja.nazwa} – ${k.nazwa}`}
                            </option>)
                    }
                </Form.Select>
                <Form.Text>
                    <b>Uwaga!</b> Nie ma możliwości ręcznej zmiany dokonanego wyboru kategorii.
                    W przypadku pomyłki należy ręcznie pozmieniać uprawnienia poszczególnym użytkownikom.
                </Form.Text>
            </Form.Group>
        </ModalForm>
    )
}
