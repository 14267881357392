import {useCallback, useEffect, useMemo, useState} from "react";
import {ButtonIcon, handleDataReceive, ModalForm} from "../../../components/ModalForm";
import ErrorBanner from "../../../components/ErrorBanner";
import {Form, InputGroup} from "react-bootstrap";
import {GodzinaInput} from "../../../components/inputs/GodzinaInput";
import {WyborTrakcji} from "../koordynatorzyElements/WyborTrakcji";
import {getZajezdnie} from "../../../apiCalls/zajezdnieApi";
import {getZastawyPojazdow} from "../../../apiCalls/pojazdyApi";
import {isEndAfterBegin} from "../../../helpers/dataHelper";
import {addBrygada, editBrygada} from "../../../apiCalls/brygadyApi";
import {RequireField, RequireFieldLegenda} from "../../../components/inputs/RequireField";

/**
 * Dodaje lub edytuje brygadę
 * @param {string} data
 * @param {BrygadaData|null} brygadaData
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function DodajBrygade({data, brygadaData = null, reloadDataFunction}) {
    const [errorText, setErrorText] = useState("");
    const [isOpened, setIsOpened] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    /** @type {ZajezdniaData[]} */
    let zajezdnieList;
    let setZajezdnieList;
    [zajezdnieList, setZajezdnieList] = useState([]);
    /** @type {ZestawPojazdowData[]} */
    let pojazdyList;
    let setPojazdyList;
    [pojazdyList, setPojazdyList] = useState([]);

    const [linia, setLinia] = useState(brygadaData ? brygadaData.linia : "");
    const [brygada, setBrygada] = useState(brygadaData ? brygadaData.brygada : "");
    const [trakcja, setTrakcja] = useState(brygadaData ? brygadaData.trakcja.TrakcjaID : "");
    const [godzRozp, setGodzRozp] = useState(brygadaData ? brygadaData.godzRozp : "");
    const [godzZak, setGodzZak] = useState(brygadaData ? brygadaData.godzZak : "");
    const [zestawPojazdow, setZestawPojazdow] = useState(brygadaData ? brygadaData.zestawPojazdow.ZestawPojazdowID : "");
    const [zajezdnia, setZajezdnia] = useState(brygadaData ? brygadaData.zajezdnia.ZajezdniaID : "");
    const [uwagi, setUwagi] = useState(brygadaData ? brygadaData.uwagi : "");

    // Aktualizacja listy zestawów pojazdów i zajezdni względem trakcji
    useEffect(() => {
        if (trakcja && isOpened) {
            setIsLoaded(false);
            Promise.all([getZajezdnie(setErrorText, trakcja), getZastawyPojazdow(setErrorText, trakcja)])
                .then(resp => {
                    setZajezdnieList(resp[0]);
                    setPojazdyList(resp[1]);
                    setIsLoaded(true);
                })
        } else {
            setZajezdnia("");
            setZestawPojazdow("");
        }
    }, [trakcja, isOpened]);

    // Definiuje czy pole zajezdni i zestawu pojazdów jest aktywne
    const isDisabled = useMemo(() => !isLoaded || !trakcja, [isLoaded, trakcja]);

    const onOpen = useCallback(() => setIsOpened(true), [isOpened]);

    const onSubmit = useCallback(() => {
        setErrorText(null);
        if (!isEndAfterBegin(godzRozp, godzZak)) {
            setErrorText("Godzina rozpoczęcia pracy brygady jest późniejsza niż zakończenia");
            return Promise.resolve(false);
        }

        if (!brygadaData)
            //Dodawanie brygady
            return addBrygada(setErrorText, data, linia, brygada, trakcja, godzRozp, godzZak, zestawPojazdow, zajezdnia, uwagi)
                .then(resp => handleDataReceive(resp, reloadDataFunction));
        else
            //Edytowanie brygady
            return editBrygada(setErrorText, brygadaData.BrygadaID, data, linia, brygada, brygadaData.trakcja.TrakcjaID, godzRozp, godzZak, zestawPojazdow, zajezdnia, uwagi)
                .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [linia, brygada, trakcja, godzRozp, godzZak, zestawPojazdow, zajezdnia, uwagi])

    const onReset = useCallback(() => {
        setErrorText(null);
        setIsOpened(false);
        if (!brygadaData) {
            setLinia(brygadaData ? brygadaData.linia : "");
            setBrygada(brygadaData ? brygadaData.brygada : "");
            setTrakcja(brygadaData ? brygadaData.trakcja.TrakcjaID : "");
            setGodzRozp(brygadaData ? brygadaData.godzRozp : "");
            setGodzZak(brygadaData ? brygadaData.godzZak : "");
            setZestawPojazdow(brygadaData ? brygadaData.zestawPojazdow.ZestawPojazdowID : "");
            setZajezdnia(brygadaData ? brygadaData.zajezdnia.ZajezdniaID : "");
            setUwagi(brygadaData ? brygadaData.uwagi : "");
        }
    }, [brygadaData, linia, brygada, trakcja, godzRozp, godzZak, zestawPojazdow, zajezdnia, uwagi])

    return (
        <ModalForm
            title={brygadaData ? "Edycja brygady" : "Dodawanie brygady"}
            openButtonText={brygadaData ? "Edytuj brygadę" : "Dodaj brygadę"}
            openButtonIcon={brygadaData ? ButtonIcon.EDIT : ButtonIcon.NONE}
            openButtonVariant={brygadaData ? "outline-warning" : "outline-success"}
            isInButtonGroup={true}
            childrenOnOpen={onOpen}
            childrenOnSubmit={onSubmit}
            childrenOnReset={onReset}
        >
            <ErrorBanner errorText={errorText}/>

            {!brygadaData &&
                <Form.Group className={"mb-2"}>
                    <Form.Label>Trakcja<RequireField/></Form.Label>
                    <WyborTrakcji setErrorText={setErrorText} setTrakcja={setTrakcja}/>
                </Form.Group>
            }

            <Form.Group className={"mb-2"}>
                <Form.Label>Oznaczenie brygady</Form.Label>
                <InputGroup>
                    <Form.Control onChange={e => setLinia(e.target.value)} value={linia}
                                  type={"text"} name={"linia"} required={true} maxLength={50} placeholder={"Linia*"}/>
                    <InputGroup.Text>/</InputGroup.Text>
                    <Form.Control onChange={e => setBrygada(e.target.value)} value={brygada}
                                  type={"text"} name={"brygada"} maxLength={10} placeholder={"Brygada"}/>
                </InputGroup>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label>Czas pracy brygady<RequireField/></Form.Label>
                <InputGroup className={"mb-1"}>
                    <InputGroup.Text>Godzina rozpoczęcia</InputGroup.Text>
                    <GodzinaInput godzina={godzRozp} setGodzina={setGodzRozp} name={"godzRozp"} required={true}/>
                </InputGroup>
                <InputGroup>
                    <InputGroup.Text>Godzina zakończenia</InputGroup.Text>
                    <GodzinaInput godzina={godzZak} setGodzina={setGodzZak} name={"godzZak"} required={true}/>
                </InputGroup>
                <Form.Text>
                    Jeżeli brygada kończy pracę po północy, wprowadź godzinę jako wartość większą niż 24
                </Form.Text>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label>Pojazd</Form.Label>
                <Form.Select disabled={isDisabled} value={zestawPojazdow === null ? "" : zestawPojazdow}
                             onChange={e => setZestawPojazdow(e.target.value)}>
                    <option value={""}>
                        {trakcja ? (isLoaded ? "Brak przypisanego pojazdu" : "Ładowanie listy pojazdów") : "Najpierw wybierz trakcję"}
                    </option>
                    {
                        pojazdyList.map(p =>
                            <option key={p.ZestawPojazdowID} value={p.ZestawPojazdowID}>
                                {p.opis}
                            </option>)
                    }
                </Form.Select>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label>Zajezdnia</Form.Label>
                <Form.Select disabled={isDisabled} value={zajezdnia === null ? "" : zajezdnia}
                             onChange={e => setZajezdnia(e.target.value)}>
                    <option value={""}>
                        {trakcja ? (isLoaded ? "Brak przypisanej zajezdni" : "Ładowanie listy zajezdni") : "Najpierw wybierz trakcję"}
                    </option>
                    {
                        zajezdnieList.map(z =>
                            <option key={z.ZajezdniaID} value={z.ZajezdniaID}>
                                {`${z.oznaczenie} ${z.nazwa}`}
                            </option>
                        )
                    }
                </Form.Select>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label>Uwagi</Form.Label>
                <Form.Control onChange={e => setUwagi(e.target.value)} value={uwagi}
                              type={"text"} name={"uwagi"} maxLength={255}/>
            </Form.Group>
            
            <RequireFieldLegenda/>
        </ModalForm>
    );
}
