import {Main} from "../components/Main";
import {Link} from "react-router-dom";
import {InfoBanner} from "../components/InfoBanner";
import {useMemo} from "react";
import {Zdjecie} from "../components/Zdjecie";

import dominik from "../media/osoby/dominik.png";
import kowalski from "../media/osoby/kowalski.png";
import igrekowska from "../media/osoby/igrekowska.png";
import iksinska from "../media/osoby/iksinska.png";
import brzeczyszczykiewicz from "../media/osoby/brzeczyszczykiewicz.png";
import nowak from "../media/osoby/nowak.png";

/**
 * Strona statyczna zawierająca dodatkowe informacje dla konduktorów
 * @param {string} errorText
 * @returns {JSX.Element}
 * @constructor
 */
export function InformacjeDodatkowe({errorText}) {
    const inzynierInfo = useMemo(() =>
        <span>
            Aplikacja powstała jako praca inżynierska Dominika Pycha, studenta Wydziału Informatyki Polsko-Japońskiej Akademii Technik Komputerowych w Warszawie. Katedra Baz Danych.<br/>
            Opiekun pracy: dr inż. Paweł Lenkiewicz<br/>
            Anno Domini MMXXIII
        </span>, []);

    const fotoInfo = useMemo(() =>
        <span>
           Poniższe zdjęcia (z wyjątkiem mojego) zostały wygenerowane przy pomocy aplikacji <Link
            to={"https://thispersondoesnotexist.com"}>This Person Does Not Exists</Link>.
       </span>, []);

    return (
        <Main errorText={errorText} pageTitle={"Informacje dodatkowe"} waitForData={false}>
            <div className={"mb-2"}>
                <h2>Numery telefonów</h2>
                <ul className={"telefony"}>
                    <li><Link to={"tel://225345585"}>22 534-55-85</Link> – Centrala Ruchu TW</li>
                    <li><Link to={"tel://22191285"}>22 19 285</Link> – Centrala Ruchu MZA</li>
                    <li><Link to={"tel://225844580"}>22 584-45-80</Link> – Dziekanat PJATK</li>
                </ul>
            </div>
            <InfoBanner intoText={fotoInfo}/>
            <div className={"mb-2"}>
                <h2>Zespół koordynatorski</h2>
                <div className={"osoby"}>
                    <Zdjecie imgPath={kowalski} name={"Jan Kowalski"} podpis={"Koordynator tramwajowy"}/>
                    <Zdjecie imgPath={igrekowska} name={"Joanna Igrekowska"} podpis={"Koordynator autobusowy"}/>
                    <Zdjecie imgPath={brzeczyszczykiewicz} name={"Grzegorz Brzęczyszczykiewicz"}
                             podpis={"Koordynator kolejowy"}/>
                </div>
            </div>
            <div className={"mb-2"}>
                <h2>Zespół instruktorski</h2>
                <div className={"osoby"}>
                    <Zdjecie imgPath={dominik} name={"Dominik Pych"}
                             podpis={"instruktor tramwajowy, administrator ISSK"}/>
                    <Zdjecie imgPath={iksinska} name={"Natalia Iksińska"} podpis={"instruktor tramwajowy"}/>
                    <Zdjecie imgPath={nowak} name={"Katarzyna Nowak"} podpis={"instruktor autobusowy"}/>
                </div>
            </div>
            <InfoBanner intoText={inzynierInfo}/>
        </Main>
    )
}
