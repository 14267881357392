import {handleDataReceive, ModalForm} from "../../../components/ModalForm";
import {useCallback, useState} from "react";
import ErrorBanner from "../../../components/ErrorBanner";
import {Form} from "react-bootstrap";
import {szkolenieRaport} from "../../../apiCalls/szkolTabApi";
import {RequireField, RequireFieldLegenda} from "../../../components/inputs/RequireField";

/**
 * Raport końcowy ze szkolenia
 * @param {int} zmianaID
 * @param {int} kursantID
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function SzkolenieRaport({zmianaID, kursantID, reloadDataFunction}) {
    const [errorText, setErrorText] = useState("");
    const [wynik, setWynik] = useState("true");
    const [uwagi, setUwagi] = useState("");

    const onSubmit = useCallback(() => {
        return szkolenieRaport(setErrorText, zmianaID, kursantID, wynik, uwagi)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [zmianaID, kursantID, wynik, uwagi]);

    const onReset = useCallback(() => {
        setErrorText("");
        setWynik("true");
        setUwagi("");
    }, [wynik, uwagi]);

    return (
        <ModalForm
            openButtonText={"Wypełnij raport"}
            openButtonVariant={"info"}
            isInButtonGroup={true}
            title={"Raport końcowy ze szkolenia"}
            childrenOnSubmit={onSubmit}
            childrenOnReset={onReset}
        >
            <ErrorBanner errorText={errorText}/>

            <Form.Group className={"mb-2"}>
                <Form.Label>Wynik szkolenia</Form.Label>
                <Form.Select name={"wynik"}
                             value={wynik} onChange={e => setWynik(e.target.value)}
                >
                    <option value={"true"}>POZYTYWNY</option>
                    <option value={"false"}>NEGATYWNY</option>
                </Form.Select>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label>
                    Uwagi
                    {wynik === "false" && <RequireField/>}
                </Form.Label>
                <Form.Control type={"text"} placeholder={wynik === "true" ? "Brak uwag" : ""}
                              value={uwagi} onChange={e => setUwagi(e.target.value)}/>
                <Form.Text>
                    {wynik === "true" ?
                        "Jeżeli nie masz żadnych uwag, pozostaw to pole puste" :
                        "Wprowadź uzasadnienie negatywnego wyniku szkolenia"
                    }
                </Form.Text>
            </Form.Group>

            {
                wynik === "false" && <RequireFieldLegenda/>
            }
        </ModalForm>
    )
}
