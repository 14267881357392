import {BarLoader} from "react-spinners";

/**
 * Spinner wyświetlany na stronie podczas ładowania danych
 * @param isLoading
 * @returns {JSX.Element}
 * @constructor
 */
export function LoadingSpinner({isLoading}) {
    const width = "200px";
    const style = {
        display: "block",
        width: width,
        margin: "auto"
    }

    return (
        <div className={"my-5"} hidden={!isLoading}>
            <div style={style}>
                <BarLoader color={"#444"} width={width} loading={isLoading}/>
            </div>
            <div className={"center"}>Ładowanie danych...</div>
        </div>
    )
}
