import {useSearchParams} from "react-router-dom";

/**
 * Zwraca wartość parametru GET
 * @param {string} paramName Nazwa parametru
 * @returns {string}
 */
function GetSearchParam(paramName) {
    const [searchParams] = useSearchParams();
    return searchParams.get(paramName);
}

/**
 * Ustawia wartość parametru GET
 * @param {string} paramName Nazwa parametru
 * @param {string} paramValue Wartość parametru
 */
export function SetSearchParam(paramName, paramValue) {
    const [searchParams] = useSearchParams();
    searchParams.set(paramName, paramValue);
}

/**
 * Dostosowuje ciąg znaków do parametru URI
 * @param {string} paramValue
 * @returns {string}
 */
export function stringifyParamValue(paramValue) {
    return encodeURIComponent(paramValue);
}

/**
 * Zwraca wartość parametru GET error (kod błędu)
 * @returns {string}
 */
export function getErrorTextParam() {
    return GetSearchParam("error");
}

/**
 * Dostosowuje wartość parametru GET error (kod błędu) do standardu URI
 * @param {string} errorText
 * @returns {string}
 */
export function stringifyErrorTextParam(errorText) {
    return "error=" + stringifyParamValue(errorText);
}

/**
 * Zwraca wartość parametru GET confirm (komunikat potwierdzenia)
 * @return {string}
 */
export function getConfirmTextParam(){
    return GetSearchParam("confirm");
}

/**
 * Dostosowuje wartość parametru GET confirm (komunikat potwierdzenia) do standardu URI
 * @param {string} confirmText
 * @return {string}
 */
export function stringifyConfirmTextParam(confirmText){
    return `confirm=${stringifyParamValue(confirmText)}`
}
