import {useCallback, useEffect, useState} from "react";
import {getUsers} from "../../../apiCalls/userApi";
import {addSzkolStacMember} from "../../../apiCalls/szkolStacApi";
import {handleDataReceive, ModalForm} from "../../../components/ModalForm";
import ErrorBanner from "../../../components/ErrorBanner";
import {Form} from "react-bootstrap";

export function DodajUczestnika({idSzkolenia, reloadDataFunction}) {
    const [errorText, setErrorText] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");

    useEffect(() => {
        setIsLoaded(false);
        getUsers(setErrorText)
            .then(resp => {
                setUsers(resp);
                setIsLoaded(true);
            });
    }, []);

    const onSubmit = useCallback(() => {
        if (selectedUser === "") {
            setErrorText("Wybierz uczestnika szkolenia");
            return Promise.resolve(null);
        }
        setErrorText(null);
        return addSzkolStacMember(setErrorText, idSzkolenia, selectedUser)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [idSzkolenia, selectedUser]);

    const onReset = useCallback(() => {
        setErrorText(null);
        setSelectedUser("");
    }, [idSzkolenia, selectedUser])

    return (
        <ModalForm
            openButtonText={"Dodaj uczestnika"}
            openButtonVariant={"outline-primary"}
            title={"Dodawanie uczestnika szkolenia"}
            isInButtonGroup={true}
            childrenOnSubmit={onSubmit}
            childrenOnReset={onReset}
        >
            <ErrorBanner errorText={errorText}/>
            <Form.Select disabled={!isLoaded} required={true} value={selectedUser}
                         onChange={e => setSelectedUser(e.target.value)}
            >
                <option
                    disabled={selectedUser !== ""}>
                    {isLoaded ? "Wybierz uczestnika" : "Ładowanie listy użytkowników"}
                </option>
                {users.map(u =>
                    <option key={`szkolenie${idSzkolenia}user${u.UserID}`} value={u.UserID}>
                        {`${u.imie} ${u.nazwisko}`}
                    </option>
                )}
            </Form.Select>
        </ModalForm>
    );
}
