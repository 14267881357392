import {ButtonIcon, handleDataReceive, ModalForm} from "../../../components/ModalForm";
import {useCallback, useState} from "react";
import ErrorBanner from "../../../components/ErrorBanner";
import {Form, InputGroup} from "react-bootstrap";
import {GodzinaInput} from "../../../components/inputs/GodzinaInput";
import {getKategorieList} from "../../../apiCalls/kategorieApi";
import {addZmiana, editZmiana} from "../../../apiCalls/zmianyApi";
import {RequireField, RequireFieldLegenda} from "../../../components/inputs/RequireField";

/**
 * Dodaje zmianę
 * @param {int} brygadaID
 * @param {string} brygadaName
 * @param {int} trakcjaID
 * @param {ZmianaData} zmianaData
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function DodajZmiane({brygadaID, brygadaName, trakcjaID, zmianaData = null, reloadDataFunction}) {
    const [errorText, setErrorText] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    /** @type KategorieSluzbData[] */
    let kategorieList;
    let setKategorieList;
    [kategorieList, setKategorieList] = useState([]);

    const [oznaczenie, setOznaczenie] = useState(zmianaData ? zmianaData.oznaczenie : "");
    const [kategoria, setKategoria] = useState(zmianaData ? zmianaData.kategoria.KategoriaID : "");
    const [godzRozp, setGodzRozp] = useState(zmianaData ? zmianaData.godzRozp : "");
    const [godzZak, setGodzZak] = useState(zmianaData ? zmianaData.godzZak : "");
    const [miejsceRozp, setMiejsceRozp] = useState(zmianaData ? zmianaData.miejsceRozp : "");
    const [miejsceZak, setMiejsceZak] = useState(zmianaData ? zmianaData.miejsceZak : "");
    const [liczbaKonduktorow, setLiczbaKonduktorow] = useState(zmianaData ? zmianaData.liczbaKonduktorow : "");

    // Pobieramy kategorie służb konduktorskich
    const onOpen = useCallback(() => {
        setIsLoaded(false);
        getKategorieList(setErrorText, trakcjaID)
            .then(resp => {
                setKategorieList(resp);
                setIsLoaded(true);
            })
    }, [trakcjaID]);

    const onSubmit = useCallback(() => {
        setErrorText("");

        if (!zmianaData)
            //Dodawanie zmiany
            return addZmiana(setErrorText, brygadaID, oznaczenie, kategoria, godzRozp, godzZak, miejsceRozp, miejsceZak, liczbaKonduktorow)
                .then(resp => handleDataReceive(resp, reloadDataFunction));
        else
            //Edycja zmiany
            return editZmiana(setErrorText, zmianaData.zmianaID, oznaczenie, kategoria, godzRozp, godzZak, miejsceRozp, miejsceZak, liczbaKonduktorow)
                .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [zmianaData, brygadaID, oznaczenie, kategoria, godzRozp, godzZak, miejsceRozp, miejsceZak, liczbaKonduktorow])

    const onReset = useCallback(() => {
        setErrorText("");
        setOznaczenie(zmianaData ? zmianaData.oznaczenie : "");
        setKategoria(zmianaData ? zmianaData.kategoria.KategoriaID : "");
        setGodzRozp(zmianaData ? zmianaData.godzRozp : "");
        setGodzZak(zmianaData ? zmianaData.godzZak : "");
        setMiejsceRozp(zmianaData ? zmianaData.miejsceRozp : "");
        setMiejsceZak(zmianaData ? zmianaData.miejsceZak : "");
        setLiczbaKonduktorow(zmianaData ? zmianaData.liczbaKonduktorow : "");
    }, [zmianaData, oznaczenie, kategoria, godzRozp, godzZak, miejsceRozp, miejsceZak, liczbaKonduktorow])

    return (
        <ModalForm
            title={zmianaData ? "Edycja zmiany" : "Dodawanie zmiany"}
            openButtonText={zmianaData ? "Edytuj zmianę" : "Dodaj zmianę"}
            openButtonIcon={zmianaData ? ButtonIcon.EDIT : ButtonIcon.NONE}
            openButtonVariant={zmianaData ? "outline-warning" : "outline-success"}
            isInButtonGroup={true}
            childrenOnOpen={onOpen}
            childrenOnSubmit={onSubmit}
            childrenOnReset={onReset}
        >
            <ErrorBanner errorText={errorText}/>

            <Form.Group className={"mb-2"}>
                <Form.Label>Oznaczenie zmiany</Form.Label>
                <InputGroup>
                    <InputGroup.Text>{brygadaName}/</InputGroup.Text>
                    <Form.Control type={"text"} name={"oznaczenie"} maxLength={1} htmlSize={1}
                                  value={oznaczenie} onChange={e => setOznaczenie(e.target.value)}/>
                </InputGroup>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label>Kategoria służby konduktorskiej<RequireField/></Form.Label>
                <Form.Select name={"kategoria"} required={true} value={kategoria} disabled={!isLoaded}
                             onChange={e => setKategoria(e.target.value)}>
                    <option disabled={!!kategoria}>
                        {isLoaded ? "Wybierz kategorię służby" : "Ładowanie kategorii służb..."}
                    </option>
                    {
                        kategorieList.map(k =>
                            <option key={k.KategoriaID} value={k.KategoriaID}>
                                {k.nazwa} ({k.nazwaGrafik})
                            </option>)
                    }
                </Form.Select>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label>Czas pracy zmiany</Form.Label>
                <InputGroup>
                    <InputGroup.Text>Godzina rozpoczęcia</InputGroup.Text>
                    <GodzinaInput godzina={godzRozp} setGodzina={setGodzRozp} name={"godzRozp"} required={false}/>
                </InputGroup>
                <InputGroup>
                    <InputGroup.Text>Godzina zakończenia</InputGroup.Text>
                    <GodzinaInput godzina={godzZak} setGodzina={setGodzZak} name={"godzZak"} required={false}/>
                </InputGroup>
                <Form.Text>
                    Jeżeli zmiana rozpoczyna lub kończy pracę równolegle z całą brygadą to odpowiednie pola można
                    zostawić puste.
                </Form.Text>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label>Miejsce rozpoczęcia pracy</Form.Label>
                <Form.Control type={"text"} name={"miejsceRozp"} maxLength={31}
                              value={miejsceRozp} onChange={e => setMiejsceRozp(e.target.value)}/>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label>Miejsce zakończenia pracy</Form.Label>
                <Form.Control type={"text"} name={"miejsceZak"} maxLength={31}
                              value={miejsceZak} onChange={e => setMiejsceZak(e.target.value)}/>
            </Form.Group>

            <Form.Group className={"mb-2"}>
                <Form.Label>Liczba konduktorów<RequireField/></Form.Label>
                <Form.Control type={"number"} name={"liczbaKonduktorow"} required={true} min={1} step={1}
                              value={liczbaKonduktorow} onChange={e => setLiczbaKonduktorow(e.target.value)}/>
            </Form.Group>

            <RequireFieldLegenda/>
        </ModalForm>
    )
}
