import {Alert} from "react-bootstrap";

/**
 * Banner z komunikatem potwierdzającym wykonanie jakiejś czynności
 * @param {string} confirmText
 * @return {JSX.Element}
 * @constructor
 */
export function ConfirmBanner({confirmText}) {
    return !confirmText?<></>:
        <Alert variant={"success"}>
            {confirmText}
        </Alert>
}
