import {_apiCall, MethodType} from "./_apiCall";

/**
 * Dane zwracane przy logowaniu
 * @typedef LoginApi
 *
 * @property {string} token
 * @property {UserData} userData
 * @property {Cooldown} cooldown
 */

/**
 * Cooldown do zapisu na służby
 * @typedef {Object.<string, {nazwa: string, cooldown: int}>} Cooldown
 */

/**
 * Logowanie użytkownika do systemu
 * @param loginData
 * @param setError
 * @returns {Promise<LoginApi|null>}
 */
export function loginApi(loginData, setError) {
    return _apiCall('user/login', MethodType.POST, setError, loginData)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Wysyła do systemu wniosek o reset hasła dla użytkownika
 * @param {string} email
 * @param {function(string)} setError
 * @return {Promise<string>}
 */
export function reqestResetPassword(email, setError) {
    return _apiCall('user/reset', MethodType.POST, setError, {email})
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Resetuje hasło dla wcześniej wnioskującego użytkownika
 * @param {function(string)} setError
 * @param {string|int} UserID
 * @param {string} resetKey
 * @param {string} newPassword
 * @param {string} repeatPassword
 */
export function resetPassword(setError, UserID, resetKey, newPassword, repeatPassword) {
    const content = {UserID, resetKey, newPassword, repeatPassword};
    return _apiCall('user/reset', MethodType.PUT, setError, content)
        .then(resp => resp.data)
        .catch(() => null);
}
