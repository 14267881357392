import logo_kmkm from "../media/kmkm_logo.png";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useCallback, useMemo} from "react";
import {saveToken} from "../helpers/authHelper";
import {useNavigate} from "react-router-dom";

const rozkladyUrl = "https://drive.google.com/drive/folders/0B5wPhEU3v_0INlEteEJIVWhxLUE?resourcekey=0-FMg1QbEGBomI5c0WG2KraA&usp=sharing";

/**
 *
 * @param {UserData} userData
 * @param {function(string)} setToken
 * @returns {JSX.Element}
 * @constructor
 */
function Navigation({userData, setToken}) {
    const navigate = useNavigate();

    const nrKlubowy = useMemo(() => {
        return userData.nrKlubowy ? userData.nrKlubowy : userData.wymiana.organizacja + " " + userData.wymiana.miasto;
    }, [userData]);

    const userUprawnienia = useMemo(() => userData.userUprawnienia, [userData]);

    const administracja =
        userUprawnienia.raporty_read ||
        userUprawnienia.user_read ||
        userUprawnienia.umowy_read ||
        userUprawnienia.trakcje_read ||
        userUprawnienia.kategorie_read ||
        userUprawnienia.zajezdnie_read ||
        userUprawnienia.pojazdy_read ||
        userUprawnienia.config_modify;

    // Definiujemy czy wyświetlać zakładkę "Moje konto", czy wyświetlać zawartość bezpośrednio na pasku menu
    const mojeKonto = administracja || userUprawnienia.szkolenia_read;

    const logoutFunction = useCallback(() => {
        saveToken(null, setToken);
        navigate("/login");
    }, [])

    return (
        <Navbar expand="lg" bg="light" className="p-2" collapseOnSelect>
            <Container>
                <Navbar.Brand href="/">
                    <img src={logo_kmkm} alt="logo KMKM"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-menu"/>
                <Navbar.Collapse id="responsive-menu">
                    <Nav className="me-auto">
                        {userUprawnienia.kalendarz_read && <Nav.Link href={"/kalendarz"}>Kalendarz służb</Nav.Link>}
                        {userUprawnienia.szkolenia_read && <Nav.Link href={"/szkolenia"}>Lista szkoleń</Nav.Link>}
                        <NavDropdown title="Informacje">
                            <NavDropdown.Item href={rozkladyUrl}>Rozkłady jazdy</NavDropdown.Item>
                            <NavDropdown.Item href={"/regulamin"}>Regulamin służby</NavDropdown.Item>
                            <NavDropdown.Item href={"/informacje"}>Informacje dodatkowe</NavDropdown.Item>
                        </NavDropdown>
                        {administracja && <NavDropdown title="Administracja">
                            {userUprawnienia.raporty_read && <NavDropdown.Item disabled>Raporty</NavDropdown.Item>}
                            {userUprawnienia.user_read && <NavDropdown.Item disabled>Użytkownicy</NavDropdown.Item>}
                            {userUprawnienia.umowy_read &&
                                <NavDropdown.Item disabled>Umowy wolontariackie</NavDropdown.Item>}
                            {userUprawnienia.trakcje_read && <NavDropdown.Item disabled>Trakcje</NavDropdown.Item>}
                            {userUprawnienia.kategorie_read &&
                                <NavDropdown.Item disabled>Kategorie służb</NavDropdown.Item>}
                            {userUprawnienia.zajezdnie_read && <NavDropdown.Item disabled>Zajezdnie</NavDropdown.Item>}
                            {userUprawnienia.pojazdy_read && <NavDropdown.Item disabled>Tabor</NavDropdown.Item>}
                            {userUprawnienia.config_modify &&
                                <NavDropdown.Item disabled>Ustawienia systemowe</NavDropdown.Item>}
                        </NavDropdown>}
                        {mojeKonto && <NavDropdown title="Moje konto">
                            <NavDropdown.Item href={"/user"}>Moje konto</NavDropdown.Item>
                            <NavDropdown.Item href={"/grafik"}>Grafik</NavDropdown.Item>
                            <NavDropdown.Item onClick={logoutFunction}>Wyloguj się</NavDropdown.Item>
                        </NavDropdown>}

                        {!mojeKonto && <>
                            <Nav.Link href={"/user"}>Moje konto</Nav.Link>
                            <Nav.Link href={"/grafik"}>Grafik</Nav.Link>
                            <Nav.Link onClick={logoutFunction}>Wyloguj się</Nav.Link>
                        </>}
                    </Nav>
                    <Navbar.Text>
                        <span>{userData.imie}&nbsp;{userData.nazwisko}</span>
                        <span> </span>
                        <span>({nrKlubowy})</span>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;
