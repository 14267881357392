import {Accordion, ButtonGroup, Table} from "react-bootstrap";
import {Trakcja} from "../../../components/Trakcja";
import {czasTrwania, optionalValue} from "../../../helpers/dataHelper";
import {Zmiana} from "./Zmiana";
import {DodajBrygade} from "./DodajBrygade";
import {DeleteButton} from "../../../components/buttons/DeleteButton";
import {useCallback} from "react";
import {deleteBrygada} from "../../../apiCalls/brygadyApi";
import {handleDataReceive} from "../../../components/ModalForm";
import {DodajZmiane} from "./DodajZmiane";

/**
 *
 * @param {string} data
 * @param {BrygadaData} brygadaData
 * @param {string} dataPublikacji
 * @param {boolean} czyEdycja
 * @param {boolean} dopuszczalnaRezygnacja
 * @param {boolean} sluzbyZapis
 * @param {int} currentUserID
 * @param {function()} reloadDataFunction
 * @param {function(string)} setErrorText
 * @return {JSX.Element}
 * @constructor
 */
export function Brygada({
                            data,
                            brygadaData,
                            dataPublikacji,
                            czyEdycja,
                            dopuszczalnaRezygnacja,
                            sluzbyZapis,
                            currentUserID,
                            reloadDataFunction,
                            setErrorText
                        }) {
    const onDelete = useCallback(() => {
        deleteBrygada(setErrorText, brygadaData.BrygadaID)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [brygadaData])

    return (
        <Accordion alwaysOpen defaultActiveKey={brygadaData.BrygadaID.toString()}>
            <Accordion.Item eventKey={brygadaData.BrygadaID.toString()}>
                <Accordion.Header>
                    <Trakcja imgPath={brygadaData.trakcja.imgPath} nazwa={brygadaData.trakcja.nazwa} showNazwa={false}/>
                    <div className={"ms-2"}>
                        {`${brygadaData.nazwa} (${czasTrwania(brygadaData.godzRozp, brygadaData.godzZak)})`}
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    {brygadaData.zajezdnia.nazwa &&
                        <div>
                            <b>Zajezdnia: </b>{brygadaData.zajezdnia.oznaczenie} {brygadaData.zajezdnia.nazwa}
                        </div>
                    }
                    {brygadaData.zestawPojazdow.ZestawPojazdowID &&
                        <div>
                            <b>Planowana obsługa: </b>{brygadaData.zestawPojazdow.opis}
                        </div>
                    }
                    <div>
                        <b>Uwagi: </b>{optionalValue(brygadaData.uwagi, "Brak uwag")}
                    </div>
                    {czyEdycja &&
                        <ButtonGroup>
                            <DodajZmiane brygadaID={brygadaData.BrygadaID} brygadaName={brygadaData.nazwa}
                                         trakcjaID={brygadaData.trakcja.TrakcjaID}
                                         reloadDataFunction={reloadDataFunction}/>
                            <DodajBrygade data={data} brygadaData={brygadaData}
                                          reloadDataFunction={reloadDataFunction}/>
                            <DeleteButton
                                onClick={onDelete}
                                confirmTitle={"Usuwanie brygady"}
                                confirmText={`Czy na pewno chcesz usunąć brygadę ${brygadaData.nazwa}?`}
                                buttonText={"Usuń brygadę"}
                                buttonWithIcon={true}
                            />
                        </ButtonGroup>
                    }
                    <Table striped responsive={"xl"}>
                        <thead>
                        <tr className={"center"}>
                            <th>Zmiana</th>
                            <th>Godziny pracy</th>
                            <th>Uwagi</th>
                            <th>Konduktorzy</th>
                            {czyEdycja &&
                                <th></th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {brygadaData.zmiany.map(z =>
                            <Zmiana
                                key={z.zmianaID}
                                brygadaID={brygadaData.BrygadaID}
                                brygadaName={brygadaData.nazwa}
                                trakcjaID={brygadaData.trakcja.TrakcjaID}
                                zmianaData={z}
                                dataPublikacji={dataPublikacji}
                                czyEdycja={czyEdycja}
                                dopuszczalnaRezygnacja={dopuszczalnaRezygnacja}
                                sluzbyZapis={sluzbyZapis}
                                currentUserID={currentUserID}
                                reloadDataFunction={reloadDataFunction}
                                setErrorText={setErrorText}
                            />
                        )}
                        </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}
