import {_apiCall, MethodType} from "./_apiCall";

const addBaza = "trakcje";

/**
 *
 * @typedef TrakcjaData
 *
 * @property {int} TrakcjaID
 * @property {string} nazwa
 * @property {string} imgPath
 */

/**
 * Pobiera trakcje
 * @param {function(string)} setErrorText
 * @returns {Promise<TrakcjaData[]>}
 */
export function getTrakcje(setErrorText) {
    return _apiCall(addBaza, MethodType.GET, setErrorText)
        .then((resp) => resp.data)
        .catch(() => [])
}
