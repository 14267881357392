/**
 * Oznaczenie wymaganego pola
 * @returns {JSX.Element}
 * @constructor
 */
export function RequireField() {
    return <span style={{color: "red"}}>*</span>
}

/**
 * Legenda opisująca pole wymagane
 * @returns {JSX.Element}
 * @constructor
 */
export function RequireFieldLegenda() {
    return (
        <div style={{fontStyle: "italic", fontSize: "small"}}>
            Pola oznaczone gwiazdką są wymagane
        </div>);
}
