import {useNavigate, useParams} from "react-router-dom";

import Koordynatorzy from "./dataElements/Koordynatorzy";
import {useCallback, useMemo, useState} from "react";
import {getKalendarzData} from "../apiCalls/kalendarzApi";
import {dataToLocalString, parseDate, timeToString} from "../helpers/dataHelper";
import {Szkolenia} from "./dataElements/Szkolenia";
import {Brygady} from "./dataElements/Brygady";
import {GDP} from "./dataElements/GDP";
import {stringifyErrorTextParam} from "../helpers/searchParamsHelper";
import {Main} from "../components/Main";
import {DayManagePanel} from "./dataElements/DayManagePanel";
import {InfoBanner} from "../components/InfoBanner";

/**
 * Wykaz służb w danym dniu
 * @param {string} errorText
 * @param {function(string)} setErrorText
 * @param {int} userID ID bieżącego użytkownika
 * @param {boolean} sluzbyGDP Definiuje czy bieżący użytkownik ma uprawnienia do zapisu na GDP
 * @param {boolean} sluzbyZapis Definiuje czy bieżący użytkownik ma uprawnienia do samodzielnego zapisu na służby
 * @returns {JSX.Element}
 * @constructor
 */
function KalendarzData({errorText, setErrorText, userID, sluzbyGDP, sluzbyZapis}) {
    useNavigate();
    const {data} = useParams();
    /**
     * @type KalendarzDay
     */
    let kalendarzData;
    let setKalendarzData;
    [kalendarzData, setKalendarzData] = useState({});

    const loadData = useCallback(() => {
        return getKalendarzData(data)
            .then(val => {
                if (val === null)
                    return window.location.href = "/kalendarz?" + stringifyErrorTextParam("Podanej daty (" + data + ") nie ma w kalendarzu");
                setKalendarzData(val);
            })
    }, [data]);

    const publishBannerText = useMemo(() => {
        if (kalendarzData.dataPublikacji === undefined)
            return "";
        const time = parseDate(kalendarzData.dataPublikacji);
        if (!time)
            return "Podany dzień jest nieopublikowany i nie ma wyznaczonego terminu publikacji";
        else if (time.getTime() > Date.now())
            return `Dzień zostanie opublikowany ${dataToLocalString(kalendarzData.dataPublikacji)} o godz. ${timeToString(time)}`
        else return "";
    }, [kalendarzData]);

    const pageTitle = useMemo(() => "Wykaz służb w dniu " + dataToLocalString(data), [data]);

    return (
        <Main
            errorText={errorText}
            pageTitle={pageTitle}
            loadDataFunction={loadData}
            loadDataDeps={[data]}
            waitForData={true}
        >
            <InfoBanner intoText={publishBannerText}/>

            {kalendarzData.czyEdycja &&
                <DayManagePanel
                    data={data}
                    dayDetails={kalendarzData}
                    setErrorText={setErrorText}
                    reloadDataFunction={loadData}
                />
            }

            <Koordynatorzy
                koordynatorzyData={kalendarzData.koordynatorzy}
                koordynatorNotes={kalendarzData.koordynatorNotes}
                data={data}
                setErrorText={setErrorText}
                reloadDataFunction={loadData}
                userID={userID}
                czyEdycja={kalendarzData.czyEdycja}
            />

            <Szkolenia
                szkolenia={kalendarzData.szkolenia}
                reloadDataFunction={loadData}
                czyEdycja={kalendarzData.czyEdycja}
                setErrorText={setErrorText}
            />

            <Brygady
                data={data}
                brygadyData={kalendarzData.brygady}
                dataPublikacji={kalendarzData.dataPublikacji}
                czyEdycja={kalendarzData.czyEdycja}
                sluzbyZapis={sluzbyZapis}
                dopuszczalnaRezygnacja={kalendarzData.dopuszczalnaRezygnacja}
                currentUserID={userID}
                reloadDataFunction={loadData}
                setErrorText={setErrorText}
            />

            {kalendarzData.czyDopuszczoneGDP &&
                <GDP
                    data={data}
                    gdpData={kalendarzData.GDP}
                    czySamodzielnyZapisGDP={kalendarzData.czySamodzielnyZapisGDP}
                    dopuszczalnaRezygnacja={kalendarzData.dopuszczalnaRezygnacja}
                    currentUser={userID}
                    reloadDataFunction={loadData}
                    czyEdycja={kalendarzData.czyEdycja}
                    canGDP={sluzbyGDP}
                    setErrorText={setErrorText}
                />
            }
        </Main>
    )
}

export default KalendarzData;
