import {DeleteButton} from "../../../components/buttons/DeleteButton";
import {useCallback} from "react";
import {delSzkolStacMember} from "../../../apiCalls/szkolStacApi";
import {handleDataReceive} from "../../../components/ModalForm";
import {Stack} from "react-bootstrap";
import {PhoneButton} from "../../../components/buttons/PhoneButton";

/**
 *
 * @param {UserTelefon} user
 * @param {int} szkolenieID
 * @param {function(string)} setErrorText
 * @param {function()} reloadDataFunction
 * @param {boolean} czyEdycja
 * @return {JSX.Element}
 * @constructor
 */
export function SzkolenieMember({user, szkolenieID, setErrorText, reloadDataFunction, czyEdycja}) {
    const onDelete = useCallback(() => {
        delSzkolStacMember(setErrorText, szkolenieID, user.UserID)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [user, szkolenieID])

    return (
        <Stack direction={"horizontal"} gap={2}>
            <div className={czyEdycja ? "mx-5" : "mx-auto"}>{user.imie}&nbsp;{user.nazwisko}</div>
            {czyEdycja &&
                <>
                    <div className={czyEdycja ? "ms-auto" : "ms-auto me-5"}>
                        <PhoneButton phoneNumber={user.telefon}/>
                    </div>
                    <div className={"me-5"}>
                        <DeleteButton
                            onClick={onDelete}
                            confirmTitle={"Usuwanie uczestnika szkolenia"}
                            confirmText={`Czy na pewno chcesz usunąć ze szkolenia uczestnika ${user.imie} ${user.nazwisko}?`}
                        />
                    </div>
                </>
            }
        </Stack>
    )
}
