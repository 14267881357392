import {Brygada} from "./brygadyElements/Brygada";

/**
 *
 * @param {string} data
 * @param {[BrygadaData]} brygadyData
 * @param {string} dataPublikacji
 * @param {boolean} czyEdycja
 * @param {boolean} dopuszczalnaRezygnacja
 * @param {boolean} sluzbyZapis
 * @param {int} currentUserID
 * @param {function()} reloadDataFunction
 * @param {function(string)} setErrorText
 * @return {JSX.Element}
 * @constructor
 */
export function Brygady({
                            data,
                            brygadyData,
                            dataPublikacji,
                            czyEdycja,
                            dopuszczalnaRezygnacja,
                            sluzbyZapis,
                            currentUserID,
                            reloadDataFunction,
                            setErrorText
                        }) {

    return (
        <>
            {brygadyData && brygadyData.length > 0 &&
                <>
                    <h2>Służby konduktorskie</h2>
                    {
                        brygadyData.map(b =>
                            <Brygada
                                key={b.BrygadaID}
                                data={data}
                                brygadaData={b}
                                dataPublikacji={dataPublikacji}
                                czyEdycja={czyEdycja}
                                sluzbyZapis={sluzbyZapis}
                                dopuszczalnaRezygnacja={dopuszczalnaRezygnacja}
                                currentUserID={currentUserID}
                                reloadDataFunction={reloadDataFunction}
                                setErrorText={setErrorText}
                            />
                        )
                    }
                </>
            }
        </>
    )
}
