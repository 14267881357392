import {Button, Table} from "react-bootstrap";
import {czasTrwania, dataToLocalString} from "../../helpers/dataHelper";
import {stringifyParamValue} from "../../helpers/searchParamsHelper";

/**
 * Lista szkoleń stacjonarnych
 * @param {SzkolStacBasic[]} szkolStacList
 * @returns {JSX.Element}
 * @constructor
 */
export function SzkolStac({szkolStacList}) {
    return (
        <Table>
            <thead>
            <tr>
                <th>Data szkolenia</th>
                <th>Nazwa</th>
                <th>Czas trwania</th>
                <th>Miejsce</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                szkolStacList.map(s =>
                    <tr key={s.SzkolenieID}>
                        <td>{dataToLocalString(s.data)}</td>
                        <td>{s.nazwa}</td>
                        <td>{czasTrwania(s.godzRozp, s.godzZak)}</td>
                        <td>{s.miejsce}</td>
                        <td>
                            <Button variant={"outline-primary"} href={`/kalendarz/${stringifyParamValue(s.data)}`}>
                                Podgląd dnia
                            </Button>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    )
}
