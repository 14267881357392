import {Button, Form} from "react-bootstrap";
import ErrorBanner from "../components/ErrorBanner";
import {useCallback, useState} from "react";
import {reqestResetPassword} from "../apiCalls/authApi";
import {handleDataReceive} from "../components/ModalForm";
import {stringifyConfirmTextParam} from "../helpers/searchParamsHelper";

//todo Połączenie z bazą danych

/**
 *
 * @param {function(string)} navigate
 * @return {JSX.Element}
 * @constructor
 */
function ForgotPassword({navigate}) {
    const [errorText, setErrorText] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");

    const onSuccess = useCallback(() => {
        navigate(`/login?${stringifyConfirmTextParam("Jeżeli użytkownik był zarejestrowany w systemie, na podany adres zostanie wysłany link do resetowania hasła.")}`);
    }, [])

    const onSubmit = useCallback((event) => {
        event.preventDefault();
        setErrorText(null);
        setIsLoading(true);
        reqestResetPassword(email, setErrorText)
            .then(resp => handleDataReceive(resp, onSuccess))
            .finally(() => setIsLoading(false))
    }, [email]);

    return (
        <>
            <ErrorBanner errorText={errorText}/>
            <Form onSubmit={e => onSubmit(e)}>
                <Form.Group className="mb-2">
                    <Form.Label>Adres e-mail na który masz założone konto</Form.Label>
                    <Form.Control type="email" placeholder="Adres e-mail" value={email} required
                                  onChange={e => setEmail(e.target.value)}/>
                </Form.Group>
                <div className="d-grid">
                    <Button variant="outline-primary" type="submit" disabled={isLoading}>
                        {isLoading ? "Wysyłanie danych..." : "Wyślij e-mail z linkiem do resetowania hasła"}
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default ForgotPassword;
