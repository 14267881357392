import {ButtonGroup} from "react-bootstrap";
import {DodajSzkolenie} from "./szkolStacElements/DodajSzkolenie";
import {EdytujDzien} from "./dayManagePanelElements/EdytujDzien";
import {UsunDzien} from "./dayManagePanelElements/UsunDzien";
import {DodajBrygade} from "./brygadyElements/DodajBrygade";
import {KopiujBrygade} from "./brygadyElements/KopiujBrygade";

/**
 *
 * @param {string} data
 * @param {KalendarzDay} dayDetails
 * @param {function(string)} setErrorText
 * @param {function()} reloadDataFunction
 * @return {JSX.Element}
 * @constructor
 */
export function DayManagePanel({data, dayDetails, setErrorText, reloadDataFunction}) {
    return (
        <div className={"center mb-3"}>
            <ButtonGroup>
                <DodajSzkolenie data={data} reloadDataFunction={reloadDataFunction}/>
                <DodajBrygade data={data} reloadDataFunction={reloadDataFunction}/>
                <KopiujBrygade data={data} reloadDataFunction={reloadDataFunction}/>
                <EdytujDzien
                    data={data}
                    defaultPublishData={dayDetails.dataPublikacji}
                    defaultUwagi={dayDetails.koordynatorNotes}
                    defaultCzyGDP={dayDetails.czyDopuszczoneGDP}
                    reloadDataFunction={reloadDataFunction}
                />
                <UsunDzien data={data} setErrorText={setErrorText} reloadDataFunction={reloadDataFunction}/>
            </ButtonGroup>
        </div>
    )
}
