import {Stack} from "react-bootstrap";
import {DeleteButton} from "../../../components/buttons/DeleteButton";
import {useCallback} from "react";
import {delKoordynator} from "../../../apiCalls/kalendarzApi";
import {Trakcja} from "../../../components/Trakcja";
import {handleDataReceive} from "../../../components/ModalForm";
import {PhoneButton} from "../../../components/buttons/PhoneButton";

export default function Koordynator({data, koordynatorData, setErrorText, reloadDataFunction, czyEdycja}) {
    const onDelete = useCallback(() => {
        delKoordynator(setErrorText, data, koordynatorData.Trakcja.TrakcjaID)
            .then((resp) => handleDataReceive(resp, reloadDataFunction))
    }, [data, koordynatorData])

    return (
        <Stack direction={"horizontal"} gap={3} className={"koordynator"}>
            <div className={"ms-auto"}>
                <Trakcja
                    imgPath={koordynatorData.Trakcja.imgPath}
                    nazwa={koordynatorData.Trakcja.nazwa}
                />
            </div>
            <div>{koordynatorData.User.imie}&nbsp;{koordynatorData.User.nazwisko}</div>
            <div>
                <PhoneButton phoneNumber={koordynatorData.User.telefon}/>
            </div>
            <div className={"me-auto"}>
                {czyEdycja &&
                    <DeleteButton
                        onClick={onDelete}
                        confirmTitle={"Usuwanie koordynatora"}
                        confirmText={"Czy na pewno chcesz usunąć ze służby koordynatora " +
                            koordynatorData.User.imie + " " + koordynatorData.User.nazwisko + "?"}
                    />
                }
            </div>
        </Stack>
    );
}
