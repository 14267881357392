import PhoneIcon from "../../media/icons/PhoneIcon";
import {Button} from "react-bootstrap";

/**
 * Tworzy przycisk dzwonienia do użytkownika
 * @param {string|undefined} phoneNumber
 * @return {JSX.Element|null}
 * @constructor
 */
export function PhoneButton({phoneNumber}) {
    if (phoneNumber)
        return (
            <Button variant={"outline-success"} href={"tel://" + phoneNumber}>
                <PhoneIcon/>
            </Button>
        )
}
