import {useCallback, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {EditIcon} from "../media/icons/EditIcon";
import {CopyIcon} from "../media/icons/CopyIcon";

/**
 * @readonly
 * @enum {JSX.Element}
 */
export const ButtonIcon = {
    "NONE": <></>,
    "EDIT": <EditIcon/>,
    "COPY": <CopyIcon/>
}

/**
 * Tworzy okno modalne wraz z przyciskiem aktywującym
 * @param {string} openButtonText
 * @param {"sm","lg",undefined} openButtonSize
 * @param {string} openButtonVariant
 * @param {ButtonIcon} openButtonIcon
 * @param {string} titleId ID tytułu okna
 * @param {string} title Tytuł okna
 * @param {boolean} isInButtonGroup Czy przycisk znajduje się w grupie innych przycisków?
 * @param {JSX.Element} children Zawartość okna modalnego
 * @param {function()|undefined} childrenOnReset Funkcja wykonywana po resecie formularza
 * @param {()=>Promise<Boolean>|undefined} childrenOnSubmit Funkcja wykonywania po zatwierdzeniu formularza. Wartość zwracana stwierdza czy operacja została wykonana poprawnie
 * @param {function()|undefined} childrenOnOpen Funkcja wykonywana po otwarciu okna
 * @returns {JSX.Element}
 * @constructor
 */
export function ModalForm({
                              openButtonText,
                              openButtonSize,
                              openButtonVariant = "outline-success",
                              openButtonIcon = ButtonIcon.NONE,
                              titleId,
                              title,
                              isInButtonGroup = false,
                              children,
                              childrenOnReset = undefined,
                              childrenOnSubmit = undefined,
                              childrenOnOpen = undefined
                          }) {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onClickButton = useCallback(() => {
        setShow(true);
        if (childrenOnOpen)
            childrenOnOpen();
    }, [childrenOnOpen])

    const onSubmit = useCallback((event) => {
        if (childrenOnSubmit === undefined) {
            setShow(false);
        } else {
            setIsLoading(true);
            event.preventDefault();
            childrenOnSubmit()
                .then(resp => {
                    if (resp)
                        setShow(false);
                })
                .finally(() => setIsLoading(false));
        }
    }, [childrenOnSubmit, isLoading, show]);

    const onHide = useCallback(() => {
        setShow(false);
        setIsLoading(false);
        onReset();
    }, [show, isLoading]);

    const onReset = useCallback(() => {
        if (childrenOnReset !== undefined)
            childrenOnReset();
    }, [childrenOnReset]);

    return (
        <>
            <Button
                variant={openButtonVariant}
                size={openButtonSize}
                className={!isInButtonGroup ? "my-3" : ""}
                onClick={onClickButton}
            >
                {openButtonIcon}
                {openButtonText}
            </Button>

            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby={titleId}
                centered={true}
            >
                <Form
                    onSubmit={(e) => onSubmit(e)}
                    onReset={() => onReset()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id={titleId}>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onHide} variant={"danger"}>Anuluj</Button>
                        <Button type={"submit"} variant={"success"} disabled={isLoading}>
                            {isLoading ? "Zapisywanie..." : "Zapisz"}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

/**
 *
 * @param response
 * @param {function()} reloadDataFunction
 * @returns {Boolean}
 */
export function handleDataReceive(response, reloadDataFunction) {
    if (!response) {
        return false;
    } else {
        reloadDataFunction();
        return true;
    }
}
