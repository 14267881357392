import {Main} from "../components/Main";
import {Link, useLocation} from "react-router-dom";
import {useEffect} from "react";

/**
 * Statyczna strona zawierająca regulamin służby konduktorskiej
 * @param {string} errorText
 * @returns {JSX.Element}
 * @constructor
 */
export function RegulaminSluzby({errorText}) {
    const {hash} = useLocation();
    useEffect(() => {
        if (hash) {
            const obiekt = document.getElementById(hash.substring(1));
            obiekt?.scrollIntoView();
        }
    }, [hash]);

    return (
        <Main errorText={errorText} pageTitle={"Regulamin służby"} waitForData={false}>
            <div className={"ps-md-5 ps-1"}>
                <h2 style={{textAlign: "left"}}>Spis treści</h2>
                <ol id="spis" type="I">
                    <Link to="#r1">
                        <li>POSTANOWIENIA OGÓLNE</li>
                    </Link>
                    <Link to="#r2">
                        <li>PRZEBIEG SŁUŻBY BRYGADY KONDUKTORSKIEJ</li>
                    </Link>
                    <ol type="A">
                        <Link to="#r2a">
                            <li>CZYNNOŚCI PRZYGOTOWAWCZE ORAZ ZAKOŃCZENIE SŁUŻBY</li>
                        </Link>
                        <Link to="#r2b">
                            <li>OBSŁUGA TECHNICZNA POJAZDU W TRAKCIE SŁUŻBY</li>
                        </Link>
                        <Link to="#r2c">
                            <li>OBSŁUGA PASAŻERÓW</li>
                        </Link>
                        <Link to="#r2d">
                            <li>USTALENIA PORZĄDKOWE</li>
                        </Link>
                    </ol>
                    <Link to="#r3">
                        <li>DZIAŁANIA KOORDYNATORA</li>
                    </Link>
                    <Link to="#r4">
                        <li>POSTANOWIENIA KOŃCOWE</li>
                    </Link>
                </ol>
            </div>

            <div id="tresc" className={"px-md-5 px-1"}>
                <h2 id="r1">ROZDZIAŁ I<br/>POSTANOWIENIA OGÓLNE</h2>
                <ol>
                    <li>Niniejszy Regulamin Służby Konduktorskiej Klubu Miłośników Komunikacji Miejskiej, zwany dalej
                        „Regulaminem”, określa zakres obowiązków i czynności wykonywanych przez wyznaczone do tego celu
                        osoby pełniące funkcję Konduktora z ramienia Klubu Miłośników Komunikacji Miejskiej, zwanego
                        dalej „Klubem”, podczas realizowania specjalnych przejazdów pasażerskich organizowanych przy
                        udziale Klubu zwanych dalej „Służbą”.
                    </li>
                    <li>
                        Użyte w Regulaminie określenia oznaczają:
                        <ol type="a">
                            <li><b>Konduktor</b> - osoba wyznaczona przez Koordynatora odpowiedzialna za obsługę
                                podróżnych w pojeździe. Konduktorem może zostać każdy członek Klubu (po odbyciu
                                odpowiednich szkoleń) o nieposzlakowanej opinii, w wieku powyżej 16. roku życia (w
                                szczególnych, indywidualnie rozpatrywanych przypadkach, za zgodą Koordynatora – osoby
                                młodsze, jednak nie młodsze niż poniżej 13. roku życia). Konduktor przed przystąpieniem
                                do służby powinien zapoznać się z zasadami Bezpieczeństwa i Higieny Pracy, Regulaminem
                                Przewozu Osób i Bagażu Środkami Lokalnego Transportu Zbiorowego m.st. Warszawy oraz
                                Taryfą Zarządu Transportu Miejskiego w Warszawie. W uzasadnionych przypadkach dopuszcza
                                się, by Konduktorem została osoba spoza Klubu.
                            </li>
                            <li><b>Koordynator</b> - wyznaczona przez Zarząd Klubu osoba pełniąca nadzór nad realizacją
                                przejazdów pasażerskich organizowanych przy udziale Klubu. Koordynatorem może zostać
                                każdy członek Klubu powyżej 20. roku życia, posiadający co najmniej trzyletnie
                                doświadczenie, w którym to powinien odbyć przynajmniej 20 służb konduktorskich.
                            </li>
                            <li><b>Instruktor</b> - osoba prowadząca szkolenia Konduktorów. Instruktorem może zostać
                                pełnoletni Konduktor z minimum dwuletnim doświadczeniem oraz wyróżniający się szczególną
                                aktywnością oraz postawą. Wyboru Instruktora dokonuje Koordynator w uzgodnieniu z
                                Zarządem Klubu oraz pozostałymi Instruktorami. Szczegółowe zasady szkolenia będzie
                                ustalać indywidualnie Koordynator z kandydatem na Instruktora.
                            </li>
                            <li><b>Brygada konduktorska</b> - obsługa pojazdu składająca się z przynajmniej dwóch
                                konduktorów. W przypadkach, gdy rozkład jazdy przewiduje obsługę brygady przez pojazd z
                                drzwiami sterowanymi zdalnie przez prowadzącego pojazd, Konduktor może stanowić
                                jednoosobową brygadę konduktorską. Decyzję o utworzeniu brygady jednoosobowej podejmuje
                                Koordynator na etapie tworzenia grafiku służb.
                            </li>
                            <li><b>Brygada szkoleniowa</b> - obsługa pojazdu składająca się z Instruktora oraz
                                Konduktora odbywającego kurs przygotowawczy do pełnienia służby konduktorskiej. Brygada
                                szkoleniowa pełni także funkcje zwykłej brygady konduktorskiej. Konduktor musi przejść
                                szkolenie trwające dwie służby lub dłużej, w momencie uznania przez Instruktora, że
                                kandydat nie może samodzielnie pełnić służby. Przypadki takie powinny być rozpatrywane
                                indywidualnie. Zaleca się, by szkolenia odbywały się podczas pierwszych służb oraz w
                                różnych typach pojazdów. W uzasadnionych przypadkach i za zgodą Koordynatora drugie
                                szkolenie może odbyć się w późniejszym terminie. Koordynator lub Instruktor jest
                                zobowiązany przeszkolić przed pierwszą służbą kandydata w zakresie Bezpieczeństwa i
                                Higieny Pracy. Instruktorów dla Konduktora przyuczającego się do pełnienia służby
                                konduktorskiej wyznacza Koordynator na etapie tworzenia grafiku służb.
                            </li>
                            <li><b>Służba</b> - zakres obowiązków i czynności wykonywanych przez Brygadę konduktorską
                                oraz Koordynatora związanych z przygotowaniem, realizacją i zakończeniem służby. Termin,
                                godziny oraz czas trwania służby ujmowane są w Grafiku.
                            </li>
                            <li><b>Konduktor pełniący Gotowość do Pracy (GDP)</b> – jest to Konduktor, który w danym
                                dniu według grafiku nie pełni służby w żadnym z pojazdów. Pozostaje on do dyspozycji
                                Koordynatora w godzinach przewidzianych grafikiem. Szczegółowe zadania, oraz miejsce
                                pełnienia służby Konduktorów pozostających w GDP ustala na bieżąco Koordynator z
                                Konduktorem pełniącym GDP.
                            </li>
                        </ol>
                        Ponadto w treści regulaminu precyzowane są pojęcia: obowiązkowych elementów ubioru wierzchniego
                        oraz wyposażenia, upomnienia oraz wykluczenia ze służby.
                    </li>
                    <li>Pełniący społeczną służbę Konduktorzy kultywują tradycje oraz upowszechniają historię
                        komunikacji miejskiej Miasta Stołecznego Warszawy.
                    </li>
                    <li>Konduktorzy i Instruktorzy podlegają Koordynatorowi, wykonując wydane przez niego polecenia
                        służbowe. W przypadku nieobecności Koordynatora powinien on wyznaczyć osobę pełniącą jego
                        obowiązki. Przyjmuje się, że obowiązki Koordynatora w przypadku jego nieobecności powinien
                        pełnić wskazany przez niego Instruktor.
                    </li>
                    <li>Koordynator podlega bezpośrednio Zarządowi Klubu.</li>
                    <li>Brygada konduktorska jest zobowiązana do zastosowania się do poleceń wydanych przez prowadzącego
                        pojazd, pracowników nadzoru ruchu a także innych funkcjonariuszy służb porządkowych.
                    </li>
                    <li>Brygada konduktorska, Instruktorzy oraz Koordynator są zobowiązani do sumiennego i rzetelnego
                        wykonywania powierzonych im zadań i obowiązków oraz przestrzegania postanowień niniejszego
                        Regulaminu.
                    </li>
                    <li>Jeżeli istnieje potrzeba Zarząd Klubu może powołać do służby więcej niż jednego Koordynatora.
                    </li>
                </ol>
                <h2 id="r2">ROZDZIAŁ II<br/>PRZEBIEG SŁUŻBY BRYGADY KONDUKTORSKIEJ</h2>
                <h3 id="r2a">DZIAŁ A<br/>CZYNNOŚCI PRZYGOTOWAWCZE ORAZ ZAKOŃCZENIE SŁUŻBY</h3>
                <ol start="9">
                    <li>
                        Brygada konduktorska jest zobowiązana do stawienia się na miejsce wskazane przez Koordynatora,
                        nie później niż:
                        <ol type="a">
                            <li>15 minut przed rozkładową godziną wyjazdu właściwej brygady z zajezdni, chyba że
                                Koordynator zadecyduje inaczej.
                            </li>
                            <li>5 minut przed rozkładowym czasem rozpoczęcia służby w razie zaczynania jej w miejscu
                                innym niż zajezdnia, chyba że Koordynator zadecyduje inaczej.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Przed wyjazdem pojazdu z zajezdni Brygada konduktorska jest zobowiązana do:
                        <ol type="a">
                            <li>Zameldowania się Koordynatorowi celem potwierdzenia gotowości do objęcia służby oraz
                                ewentualnego otrzymania bieżących informacji i ogłoszeń związanych z pełnieniem służby.
                                Odpowiedzialnym za przekazanie bieżących informacji związanych z obsługą służby jest
                                Koordynator.
                            </li>
                            <li>Ustalenia między sobą podział zadań w trakcie służby (przede wszystkim wyznaczenia osób
                                obsługujących konkretne drzwi pojazdu, w tym Konduktora odpowiedzialnego za podawanie
                                sygnału zezwalającego na odjazd oraz opcjonalnie Konduktora odpowiedzialnego za
                                kasowanie biletów).
                            </li>
                            <li>Dokonania przeglądu oznakowania liniowego właściwego pojazdu oraz w razie potrzeby
                                zainstalowania (bądź też pobrania z magazynu – w przypadku Zajezdni Mokotów) brakujących
                                jego elementów (w tramwajach z wyłączeniem wymiany dachowych tablic liniowych, za
                                których montaż odpowiedzialni są pracownicy zaplecza technicznego danego Zakładu
                                Realizacji Przewozów).
                            </li>
                            <li>Skontrolowania poprawności funkcjonowania elementów wyposażenia pojazdu (oświetlenie,
                                drzwi, ogrzewanie, ustawienie kasowników itd.).
                            </li>
                            <li>Zadbania o czystość pojazdu.</li>
                            <li>Czynności podane w punktach c, d, e, wykonuje się przed i po zjeździe pojazdu do
                                zajezdni.
                            </li>
                        </ol>
                        Ponadto po zjeździe pojazdu do zajezdni do obowiązków Brygady konduktorskiej należy również:
                        <ol type="a" start="7">
                            <li>Pozamykanie okien i drzwi pojazdu.</li>
                            <li>Dokonanie przeglądu pojazdu celem wykrycia ewentualnych zniszczeń powstałych w trakcie
                                służby.
                            </li>
                            <li>Zdemontowanie ewentualnych elementów dodatkowego wystroju pojazdu przygotowanego na
                                potrzeby przejazdu (oraz zdania ich do magazynu – w przypadku Zajezdni Mokotów).
                            </li>
                        </ol>
                    </li>
                    <li>Potrzebę wymiany liniowych tablic oraz niesprawne elementy wyposażenia pojazdu Brygada
                        konduktorska niezwłocznie zgłasza prowadzącemu pojazd.
                    </li>
                    <li>Każdorazowo przed wyjazdem pojazdu z zajezdni, Brygada konduktorska ustala z prowadzącym pojazd
                        formę dawania sygnału „odjazd”. Zaleca się, by w pojazdach z ręczną obsługą drzwi sygnał
                        „odjazd” sygnalizować za pomocą jednego uderzenia dzwonkiem wewnętrznym.
                    </li>
                    <li>W wypadku wykrycia przez Brygadę konduktorską jakichkolwiek zniszczeń powstałych w trakcie
                        służby każdy Konduktor jest zobowiązany do niezwłocznego zgłoszenia ich prowadzącemu pojazd oraz
                        Koordynatorowi. Wszelkie niewłaściwości wykryte podczas Służby zgłaszane są Koordynatorowi w
                        postaci raportu (formę raportu Konduktor ustala z Koordynatorem).
                    </li>
                    <li>Członkowie Brygady konduktorskiej pełnią służbę do czasu planowego jej zakończenia chyba, że
                        Koordynator zadecyduje inaczej. Czas na wykonanie odpowiednich czynności po zjeździe do zajezdni
                        jest wliczany do czasu służby.
                    </li>
                </ol>
                <h3 id="r2b">DZIAŁ B<br/>OBSŁUGA TECHNICZNA POJAZDU W TRAKCIE SŁUŻBY</h3>
                <ol start="15">
                    <li>W tramwajach podczas Służby Konduktor jest zobowiązany do zmiany zwrotnic, jeżeli warunki na
                        trasie tego wymagają. Podczas szkolenia Instruktor jest zobowiązany do przeszkolenia Kandydata w
                        zakresie budowy, zasad działania oraz sposobu obsługi zwrotnic.
                    </li>
                    <li>Konduktor zmienia kierunkową dekorację liniową podczas postoju na krańcu. Za wiedzą i zgodą
                        prowadzącego pojazd, zmiana dekoracji liniowej jest dopuszczalna na przystanku dla
                        wysiadających.
                    </li>
                    <li>Po zatrzymaniu się pojazdu na przystanku, każdy Konduktor zobowiązany jest do zapewnienia
                        porządku i bezpieczeństwa w strefie drzwi, a przed podaniem sygnału odjazdu upewnia się, że do
                        ruszającego pojazdu nikt nie będzie usiłował wsiąść.
                    </li>
                    <li>Po zakończeniu wymiany podróżnych na danym przystanku Konduktor odpowiedzialny za podawanie
                        sygnału zezwalającego na odjazd podaje prowadzącemu pojazd ustalony sygnał „odjazd”. Dopuszcza
                        się, by pozostali Konduktorzy sygnalizowali gotowość do odjazdu poprzez uniesienie ręki tak, by
                        była ona widoczna przez Konduktora odpowiedzialnego za podawanie sygnału zezwalającego na
                        odjazd.
                    </li>
                    <li>W przypadku, gdy przystanek nie posiada platformy peronowej, przynajmniej jeden z Konduktorów
                        zobowiązany jest podczas postoju na przystanku do wyjścia z pojazdu na nawierzchnię, celem
                        zapewnienia bezpiecznej wymiany pasażerskiej.
                    </li>
                    <li>Podczas jazdy pojazdu drzwi pojazdu muszą być zamknięte. W pojazdach wyposażonych w specjalne
                        barierki zabezpieczające, dopuszczona jest jazda z otwartymi drzwiami pod warunkiem zamknięcia
                        barierek. Podczas jazdy z otwartymi drzwiami zabezpieczonymi barierkami Konduktor w żadnym
                        wypadku nie może opuszczać strefy drzwi.
                    </li>
                    <li>Bezwzględnie zabronione jest otwieranie drzwi pojazdu przeznaczonych do obsługi podróżnych dla
                        kierunku przeciwnego.
                    </li>
                </ol>
                <h3 id="r2c">DZIAŁ C<br/>OBSŁUGA PASAŻERÓW</h3>
                <ol start="22">
                    <li>W stosunku do podróżnych Brygada konduktorska zachowuje się bezinteresownie i bezstronnie.</li>
                    <li>
                        W trakcie wykonywania powierzonych obowiązków Brygada konduktorska:
                        <ol type="a">
                            <li>Zachowuje się grzecznie, taktownie, niemniej jednak stanowczo,</li>
                            <li>Przestrzega obowiązujących form grzecznościowych (przywitanie, prośba o wręczenie
                                biletów do skasowania, podziękowanie po zakończeniu kasowania).
                            </li>
                        </ol>
                    </li>
                    <li>W razie naruszania przez podróżnego przepisów porządkowych, Konduktor w sposób grzeczny i
                        stanowczy udziela napomnienia.
                    </li>
                    <li>W uzasadnionych przypadkach każdy członek Brygady konduktorskiej ma obowiązek zgłosić
                        niepożądane zachowania pasażera prowadzącemu pojazd oraz zażądać usunięcia takiego pasażera z
                        pojazdu również przy użyciu odpowiednich służb mundurowych. O zdarzeniach tego typu należy
                        powiadomić Koordynatora oraz opisać zdarzenie w raporcie (formę raportu Konduktor ustala z
                        Koordynatorem).
                    </li>
                    <li>W przypadkach szczególnych (awaria pojazdu, objazd, inne zdarzenie losowe) mających wpływ na
                        przebieg służby, Brygada konduktorska ma obowiązek niezwłocznie powiadomić o tym fakcie
                        Koordynatora, a ten – w miarę możliwości – pozostałe Brygady, pełniące danego dnia służbę
                        konduktorską. Ponadto Konduktorzy, w porozumieniu z prowadzącym pojazd oraz nadzorem ruchu,
                        jeżeli takowy jest, wygłaszają stosowne komunikaty informacyjne dla pasażerów.
                    </li>
                    <li>Podczas postoju pojazdu na przystankach Brygada konduktorska zobowiązana jest do udzielania
                        pomocy przy wsiadaniu i wysiadaniu z pojazdu podróżnym tego potrzebującym.
                    </li>
                    <li>Konduktor, któremu podczas Służby powierzono obowiązek kasowania biletów nie może w tym czasie
                        wykonywać innych zadań np. obsługi drzwi.
                    </li>
                    <li>Brygada konduktorska jest zobowiązana do znajomości trasy przejazdu oraz obowiązującego podczas
                        służby rozkładu jazdy i przekazywania tych informacji na każdorazowe życzenie pasażerów.
                    </li>
                </ol>
                <h3 id="r2d">DZIAŁ D<br/>USTALENIA PORZĄDKOWE</h3>
                <ol start="30">
                    <li>W trakcie pełnienia Służby, Brygada konduktorska winna nosić na sobie czysty, schludny i
                        kompletny ubiór wierzchni, którego dodatkowe elementy mogą zostać wyznaczone przez Koordynatora.
                    </li>
                    <li>
                        Elementami ubioru wierzchniego i wyposażenia członka Brygady konduktorskiej są:
                        <ol type="a">
                            <li>Niebieska lub biała koszula z kołnierzykiem.</li>
                            <li>Opcjonalnie krawat lub apaszka.</li>
                            <li>Spodnie materiałowe (garniturowe lub mundurowe) ewentualnie sztruksowe, jednolitego
                                koloru (granatowe lub czarne), w przypadku kobiet dopuszcza się noszenie spódnic według
                                wspomnianych wyżej zasad.
                            </li>
                            <li>Buty odpowiednie do pozostałych elementów ubioru.</li>
                            <li>Umieszczony w widocznym miejscu, zawierający numer służbowy Identyfikator, dostarczony
                                przez Koordynatora.
                            </li>
                        </ol>
                    </li>
                    <li>Konduktor podczas obsługi pasażerów powinien zachować podstawowe zasady higieny.</li>
                    <li>W przypadku, gdy członek Brygady konduktorskiej musi opuścić służbę przed ustalonym czasem jej
                        zakończenia, jest on zobowiązany do niezwłocznego poinformowania o tym fakcie Koordynatora,
                        który ustali z zainteresowanym szczegóły wcześniejszego zakończenia Służby, oraz poinformuje o
                        zaistniałym fakcie Konduktora pełniącego GDP.
                    </li>
                    <li>Nazwiska Konduktorów, którzy w sposób szczególny pełnieniem służby przyczyniają się do tworzenia
                        pozytywnego wizerunku Klubu, na wniosek Koordynatora są przedstawiane Zarządowi Klubu celem
                        udzielenia publicznej pochwały na forum Klubu.
                    </li>
                    <li>
                        Za złamanie postanowień Regulaminu przez Konduktora Koordynator ma prawo:
                        <ol type="a">
                            <li>Udzielić upomnienia członkowi Brygady konduktorskiej,</li>
                            <li>W przypadku stwierdzenia zaniedbań w wypełnianiu powierzonych obowiązków mogących
                                skutkować zagrożeniem zdrowia lub mienia pasażerów bądź obsługi pojazdu dokonać
                                zawieszenia w obowiązkach lub wykluczenia z pełnienia Służby.
                            </li>
                        </ol>
                    </li>
                </ol>
                <h2 id="r3">ROZDZIAŁ III<br/>DZIAŁANIA KOORDYNATORA</h2>
                <ol start="36">
                    <li>Działania Koordynatora są jawne.</li>
                    <li>Koordynator jest zobowiązany do zorganizowania obsady Brygady konduktorskiej niezbędnej do
                        sprawnej realizacji obsługi konduktorskiej danej Służby.
                    </li>
                    <li>Koordynator lub osoba przez niego wyznaczona z odpowiednim wyprzedzeniem dokonuje zapisów oraz
                        weryfikacji chętnych do objęcia Służby konduktorskiej. Co do zasady zapisów dokonuje się za
                        pomocą Internetowego Systemu Służb Konduktorskich (w skrócie ISSK). Nadzór nad ISSK sprawuje
                        administrator systemu we współpracy z Koordynatorem.
                    </li>
                    <li>
                        Koordynator ma prawo odmówić zapisania na Służbę lub dopuszczenia do niej osobie, która:
                        <ol type="a">
                            <li>W okresie 6 miesięcy poprzedzających dzień planowanego rozpoczęcia Służby otrzymała od
                                Koordynatora, co najmniej dwa upomnienia podczas odbywających się przejazdów,
                            </li>
                            <li>W okresie 12 miesięcy poprzedzających dzień planowanego rozpoczęcia Służby otrzymała
                                wykluczenie ze Służby,
                            </li>
                            <li>W momencie planowanego objęcia Służby nie posiada określonego Regulaminem obowiązkowego
                                ubioru wierzchniego,
                            </li>
                            <li>Nie spełnia wymogów formalnych bycia członkiem Brygady konduktorskiej określonych w
                                Rozdziale I, § 2 lit. a niniejszego Regulaminu.
                            </li>
                        </ol>
                    </li>
                    <li>Od wydanej na podstawie §39 decyzji Koordynatora, zainteresowanemu Konduktorowi przysługuje w
                        terminie 7 dni od wydania decyzji odwołanie do Zarządu Klubu.
                    </li>
                    <li>Koordynator dokonuje podziału obsługi konduktorskiej na poszczególne Brygady konduktorskie.</li>
                    <li>
                        Koordynator przekazuje Brygadzie konduktorskiej następujące informacje o służbie nie później niż
                        na 2 dni przed jej rozpoczęciem. Na etapie zapisów w ISSK powinny znaleźć się następujące
                        informacje:
                        <ol type="a">
                            <li>Godzina i miejsce objęcia oraz zakończenia Służby,</li>
                            <li>Planowana trasa przejazdu,</li>
                            <li>Ramowy rozkład jazdy, o ile jest ustalony,</li>
                            <li>Numer telefonu kontaktowego pod którym Koordynator będzie dostępny w trakcie trwania
                                służby.
                            </li>
                        </ol>
                    </li>
                    <li>Koordynator, bądź osoba przez niego wyznaczona, osobiście wyprawia Brygadę konduktorską wraz z
                        pojazdem i odbiera Służbę po jej zakończeniu.
                    </li>
                </ol>
                <h2 id="r4">ROZDZIAŁ IV<br/>POSTANOWIENIA KOŃCOWE</h2>
                <ol start="44">
                    <li>W pojazdach uruchamianych na osobne zarządzenie, udostępnianie przejazdu innym osobom, tj. spoza
                        grupy uczestników przejazdu zorganizowanego, jest zabronione nawet w razie wolnych miejsc w tym
                        pojeździe, chyba że zamawiający wyrazi zgodę na przewóz dodatkowych osób.
                    </li>
                    <li>W trakcie wydarzeń organizowanych na zlecenie Zarządu Transportu Miejskiego w Warszawie, podczas
                        których w pojazdach obowiązuje taryfa Zarządu Transportu Miejskiego, przepisami obowiązującymi
                        obsługę i pasażerów są postanowienia Regulaminu Przewozu Osób i Bagażu Środkami Lokalnego
                        Transportu Zbiorowego m.st. Warszawy.
                    </li>
                    <li>W kwestiach nieuregulowanych niniejszym regulaminem zastosowanie mają przepisy wewnętrzne oraz
                        regulacje prawa krajowego oraz miejscowego.
                    </li>
                    <li>Regulamin wchodzi w życie z dniem uchwalenia przez Zarząd Klubu.</li>
                </ol>
            </div>
        </Main>
    )
}
