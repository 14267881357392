import {handleDataReceive, ModalForm} from "../../../components/ModalForm";
import {useCallback, useState} from "react";
import {getUsersUprawnieniaList} from "../../../apiCalls/uprawnieniaApi";
import {Form} from "react-bootstrap";
import ErrorBanner from "../../../components/ErrorBanner";
import {zapiszKonduktoraZmiana} from "../../../apiCalls/zmianyApi";

/**
 * Zapisywanie konduktora na zmianę
 * @param {int} zmianaID
 * @param {int} kategoriaID
 * @param {string} zmianaName
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function ZapiszKonduktora({zmianaID, kategoriaID, zmianaName, reloadDataFunction}) {
    const [errorText, setErrorText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    /** @type UprawnieniaUser[] */
    let usersList;
    let setUsersList;
    [usersList, setUsersList] = useState([]);

    const [konduktorID, setKonduktorID] = useState("");

    // Ładowanie listy konduktorów z uprawnieniami do danej kategorii
    const onOpen = useCallback(() => {
        setIsLoading(true);
        getUsersUprawnieniaList(setErrorText, kategoriaID)
            .then(resp => {
                setUsersList(resp);
                setIsLoading(false);
            })
    }, [isLoading, usersList, kategoriaID]);

    const onSubmit = useCallback(() => {
        setErrorText("");
        return zapiszKonduktoraZmiana(setErrorText, zmianaID, konduktorID)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [errorText, zmianaID, konduktorID]);

    const onReset = useCallback(() => {
        setErrorText("");
        setKonduktorID("");
    }, [errorText, konduktorID]);

    return (
        <ModalForm
            openButtonText={"Zapisz konduktora"}
            openButtonVariant={"outline-primary"}
            title={"Zapisywanie konduktora na służbę"}
            isInButtonGroup={true}
            childrenOnSubmit={onSubmit}
            childrenOnReset={onReset}
            childrenOnOpen={onOpen}
        >
            <ErrorBanner errorText={errorText}/>

            <Form.Group className={"mb-2"}>
                <Form.Label>Zadanie</Form.Label>
                <Form.Control disabled={true} value={zmianaName}/>
            </Form.Group>
            <Form.Group className={"mb-2"}>
                <Form.Label>Konduktor</Form.Label>
                <Form.Select name={"UserID"} disabled={isLoading}
                             value={konduktorID} onChange={e => setKonduktorID(e.target.value)}
                >
                    <option disabled={!!konduktorID}>
                        {isLoading ? "Ładowanie listy konduktorów" : "Wybierz konduktora"}
                    </option>
                    {
                        usersList.map(u =>
                            <option key={u.user.UserID} value={u.user.UserID}>
                                {`${u.user.imie} ${u.user.nazwisko} ${u.czyPrzeszkolony ? "" : "(SZKOLENIE)"}`}
                            </option>)
                    }
                </Form.Select>
            </Form.Group>
        </ModalForm>
    )
}
