import {_apiCall, MethodType} from "./_apiCall";

const addrBaza = "brygady"

/**
 * Brygada
 * @typedef BrygadaData
 * @property {int} BrygadaID ID Brygady
 * @property {string} linia Oznaczenie linii
 * @property {string} brygada Oznaczenie brygady
 * @property {string} nazwa Pełna nazwa brygady (linia/brygada)
 * @property {TrakcjaData} trakcja Trakcja brygady
 * @property {string} godzRozp Godzina rozpoczęcia pracy brygady
 * @property {string} godzZak Godzina zakończenia pracy brygady
 * @property {{ZestawPojazdowID: int|null, opis:string|null}} zestawPojazdow Zestaw pojazdów przypisany do brygady
 * @property {{ZajezdniaID: int|null, oznaczenie: string|null, nazwa: string|null}} zajezdnia Zajezdnia powiązana z brygadą
 * @property {string|null} uwagi Uwagi dotyczące brygady
 * @property {[ZmianaData]} zmiany Lista zmian na brygadzie
 */

/**
 * Dodaje brygadę
 * @param {function(string)} setErrorText
 * @param {string} data
 * @param {string} linia
 * @param {string|null} brygada
 * @param {string} TrakcjaID
 * @param {string} godzRozp
 * @param {string} godzZak
 * @param {string|null} ZestawPojazdowID
 * @param {string|null} ZajezdniaID
 * @param {string} uwagi
 */
export function addBrygada(setErrorText, data, linia, brygada, TrakcjaID, godzRozp, godzZak, ZestawPojazdowID, ZajezdniaID, uwagi) {
    const content = {data, linia, brygada, TrakcjaID, godzRozp, godzZak, ZestawPojazdowID, ZajezdniaID, uwagi};
    return _apiCall(addrBaza, MethodType.POST, setErrorText, content)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Kopiuje parametry brygady o podanym ID do wskazanej daty
 * @param {function(string)} setErrorText
 * @param {int|string} brygadaID
 * @param {string} data
 * @param {boolean} zmiany
 */
export function copyBrygada(setErrorText, brygadaID, data, zmiany) {
    const content = {data, zmiany};
    return _apiCall(`${addrBaza}/${brygadaID}`, MethodType.POST, setErrorText, content)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Aktualizuje brygadę
 * @param {function(string)} setErrorText
 * @param {int} brygadaID
 * @param {string} data
 * @param {string} linia
 * @param {string} brygada
 * @param {string|int} TrakcjaID
 * @param {string} godzRozp
 * @param {string} godzZak
 * @param {string|int} ZestawPojazdowID
 * @param {string|int} ZajezdniaID
 * @param {string} uwagi
 */
export function editBrygada(setErrorText, brygadaID, data, linia, brygada, TrakcjaID, godzRozp, godzZak, ZestawPojazdowID, ZajezdniaID, uwagi) {
    const content = {data, linia, brygada, TrakcjaID, godzRozp, godzZak, ZestawPojazdowID, ZajezdniaID, uwagi};
    return _apiCall(`${addrBaza}/${brygadaID}`, MethodType.PUT, setErrorText, content)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Usuwa brygadę o podanym ID
 * @param {function(string)} setErrorText
 * @param {int|string} brygadaID
 */
export function deleteBrygada(setErrorText, brygadaID) {
    return _apiCall(`${addrBaza}/${brygadaID}`, MethodType.DELETE, setErrorText)
        .then(resp => resp.data)
        .catch(() => null);
}
