import {dataToLocalString} from "../../helpers/dataHelper";
import {Button, ButtonGroup, Table} from "react-bootstrap";
import {SzkolenieRaport} from "../dataElements/szkolTabElements/SzkolenieRaport";
import {UsunInstruktora} from "../dataElements/szkolTabElements/UsunInstruktora";
import {UsunKonduktora} from "../dataElements/szkolTabElements/UsunKonduktora";

/**
 *
 * @param {SzkolTabData[]} szkolTabList
 * @param {int} userID
 * @param {boolean} isKoordynator
 * @param {function(string)} setErrorText
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function SzkolTab({szkolTabList, userID, isKoordynator, setErrorText, reloadDataFunction}) {
    return (
        <Table>
            <thead>
            <tr>
                <th>Data</th>
                <th>Linia/Brygada/Zmiana</th>
                <th>Rodzaj uprawnień</th>
                <th>Kursant</th>
                <th>Instruktor</th>
                <th>Wynik szkolenia</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                szkolTabList.map(s =>
                    <tr key={`z${s.zmiana.zmianaID}u${s.user.UserID}`}>
                        <td>{dataToLocalString(s.zmiana.data)}</td>
                        <td>{s.zmiana.nazwa}</td>
                        <td>{s.kategoria.nazwa}</td>
                        <td>{s.user.imie} {s.user.nazwisko}</td>
                        <td>
                            {s.instruktor && s.instruktor.UserID ?
                                `${s.instruktor.imie} ${s.instruktor.nazwisko}` :
                                <i>Nie wyznaczono jeszcze instruktora</i>
                            }
                            {s.instruktor && s.instruktor.UserID && isKoordynator && s.wynik === null &&
                                <UsunInstruktora
                                    zmianaID={s.zmiana.zmianaID}
                                    konduktorID={s.user.UserID}
                                    konduktorImie={s.user.imie}
                                    konduktorNazwisko={s.user.nazwisko}
                                    instruktorImie={s.instruktor.imie}
                                    instruktorNazwisko={s.instruktor.nazwisko}
                                    setErrorText={setErrorText}
                                    reloadDataFunction={reloadDataFunction}
                                />
                            }
                        </td>
                        <td>
                            {s.wynik !== null ?
                                <span style={{color: s.wynik ? "green" : "red"}}>
                                    {s.wynik ? "POZYTYWNY" : "NEGATYWNY"}
                                </span>
                                : (
                                    s.czyZakonczone ? (
                                            userID === s.instruktor.UserID ?
                                                <SzkolenieRaport
                                                    zmianaID={s.zmiana.zmianaID}
                                                    kursantID={s.user.UserID}
                                                    reloadDataFunction={reloadDataFunction}
                                                />
                                                :
                                                <i>Nie wypełniono jeszcze raportu</i>
                                        )
                                        :
                                        <i>Zaplanowane do realizacji</i>
                                )}
                        </td>
                        <td>
                            <ButtonGroup>
                                <Button variant={"outline-primary"} href={`/kalendarz/${s.zmiana.data}`}>
                                    Podgląd dnia
                                </Button>
                                {
                                    isKoordynator && s.wynik === null &&
                                    <UsunKonduktora
                                        zmianaID={s.zmiana.zmianaID}
                                        zmianaName={s.zmiana.nazwa}
                                        userID={s.user.UserID}
                                        userImie={s.user.imie}
                                        userNazwisko={s.user.nazwisko}
                                        czySzkolenie={true}
                                        onListaSzkolen={true}
                                        setErrorText={setErrorText}
                                        reloadDataFunction={reloadDataFunction}
                                    />
                                }
                                {/*TODO: Usuwanie szkolenia*/}
                            </ButtonGroup>
                        </td>
                    </tr>)
            }
            </tbody>
        </Table>
    )
}
