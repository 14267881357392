import {Routes, Route, Navigate, useNavigate} from "react-router-dom";
import Navigation from "../components/Navigation";
import Kalendarz from "../strony/Kalendarz";
import KalendarzData from "../strony/KalendarzData";
import {getCurrentUser, getCurrentUserLocal} from "../helpers/authHelper";
import {useEffect, useState} from "react";
import ErrorBanner from "../components/ErrorBanner";
import {getErrorTextParam} from "../helpers/searchParamsHelper";
import {CurrentUser} from "../strony/CurrentUser";
import {ListaSzkolen} from "../strony/ListaSzkolen";
import {Grafik} from "../strony/Grafik";
import {RegulaminSluzby} from "../strony/RegulaminSluzby";
import {InformacjeDodatkowe} from "../strony/InformacjeDodatkowe";

/**
 *
 * @param {string|null} token
 * @param {function(string|null)} setToken
 * @returns {JSX.Element}
 */
function LoggedRouter({setToken}) {
    const navigate = useNavigate();
    const [mainError, setMainError] = useState(null);
    const [userData, setUserData] = useState(getCurrentUserLocal());

    const [errorText, setErrorText] = useState(getErrorTextParam());

    useEffect(() => {
        if (!userData)
            getCurrentUser(setMainError)
                .then(data => {
                    if (data) {
                        setUserData(data);
                    }
                });
    }, [userData]);

    return (
        <>
            {userData && <>
                <Navigation userData={userData} setToken={setToken}/>
                <main className="m-1">
                    <ErrorBanner errorText={mainError}/>
                    <Routes>
                        <Route path="/" element={<Navigate to="/kalendarz"/>}/>
                        <Route path="/kalendarz" element={
                            <Kalendarz
                                errorText={errorText}
                                setErrorText={setErrorText}
                                userUprawnienia={userData.userUprawnienia}
                                navigate={navigate}
                            />}
                        />
                        <Route path={"/kalendarz/:data"} element={
                            <KalendarzData
                                errorText={errorText} setErrorText={setErrorText}
                                userID={userData.UserID}
                                sluzbyGDP={userData.userUprawnienia.sluzby_gdp}
                                sluzbyZapis={userData.userUprawnienia.sluzby_zapis}
                            />}
                        />
                        <Route path={"/szkolenia"} element={
                            <ListaSzkolen
                                errorText={errorText}
                                setErrorText={setErrorText}
                                userID={userData.UserID}
                                isKoordynator={userData.userUprawnienia.kalendarz_koordynator}
                            />}
                        />
                        <Route path="/regulamin" element={<RegulaminSluzby errorText={errorText}/>}/>
                        <Route path={"/informacje"} element={<InformacjeDodatkowe errorText={errorText}/>}/>
                        <Route path={"/user"}
                               element={<CurrentUser errorText={errorText} setErrorText={setErrorText}/>}/>
                        <Route path={"/grafik"} element={
                            <Grafik errorText={errorText} setErrorText={setErrorText} currentUserData={userData}/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                </main>
            </>
            }
        </>)
}

export default LoggedRouter;
