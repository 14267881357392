import {Figure} from "react-bootstrap";

/**
 *
 * @param {Image} imgPath
 * @param {string} name imię i nazwisko delikwenta
 * @param {string} podpis
 * @returns {JSX.Element}
 * @constructor
 */
export function Zdjecie({imgPath, name, podpis}){
    return (
        <Figure>
            <Figure.Image
                alt={podpis}
                src={imgPath}
            />
            <Figure.Caption>
                <span>{name}</span><br/>
                {podpis}
            </Figure.Caption>
        </Figure>
    )
}
