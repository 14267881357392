import {Alert} from "react-bootstrap";

/**
 * Banner z komunikatem informacyjnym
 * @param {string} intoText
 * @returns {JSX.Element}
 * @constructor
 */
export function InfoBanner({intoText}) {
    if (!intoText)
        return (<></>);

    return (
        <Alert variant={"info"}>
            {intoText}
        </Alert>
    )
}
