import {DeleteButton} from "../../../components/buttons/DeleteButton";
import {useCallback} from "react";
import {usunKonduktoraZmiana} from "../../../apiCalls/zmianyApi";
import {handleDataReceive} from "../../../components/ModalForm";

/**
 * Usuwa konduktora ze służby
 * @param {int} zmianaID
 * @param {string} zmianaName
 * @param {int} userID
 * @param {string} userImie
 * @param {string} userNazwisko
 * @param {boolean} czySzkolenie
 * @param {boolean} onListaSzkolen Definiuje czy przycisk jest widoczny na liście szkoleń
 * @param {function(string)} setErrorText
 * @param {function()} reloadDataFunction
 * @returns {JSX.Element}
 * @constructor
 */
export function UsunKonduktora({
                                   zmianaID,
                                   zmianaName,
                                   userID,
                                   userImie,
                                   userNazwisko,
                                   czySzkolenie,
                                   onListaSzkolen = false,
                                   setErrorText,
                                   reloadDataFunction
                               }) {
    const onDelete = useCallback(() => {
        usunKonduktoraZmiana(setErrorText, zmianaID, userID)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [zmianaID, userID]);

    return (
        <DeleteButton
            onClick={onDelete}
            buttonWithIcon={!onListaSzkolen}
            buttonText={onListaSzkolen ? "Odwołaj szkolenie" : ""}
            confirmTitle={"Usuwanie konduktora ze służby"}
            confirmText={`Czy na pewno chcesz usunąć konduktora ${userImie} ${userNazwisko} ze służby ${zmianaName}?${czySzkolenie ? " Spowoduje to również usunięcie zaplanowanego szkolenia." : ""}`}
        />
    )
}
