import {ButtonIcon, handleDataReceive, ModalForm} from "../../../components/ModalForm";
import {useCallback, useState} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {ResetIcon} from "../../../media/icons/ResetIcon";
import {TimerIcon} from "../../../media/icons/TimerIcon";
import {dateTimeToFormString} from "../../../helpers/dataHelper";
import ErrorBanner from "../../../components/ErrorBanner";
import {updateKalendarzData} from "../../../apiCalls/kalendarzApi";

/**
 *
 * @param {string} data
 * @param {string} defaultPublishData
 * @param {string} defaultUwagi
 * @param {boolean} defaultCzyGDP
 * @param {function()} reloadDataFunction
 * @return {JSX.Element}
 * @constructor
 */
export function EdytujDzien({data, defaultPublishData, defaultUwagi, defaultCzyGDP, reloadDataFunction}) {
    const [errorText, setErrorText] = useState(null);

    const [dataPublikacji, setDataPublikacji] = useState(dateTimeToFormString(defaultPublishData));
    const [koordynatorNotes, setKoordynatorNotes] = useState(defaultUwagi);
    const [czyDopuszczoneGDP, setCzyDopuszczoneGDP] = useState(defaultCzyGDP);

    const onClickTimer = useCallback(() => {
        setDataPublikacji(dateTimeToFormString(new Date()));
    }, []);

    const onResetTime = useCallback(() => {
        setDataPublikacji("");
    }, []);

    const onSubmit = useCallback(() => {
        setErrorText(null);
        return updateKalendarzData(setErrorText, data, dataPublikacji, koordynatorNotes, czyDopuszczoneGDP)
            .then(resp => handleDataReceive(resp, reloadDataFunction));
    }, [data, dataPublikacji, koordynatorNotes, czyDopuszczoneGDP]);

    const onReset = useCallback(() => {
        setErrorText(null);
        setDataPublikacji(dateTimeToFormString(defaultPublishData));
        setKoordynatorNotes(defaultUwagi);
        setCzyDopuszczoneGDP(defaultCzyGDP);
    }, [data, dataPublikacji, koordynatorNotes, czyDopuszczoneGDP]);

    return (
        <ModalForm openButtonText={"Edytuj dzień"} isInButtonGroup={true} openButtonVariant={"outline-warning"}
                   openButtonIcon={ButtonIcon.EDIT} title={"Edycja dnia"} childrenOnSubmit={onSubmit}
                   childrenOnReset={onReset}
        >
            <ErrorBanner errorText={errorText}/>
            <Form.Group className={"mb-2"}>
                <Form.Label>Data publikacji</Form.Label>
                <InputGroup>
                    <Form.Control type={"datetime-local"} name={"dataPublikacji"}
                                  value={dataPublikacji} onChange={e => setDataPublikacji(e.target.value)}/>
                    <InputGroup.Text onClick={onClickTimer} className={"pointer"}>
                        <TimerIcon/>
                    </InputGroup.Text>
                    <InputGroup.Text onClick={onResetTime} className={"pointer"}>
                        <ResetIcon/>
                    </InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group className={"mb-2"}>
                <Form.Label>Uwagi od koordynatora</Form.Label>
                <InputGroup>
                    <Form.Control type={"text"} name={"uwagi"} value={koordynatorNotes}
                                  onChange={e => setKoordynatorNotes(e.target.value)}/>
                </InputGroup>
            </Form.Group>
            <Form.Check
                type={"switch"}
                label={"Czy dopuszczone GDP?"}
                checked={czyDopuszczoneGDP}
                onChange={e => setCzyDopuszczoneGDP(e.target.checked)}
            />
        </ModalForm>
    )
}
