import {Main} from "../components/Main";
import {useCallback, useState} from "react";
import {Accordion} from "react-bootstrap";
import {getSzkoleniaTaborowe} from "../apiCalls/szkolTabApi";
import {SzkolTab} from "./szkoleniaListElements/SzkolTab";
import {getSzkoleniaStacjonarne} from "../apiCalls/szkolStacApi";
import {SzkolStac} from "./szkoleniaListElements/SzkolStac";
import {InfoBanner} from "../components/InfoBanner";

/**
 * Strona z listą wszystkich szkoleń w systemie
 * @param {string} errorText
 * @param {function(string)} setErrorText
 * @param {int} userID ID bieżącego użytkownika
 * @param {boolean} isKoordynator Definiuje czy bieżący użytkownik ma uprawnienia koordynatora
 * @returns {JSX.Element}
 * @constructor
 */
export function ListaSzkolen({errorText, setErrorText, userID, isKoordynator}) {

    /** @type SzkolTabData[] */
    let szkolTabList;
    let setSzkolTabList;
    [szkolTabList, setSzkolTabList] = useState([]);
    /** @type SzkolStacBasic[] */
    let szkolStacList;
    let setSzkolStacList;
    [szkolStacList, setSzkolStacList] = useState([]);

    const loadData = useCallback(() => {
        return Promise.all([
            getSzkoleniaTaborowe(setErrorText),
            getSzkoleniaStacjonarne(setErrorText)
        ])
            .then(resp => {
                setSzkolTabList(resp[0]);
                setSzkolStacList(resp[1]);
            })
    }, []);

    return (
        <Main
            errorText={errorText}
            pageTitle={"Lista szkoleń"}
            waitForData={false}
            loadDataFunction={loadData}
        >
            <Accordion defaultActiveKey={["tab", "stac"]} alwaysOpen={true}>
                <Accordion.Item eventKey={"tab"}>
                    <Accordion.Header>Szkolenia taborowe</Accordion.Header>
                    <Accordion.Body>
                        <InfoBanner
                            intoText={"Osoba zastępująca koordynatora może zarządzać szkoleniami z poziomu kalendarza"}
                        />
                        <SzkolTab
                            szkolTabList={szkolTabList}
                            userID={userID}
                            isKoordynator={isKoordynator}
                            setErrorText={setErrorText}
                            reloadDataFunction={loadData}
                        />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={"stac"}>
                    <Accordion.Header>Szkolenia stacjonarne</Accordion.Header>
                    <Accordion.Body>
                        <InfoBanner
                            intoText={"Zarządzanie szkoleniami możliwe jest z poziomu kalendarza"}
                        />
                        <SzkolStac szkolStacList={szkolStacList}/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Main>
    )
}
