import axios, {AxiosError} from 'axios';
import {getToken, saveToken} from "../helpers/authHelper";
import {stringifyErrorTextParam} from "../helpers/searchParamsHelper";

const apiUrl = process.env.REACT_APP_API_ADDR;

/**
 * Enum metod zapytań HTTP
 * @readonly
 * @enum {string}
 */
export const MethodType = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
}

/**
 * Funkcja wywołująca do serwera zapytanie API
 * @param {string} address Końcówka zapytania API (np. dla "/api/login" należy podać sam "login")
 * @param {MethodType} method Metoda HTTP zapytania
 * @param {Object|undefined} content Zawartość formularza przesyłanego na serwer
 * @param {function(string)|undefined} setError Miejsce do którego będą zwracane komunikaty o błędach
 * @return {Promise<AxiosResponse>} Odpowiedź z serwera
 * @throws {AxiosError}
 * @public
 */
export function _apiCall(address, method, setError = undefined, content = undefined) {
    const url = `${apiUrl}/${address}`;
    const options = {
        url: url,
        method: method,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const token = getToken();
    if (token !== null) {
        options.headers['Authorization'] = `Bearer ${token}`;
    }

    if (method !== MethodType.GET) {
        options['data'] = content;
    }

    return axios.request(options)
        .catch(error => {
            const networkError = error.code === "ERR_NETWORK";
            try {
                error.message = networkError ? "Błąd połączenia z serwerem API" : error.response.data;
            } catch (err) {
                error.message = err.message;
            }

            if (setError)
                setError(error.message);

            throw error;
        })
}

/**
 * Obsługa błędów zapytań API
 * @param {Error|AxiosError} err
 */
export function apiErrorHandler(err) {
    if (err.response === undefined) {
        saveToken(null);
        window.location.href = "/login?" + stringifyErrorTextParam(err.message);
        return null;
    }
    switch (err.response.status) {
        case 401:
            saveToken(null);
            window.location.href = "/login";
            break;
    }
    return null;
}

/**
 * Zwraca informację czy zapytanie wprowadzania/aktualizacji/usuwania danych zakończyło się sukcesem
 * @param {AxiosResponse} resp
 * @returns {boolean}
 */
export function isSuccess(resp) {
    return resp.status >= 200 && resp.status < 300;
}
