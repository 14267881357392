import {_apiCall, MethodType} from "./_apiCall";

const addrBaza = "brygady/zmiany";

/**
 * Zmiany
 * @typedef ZmianaData
 * @property {int} zmianaID ID Zmiany
 * @property {KategorieSluzbBasic} kategoria Kategoria służby
 * @property {string} oznaczenie Oznaczenie zmiany
 * @property {string} nazwa Pełna nazwa zmiany (linia/brygada/zmiana)
 * @property {string} godzRozp Godzina rozpoczęcia zmiany
 * @property {string} godzZak Godzina zakończenia zmiany
 * @property {string|null} miejsceRozp Miejsce rozpoczęcia służby
 * @property {string|null} miejsceZak Miejsce zakończenia służby
 * @property {int} liczbaKonduktorow Maksymalna dopuszczalna liczba konduktorów
 * @property {[ZmianaKonduktorData]} konduktorzy Lista konduktorów pełniących służbę
 * @property {boolean} czyZapis Definiuje czy użytkownik może się zapisać na tę brygadę (są miejsca + posiada uprawnienia)
 */

/**
 * Dane o konduktorach na zmianie
 * @typedef {UserTelefon | {szkolenie: SzkolTabGrafik|null}} ZmianaKonduktorData
 */

/**
 * Dodaje zmianę na brygadzie
 * @param {function(string)} setErrorText
 * @param {int} brygadaID
 * @param {string|null} oznaczenie
 * @param {string|int} KategoriaID
 * @param {string|null} godzRozp
 * @param {string|null} godzZak
 * @param {string|null} miejsceRozp
 * @param {string|null} miejsceZak
 * @param {int} liczbaKonduktorow
 */
export function addZmiana(setErrorText, brygadaID, oznaczenie, KategoriaID, godzRozp, godzZak, miejsceRozp, miejsceZak, liczbaKonduktorow) {
    const content = {brygadaID, oznaczenie, KategoriaID, godzRozp, godzZak, miejsceRozp, miejsceZak, liczbaKonduktorow};
    return _apiCall(addrBaza, MethodType.POST, setErrorText, content)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Edytuje zmianę na brygadzie
 * @param {function(string)} setErrorText
 * @param {int} zmianaID
 * @param {string|null} oznaczenie
 * @param {string|int} KategoriaID
 * @param {string|null} godzRozp
 * @param {string|null} godzZak
 * @param {string|null} miejsceRozp
 * @param {string|null} miejsceZak
 * @param {string|int} liczbaKonduktorow
 */
export function editZmiana(setErrorText, zmianaID, oznaczenie, KategoriaID, godzRozp, godzZak, miejsceRozp, miejsceZak, liczbaKonduktorow) {
    const
        addr = `${addrBaza}/${zmianaID}`,
        content = {oznaczenie, KategoriaID, godzRozp, godzZak, miejsceRozp, miejsceZak, liczbaKonduktorow};
    return _apiCall(addr, MethodType.PUT, setErrorText, content)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Usuwa zmianę z systemu
 * @param {function(string)} setErrorText
 * @param {int} zmianaID
 */
export function delZmiana(setErrorText, zmianaID) {
    const addr = `${addrBaza}/${zmianaID}`;
    return _apiCall(addr, MethodType.DELETE, setErrorText)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Zapisuje bieżącego użytkownika na służbę
 * @param {function(string)} setErrorText
 * @param {int} zmianaID
 */
export function zapiszSieZmiana(setErrorText, zmianaID) {
    const addr = `${addrBaza}/${zmianaID}/sluzba`;
    return _apiCall(addr, MethodType.POST, setErrorText)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Zapisuje wskazanego konduktora na służbę
 * @param {function(string)} setErrorText
 * @param {int} zmianaID
 * @param {string|int} UserID
 */
export function zapiszKonduktoraZmiana(setErrorText, zmianaID, UserID) {
    const addr = `${addrBaza}/${zmianaID}/sluzba`;
    return _apiCall(addr, MethodType.POST, setErrorText, {UserID})
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Wypisuje bieżącego użytkownika ze zmiany
 * @param {function(string)} setErrorText
 * @param {int} zmianaID
 */
export function wypiszSieZmiana(setErrorText, zmianaID) {
    const addr = `${addrBaza}/${zmianaID}/sluzba`;
    return _apiCall(addr, MethodType.DELETE, setErrorText)
        .then(resp => resp.data)
        .catch(() => null);
}

/**
 * Usuwa wskazanego konduktora ze służby
 * @param {function(string)} setErrorText
 * @param {int} zmianaID
 * @param {int} UserID
 */
export function usunKonduktoraZmiana(setErrorText, zmianaID, UserID) {
    const addr = `${addrBaza}/${zmianaID}/sluzba/${UserID}`;
    return _apiCall(addr, MethodType.DELETE, setErrorText)
        .then(resp => resp.data)
        .catch(() => null);
}
