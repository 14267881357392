import {Button, Modal} from "react-bootstrap";
import {DeleteIcon} from "../../media/icons/DeleteIcon";
import {useCallback, useState} from "react";

/**
 * Przycisk usuwania elementów
 * @param {function()} onClick
 * @param {string} confirmTitle
 * @param {string} confirmText
 * @param {string|undefined} buttonText
 * @param {boolean} buttonWithIcon
 * @return {JSX.Element}
 * @constructor
 */
export function DeleteButton({onClick, confirmTitle, confirmText, buttonText = undefined, buttonWithIcon = true}) {
    const [show, setShow] = useState(false)


    const onConfirm = useCallback(() => {
        onClick();
        setShow(false);
    }, [onClick])

    return (<>
        <Button variant={"outline-danger"} onClick={() => setShow(true)}>
            {buttonWithIcon && <DeleteIcon/>}
            {!!buttonText && buttonText}
        </Button>
        <Modal
            show={show}
            onHide={() => setShow(false)}
            centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>{confirmTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {confirmText}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShow(false)} variant={"outline-secondary"}>Nie</Button>
                <Button onClick={onConfirm} variant={"danger"}>Tak</Button>
            </Modal.Footer>
        </Modal>
    </>)
}
